<div class="modal modal--newsletter right" id="newsletter" tabindex="-1" role="dialog" aria-labelledby="newsletterLabel" aria-hidden="true"
     [ngClass]="{'newsletter-with-banner': brandSlug === 'fiat' || brandSlug === 'fiat-professional'}">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
              <div *ngIf="brandSlug === 'fiat' || brandSlug === 'fiat-professional'" class="newsletter-banner">
                <img src="/assets/img/brands/{{brandSlug}}/newsletter_banner.jpg" >
              </div>
              <span class="close" data-dismiss="modal" aria-label="Zamknij">
                <span class="icon-x"></span>
              </span>
            </div>
            <app-form [cta]="'newsletter'" [brand_slug]="brandSlug" (error)="catchError($event)" *ngIf="isVisible" [adobeThankyouPageData]="adobeThankyouData"
            ></app-form>
        </div>
    </div>
</div>
