import {Component, Input, OnInit} from '@angular/core';
import {BasicOffer} from '../../models/offer/basic-offer';
import {ObservedOfferService} from '../../services/observed-offer.service';
import {BrandService} from '../../services/brand.service';

@Component({
  selector: 'app-observed-offer-card',
  templateUrl: './observed-offer-card.component.html',
})
export class ObservedOfferCardComponent implements OnInit {

  @Input() item: BasicOffer;
  @Input() isOffersLoaded: boolean;
  @Input() isActive: boolean;
  brandName: string;

  constructor(
      public observedOffers: ObservedOfferService,
  ) {
  }

  setDefaultImg(event) {
    if (event.currentTarget && event.currentTarget.parentElement) {
      event.currentTarget.parentElement.innerHTML = '<img src="/assets/img/default_img_290x158.png" class="box-model__photo-img" />';
    }
  }

  ngOnInit() {
    this.brandName = BrandService.getCurrentBrand().name;
  }
}
