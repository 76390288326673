var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { AfterViewInit, EventEmitter } from '@angular/core';
import { BrandService } from './services/brand.service';
import { StockApiService } from './services/stock-api.service';
import { ObservedOfferService } from './services/observed-offer.service';
import { ComparedOfferService } from './services/compared-offer.service';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../environments/environment';
import { Brand } from './models/Brands';
import { QueryStringService } from './services/query-string.service';
import { LocalStorageService } from './services/local-storage.service';
import { EmptyModalComponent } from './modals/empty-modal/empty-modal.component';
import { LiveChatService } from './services/live-chat.service';
import { ErrorHandler } from './tools/error-handler';
import { LabelProviderService } from './services/labelProvider.service';
import { TrackingService } from './services/tracking.service';
var AppComponent = /** @class */ (function () {
    function AppComponent(brandService, stockApiService, router, observedOffers, comparedOffers, QSS, LSS, liveChatService, labelProviderService) {
        var _this = this;
        this.brandService = brandService;
        this.stockApiService = stockApiService;
        this.router = router;
        this.observedOffers = observedOffers;
        this.comparedOffers = comparedOffers;
        this.QSS = QSS;
        this.LSS = LSS;
        this.liveChatService = liveChatService;
        this.labelProviderService = labelProviderService;
        this.isBrandLoaded = false;
        this.footerLinkToBrand = '';
        this.brandConfig = null;
        this.debuggerEnabled = false;
        this.trackingDebuggerEmitter = new EventEmitter();
        this.show404Error = false;
        this.classReference = AppComponent;
        this.currentModel = '0';
        this.lfaUrl = null;
        var code = null;
        if (/\/dealer\-site\/\d{7}/.test(location.href)) {
            code = location.href.match(/\/dealer\-site\/(\d{7})/)[1];
        }
        this.getBrands(code).then(function () {
            if (environment.version === 'dev') {
                if (typeof BrandService.getCurrentBrand().slug !== 'undefined') {
                    $('head').append('<link rel="stylesheet" href="/assets/css/style-' +
                        BrandService.getCurrentBrand().slug +
                        '.css" type="text/css"/>');
                }
                else {
                    $('head').append('<link rel="stylesheet" href="/assets/css/style-fiat.css" type="text/css"/>');
                }
            }
            var ua = navigator.userAgent;
            var is_ie = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
            if (is_ie) {
                $('head').append('<link rel="stylesheet" href="/assets/css/ie-style.css" type="text/css"/>');
            }
            _this.isBrandLoaded = true;
            _this.afterBrandLoad();
        }).catch(function (reason) {
            if (reason.message && reason.message !== AppComponent.THROW_404_ERROR) {
                console.log(reason);
                ErrorHandler.handle(reason);
            }
        });
    }
    AppComponent.prototype.getBrands = function (code) {
        return __awaiter(this, void 0, void 0, function () {
            var brands;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(sitedata !== undefined && sitedata.brands)) return [3 /*break*/, 1];
                        BrandService.setData(sitedata.brands);
                        if (sitedata.show404Error && sitedata.show404Error === true) {
                            this.show404Error = true;
                        }
                        else {
                            this.labelProviderService.init();
                        }
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.stockApiService.getBrands(code)];
                    case 2:
                        brands = _a.sent();
                        BrandService.setData(brands);
                        this.labelProviderService.init();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AppComponent.prototype.afterBrandLoad = function () {
        var _this = this;
        this.brandConfig = BrandService.getConfig();
        this.sendMasterkeyData();
        if (environment.version !== 'dev') {
            this.loadGoogleAnalytics();
        }
        if (BrandService.getConfig()) {
            this.brand = BrandService.getCurrentBrand();
            this.footerLinkToBrand = this.brandConfig.homeSite;
            var host = location.protocol + '//' + location.host;
            this.changeRoute($(location).attr('href').slice(host.length));
            this.router.events.subscribe(function (value) {
                if (value instanceof NavigationEnd) {
                    if (TrackingService.isEnabled(TrackingService.GOOGLE_ANALYTICS) && typeof ga !== 'undefined') {
                        ga('set', 'page', value.urlAfterRedirects);
                        ga('send', 'pageview');
                    }
                    _this.changeRoute(value.urlAfterRedirects);
                }
            });
        }
        if (this.brand.slug === 'fiat-professional') {
            this.lfaUrl = {
                configurator: 'https://www.fiatprofessional.com/pl/konfigurator',
                testDrive: 'https://www.fiatprofessional.com/pl/jazda-probna',
                dealers: 'https://www.fiatprofessional.com/pl/znajdz-dealera'
            };
        }
        else if (this.brand.slug === 'fiat') {
            this.lfaUrl = {
                configurator: 'https://www.fiat.pl/configurator',
                testDrive: 'https://www.fiat.pl/jazda-testowa',
                dealers: 'https://www.fiat.pl/dealerzy'
            };
        }
    };
    AppComponent.prototype.loadGoogleAnalytics = function (number) {
        var _this = this;
        if (number === void 0) { number = 0; }
        if (TrackingService.isEnabled(TrackingService.GOOGLE_ANALYTICS)) {
            if (typeof ga !== 'undefined') {
                ga('create', environment.production ? this.brandConfig.googleAnalyticsKey : false, 'auto');
                ga('set', 'page', document.location.pathname);
                ga('send', 'pageview');
            }
            else {
                if (number <= 10) {
                    setTimeout(function () {
                        _this.loadGoogleAnalytics(number + 1);
                    }, 500);
                }
            }
        }
    };
    AppComponent.prototype.sendMasterkeyData = function () {
        var data = {
            cid: this.QSS.getParam('cid'),
            oid: this.QSS.getParam('oid'),
            source: this.QSS.getParam('source'),
            campaignid: this.QSS.getParam('campaignid'),
            offerid: this.QSS.getParam('offerid'),
            bannerid: this.QSS.getParam('bannerid'),
            advertiserid: this.QSS.getParam('advertiserid'),
            publisher: this.QSS.getParam('publisher'),
        };
        if (data.cid !== null
            || data.oid !== null
            || data.source !== null
            || data.campaignid !== null
            || data.offerid !== null
            || data.bannerid !== null
            || data.advertiserid !== null
            || data.publisher !== null) {
            this.stockApiService.sendMasterkeyData(data).catch(function (reason) {
                ErrorHandler.handle(reason);
            });
        }
    };
    AppComponent.prototype.ngAfterViewInit = function () {
        if (this.QSS.getParam('enable_stock_debugger') !== null) {
            this.LSS.setMultiBrand('stockdebug', true);
            this.debuggerEnabled = true;
        }
        else {
            if (this.LSS.getMultiBrand('stockdebug')) {
                this.debuggerEnabled = true;
            }
        }
    };
    AppComponent.prototype.changeRoute = function (url) {
        var queryParamsCharPos = url.indexOf('?');
        var route;
        if (queryParamsCharPos > -1) {
            route = url.substr(0, queryParamsCharPos);
        }
        else {
            route = url;
        }
        if (/\/dealer\-site\/\d{7}/.test(route)) {
            var code = route.match(/\/dealer\-site\/(\d{7})/)[1];
            if (code !== AppComponent.dealerCode) {
                AppComponent.isDealerStock = true;
                AppComponent.changeStockDealer.emit(code);
                AppComponent.dealerCode = code;
                // this.stockApiService.getBrands(code).then(value => {
                //   this.brandService.setNewDataWithoutBrandChanging(value);
                // }).catch(reason => {
                //   document.location.href = '/page-not-found'
                //   ErrorHandler.handle(reason);
                // });
            }
        }
        else {
            if (AppComponent.dealerCode !== null) {
                AppComponent.isDealerStock = false;
                AppComponent.changeStockDealer.emit(null);
                AppComponent.dealerCode = null;
                // this.stockApiService.getBrands().then(value => {
                //   this.brandService.setNewDataWithoutBrandChanging(value);
                // }).catch(reason => {
                //   ErrorHandler.handle(reason);
                // });
            }
        }
    };
    AppComponent.prototype.openPrivacyNotes = function () {
        $('body').addClass('cursor-progress');
        this.stockApiService.getPrivacyNotes().then(function (value) {
            EmptyModalComponent.openModal(value, true);
            $('body').removeClass('cursor-progress');
        }, function (reason) {
            $('body').removeClass('cursor-progress');
            ErrorHandler.handle(reason);
        });
    };
    AppComponent.prototype.generateNumberRandomForBanner = function (numberOfBanners) {
        return Math.floor(Math.random() * numberOfBanners);
    };
    AppComponent.prototype.clickLogo = function () {
        if (this.show404Error) {
            document.location.href = BrandService.getCurrentBrand().stockUrl;
        }
        AppComponent.goToHomepage.emit();
    };
    AppComponent.prototype.openCookieBar = function () {
        $('.cookie-info').trigger('click');
    };
    AppComponent.prototype.getLiveChatLicense = function () {
        return this.liveChatService.livechatLicense;
    };
    AppComponent.prototype.getDealerLiveChatGroup = function (dealerCode) {
        var group = this.liveChatService.getDealerLiveChatGroup(dealerCode);
        if (group !== false) {
            $('#livechat-compact-container').show(0);
            $('#livechat-eye-catcher').show(0);
        }
        return group;
    };
    AppComponent.prototype.onActivate = function (elementRef) {
        var _this = this;
        if (typeof elementRef.currentModel !== 'undefined') {
            elementRef.currentModel.subscribe(function (event) {
                _this.currentModel = event;
            });
        }
        else {
            this.currentModel = '0';
        }
    };
    AppComponent.prototype.checkQSS = function () {
        this.QSSmodels = this.QSS.getParam('models');
        return 1;
    };
    AppComponent.prototype.closeDebugger = function () {
        this.LSS.removeMultiBrand('stockdebug');
        this.debuggerEnabled = false;
    };
    AppComponent.prototype.openOrCloseNavListMenu = function (value) {
        this.showNavListMenu = value;
    };
    AppComponent.isDealerStock = false;
    AppComponent.dealerCode = null;
    AppComponent.changeStockDealer = new EventEmitter();
    AppComponent.bodyClass = 'home';
    AppComponent.goToHomepage = new EventEmitter();
    AppComponent.THROW_404_ERROR = 'Throw 404 error';
    return AppComponent;
}());
export { AppComponent };
