<div class="offer-box align-items-end d-flex">
  <div class="offer-box__photo">
    <div class="label-wrap">
      <app-offer-labels [offer]="item"></app-offer-labels>
    </div>
    <a [routerLink]="item | offerUrl" class="offer-box__link" *ngIf="item.version !== null" title="{{ brandName }} {{ item.model.group.name }} {{ item.version.commercialCode }} {{ item.technicalData.engineType }}"></a>
    <a [routerLink]="item | offerUrl" class="offer-box__link" *ngIf="item.version === null" title="{{ brandName }} {{ item.model.group.name }} {{ item.technicalData.engineType }}"></a>

    <app-box-compare-picture [offer]="item" [brandSlug]="brandSlug" *ngIf="item.version !== null" [toAlt]="brandName + ' ' + item.model.group.name + ' ' + item.version.commercialCode + ' ' + item.technicalData.engineType"></app-box-compare-picture>
    <app-box-compare-picture [offer]="item" [brandSlug]="brandSlug" *ngIf="item.version === null" [toAlt]="brandName + ' ' + item.model.group.name + ' ' + item.technicalData.engineType"></app-box-compare-picture>
  </div>
  <div class="offer-box__info">
    <div class="offer-details d-flex d-lg-none" style="margin-top: -30px">
      <div class="col-info">
        <div class="offer-box__compare" style="visibility: hidden"></div>
      </div>

      <div class="col-icon">
        <div class="favorite-offer"
             [ngClass]="{'favorite-offer--active': observedOffers.isObserved(item.uid)}"
             (click)="observedOffers.changeObservedStatus(item.uid)">
          <svg width="16" height="14" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.47852 17.0751L9.47743 17.0742C6.75877 14.6431 4.56312 12.6755 3.03837 10.836C1.52151 9.00596 0.75 7.39658 0.75 5.69482C0.75 2.92032 2.94439 0.75 5.775 0.75C7.37914 0.75 8.92829 1.49024 9.93427 2.64607L10.5 3.29607L11.0657 2.64607C12.0717 1.49024 13.6209 0.75 15.225 0.75C18.0556 0.75 20.25 2.92032 20.25 5.69482C20.25 7.39658 19.4785 9.00596 17.9616 10.836C16.4369 12.6755 14.2412 14.6431 11.5226 17.0742L11.5215 17.0751L10.5 17.9921L9.47852 17.0751Z" stroke="#A80C42" stroke-width="1.5"/>
          </svg>
        </div>
      </div>
    </div>

    <a [routerLink]="item | offerUrl" class="box-model__link" *ngIf="item.version !== null" title="{{ brandName }} {{ item.model.group.name }} {{ item.version.commercialCode}}"></a>
    <a [routerLink]="item | offerUrl" class="box-model__link" *ngIf="item.version === null" title="{{ brandName }} {{ item.model.group.name }} {{ item.technicalData.engineType }}"></a>

    <div class="price">
      <div class="price__tax">
        <span *ngIf="brandSlug === 'fiat'">{{ item.price.final.brutto | formatMoney }} ZŁ</span>
        <span *ngIf="brandSlug === 'fiat-professional'">{{ item.price.final.netto | formatMoney }} zł netto</span>
      </div>
    </div>
    <div class="offer-details">
      <div class="col-info">
        <h3 class="offer-box__name text-uppercase">{{ item.model.group.name }}</h3>
        <div class="offer-box__version text-uppercase" *ngIf="item.version !== null">{{ item.version.commercialCode }}<br />{{ item.technicalData.engineType }}</div>
        <div class="offer-box__version text-uppercase" *ngIf="item.version === null">{{ item.technicalData.engineType }}</div>
      </div>

      <div class="col-icon d-none d-lg-block">
        <div class="favorite-offer"
             [ngClass]="{'favorite-offer--active': observedOffers.isObserved(item.uid)}"
             (click)="observedOffers.changeObservedStatus(item.uid)">
          <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.47852 17.0751L9.47743 17.0742C6.75877 14.6431 4.56312 12.6755 3.03837 10.836C1.52151 9.00596 0.75 7.39658 0.75 5.69482C0.75 2.92032 2.94439 0.75 5.775 0.75C7.37914 0.75 8.92829 1.49024 9.93427 2.64607L10.5 3.29607L11.0657 2.64607C12.0717 1.49024 13.6209 0.75 15.225 0.75C18.0556 0.75 20.25 2.92032 20.25 5.69482C20.25 7.39658 19.4785 9.00596 17.9616 10.836C16.4369 12.6755 14.2412 14.6431 11.5226 17.0742L11.5215 17.0751L10.5 17.9921L9.47852 17.0751Z" stroke="#A80C42" stroke-width="1.5"/>
          </svg>
        </div>
      </div>
    </div>

  </div>
</div>
