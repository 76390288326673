import { ElementRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { StockApiService } from '../../services/stock-api.service';
import { ErrorHandler } from '../../tools/error-handler';
import { LocalStorageService } from '../../services/local-storage.service';
var ModelVersionSelectWithColorComponent = /** @class */ (function () {
    function ModelVersionSelectWithColorComponent(eRef, stockApiService, LSS) {
        this.eRef = eRef;
        this.stockApiService = stockApiService;
        this.LSS = LSS;
        this.model = null;
        this.version = null;
        this.sendStats = false;
        this.isOptionListOpened = false;
        this.openedSubSelect = false;
        this.allVersions = false;
        this.mouseIsDown = false;
        this.isTouchable = false;
        this.update = new EventEmitter();
    }
    ModelVersionSelectWithColorComponent.prototype.clickout = function (event) {
        // if (!this.eRef.nativeElement.contains(event.target)) {
        //   this.closeOptionList();
        // }
    };
    ModelVersionSelectWithColorComponent.prototype.ngOnInit = function () {
        this.setAvailableValues(this.availableValues, null);
        if (!this.model) {
            this.LSS.set('current-group-value', null);
        }
        for (var _i = 0, _a = this.availableValues; _i < _a.length; _i++) {
            var item = _a[_i];
            if (item.key === this.model) {
                this.LSS.set('current-group-value', item.key);
            }
        }
    };
    ModelVersionSelectWithColorComponent.prototype.getCountOfSelectedModels = function () {
        var counter = 0;
        if (this.model !== null && this.model.split(':;').length > 1) {
            for (var _i = 0, _a = this.availableValues; _i < _a.length; _i++) {
                var model = _a[_i];
                for (var _b = 0, _c = this.model.split(':;'); _b < _c.length; _b++) {
                    var m = _c[_b];
                    if (model.key === m) {
                        counter++;
                    }
                }
            }
        }
        return counter;
    };
    ModelVersionSelectWithColorComponent.prototype.ngOnDestroy = function () {
    };
    ModelVersionSelectWithColorComponent.prototype.setName = function (name) {
        this.name = name;
    };
    ModelVersionSelectWithColorComponent.prototype.getName = function () {
        return this.name;
    };
    ModelVersionSelectWithColorComponent.prototype.setIcon = function (icon) {
        this.icon = icon;
    };
    ModelVersionSelectWithColorComponent.prototype.getIcon = function () {
        return this.icon;
    };
    ModelVersionSelectWithColorComponent.prototype.setValue = function (value) {
        this.model = value.model;
        this.version = value.version;
    };
    ModelVersionSelectWithColorComponent.prototype.getValue = function () {
        return {
            model: this.model,
            version: this.version
        };
    };
    ModelVersionSelectWithColorComponent.prototype.setNullValue = function (nullValue, rangeName) {
        this.LSS.set('current-group-value', null);
        this.nullValue = nullValue;
    };
    ModelVersionSelectWithColorComponent.prototype.setAvailableValues = function (availableValues, rangeName) {
        for (var _i = 0, _a = Object.keys(availableValues); _i < _a.length; _i++) {
            var model_key = _a[_i];
            if (availableValues[model_key].key === 'e-ducato') {
                for (var _b = 0, _c = Object.keys(availableValues[model_key].versions); _b < _c.length; _b++) {
                    var version_key = _c[_b];
                    if (availableValues[model_key].versions[version_key].key === 'Electric Vehicle') {
                        availableValues[model_key].versions.splice(version_key, 1);
                    }
                }
            }
        }
        this.availableValues = availableValues;
    };
    ModelVersionSelectWithColorComponent.prototype.onUpdate = function (model, modelVersion) {
        if (this.model !== model) {
            this.version = null;
        }
        this.model = model;
        this.LSS.set('current-group-value', model);
        if (this.sendStats && this.model !== null) {
            this.stockApiService.sendSearchStats(this.model, this.version).catch(function (reason) {
                ErrorHandler.log(reason);
            });
        }
        this.emitUpdateEvent();
        this.closeOptionList();
    };
    ModelVersionSelectWithColorComponent.prototype.onUpdateVersion = function (model, modelVersion) {
        if (this.model !== model) {
            this.version = null;
        }
        this.model = model;
        this.LSS.set('current-group-value', model);
        if (this.version) {
            var versionsList = this.version.split(':;');
            if (versionsList.indexOf(modelVersion) > -1) {
                versionsList.splice(versionsList.indexOf(modelVersion), 1);
            }
            else {
                versionsList.push(modelVersion);
            }
            this.version = versionsList.join(':;');
        }
        else {
            this.version = modelVersion;
        }
        if (this.sendStats && this.model !== null) {
            this.stockApiService.sendSearchStats(this.model, this.version).catch(function (reason) {
                ErrorHandler.log(reason);
            });
        }
        this.emitUpdateEvent();
    };
    ModelVersionSelectWithColorComponent.prototype.emitUpdateEvent = function () {
        var modelUpdateObject = {
            name: 'models',
            value: this.model,
        };
        var versionUpdateObject = {
            name: 'version',
            value: this.version,
        };
        this.update.emit(modelUpdateObject);
        this.update.emit(versionUpdateObject);
    };
    ModelVersionSelectWithColorComponent.prototype.openOrCloseOptionList = function ($event) {
        if ($event === void 0) { $event = null; }
        var isTargetHasIconClas = $($event.target).hasClass('icon-x');
        var isTargetHasRemoveClass = $($event.target).hasClass('remove');
        var clickOnRemoveButton = isTargetHasIconClas || isTargetHasRemoveClass;
        if ($event === null || !clickOnRemoveButton) {
            this.isOptionListOpened = !this.isOptionListOpened;
        }
    };
    ModelVersionSelectWithColorComponent.prototype.closeOptionList = function () {
        this.isOptionListOpened = false;
        this.openedSubSelect = null;
    };
    ModelVersionSelectWithColorComponent.prototype.getDisplayModel = function () {
        if (this.model !== null && this.availableValues !== undefined) {
            for (var _i = 0, _a = this.availableValues; _i < _a.length; _i++) {
                var model = _a[_i];
                if (model.key === this.model) {
                    return model.value;
                }
            }
        }
    };
    ModelVersionSelectWithColorComponent.prototype.getDisplayVersion = function () {
        var _this = this;
        if (this.model !== null && this.availableValues !== undefined) {
            var _loop_1 = function (model) {
                if (model.key === this_1.model) {
                    if (model.versions && this_1.version !== null) {
                        var versionsList_1 = '';
                        var _loop_2 = function (modelVersion) {
                            var versions = this_1.version.split(':;');
                            versions.forEach(function (val) {
                                if (_this.availableValues !== undefined) {
                                    if (modelVersion.key === val) {
                                        if (versionsList_1 === '') {
                                            versionsList_1 = ': ' + val;
                                        }
                                        else {
                                            versionsList_1 += ', ' + val;
                                        }
                                    }
                                }
                            });
                        };
                        for (var _i = 0, _a = model.versions; _i < _a.length; _i++) {
                            var modelVersion = _a[_i];
                            _loop_2(modelVersion);
                        }
                        return { value: versionsList_1 };
                    }
                }
            };
            var this_1 = this;
            for (var _i = 0, _a = this.availableValues; _i < _a.length; _i++) {
                var model = _a[_i];
                var state_1 = _loop_1(model);
                if (typeof state_1 === "object")
                    return state_1.value;
            }
        }
    };
    ModelVersionSelectWithColorComponent.prototype.getDisplayValue = function () {
        if (this.model !== null) {
            var count = this.getCountOfSelectedModels();
            if (count > 1) {
                return 'Wybrane modele: ' + count;
            }
            else {
                if (this.version !== null && this.version.split(':;').length >= 1) {
                    return this.getDisplayModel() + this.getDisplayVersion();
                }
                else {
                    return this.getDisplayModel();
                }
            }
        }
        return this.nullValue;
    };
    ModelVersionSelectWithColorComponent.prototype.isSelectedModel = function (val) {
        if (this.model !== null && this.availableValues !== undefined) {
            for (var _i = 0, _a = this.model.split(':;'); _i < _a.length; _i++) {
                var m = _a[_i];
                if (val === m) {
                    return true;
                }
            }
        }
        return false;
    };
    ModelVersionSelectWithColorComponent.prototype.isSelectedVersion = function (val) {
        if (val === this.version) {
            return true;
        }
        if (this.version !== null) {
            for (var _i = 0, _a = this.version.split(':;'); _i < _a.length; _i++) {
                var v = _a[_i];
                if (val === v) {
                    return true;
                }
            }
        }
        return false;
    };
    ModelVersionSelectWithColorComponent.prototype.onFocus = function (item) {
        if (!this.isTouchable) {
            if (item.versions && item.versions.length > 0) {
                this.openedSubSelect = item.key;
            }
            else {
                this.openedSubSelect = null;
            }
        }
    };
    ModelVersionSelectWithColorComponent.prototype.onFocusMobile = function (item, $event) {
        $event.stopPropagation();
        // if (!this.isTouchable) {
        if (item.versions && item.versions.length > 0) {
            this.openedSubSelect = item.key;
        }
        else {
            this.openedSubSelect = null;
        }
        // }
    };
    ModelVersionSelectWithColorComponent.prototype.onUpdateDesktop = function (item) {
        if (!this.isTouchable) {
            this.onUpdate(item.key, null);
        }
    };
    ModelVersionSelectWithColorComponent.prototype.onTouchDown = function (item) {
        var _this = this;
        this.mouseIsDown = true;
        this.isTouchable = true;
        this.currentItem = item;
        setTimeout(function () {
            if (_this.mouseIsDown) {
                if (item.versions && item.versions.length > 0) {
                    _this.openedSubSelect = item.key;
                    // this.isTouchable = false;
                }
                else {
                    _this.openedSubSelect = null;
                }
                _this.mouseIsDown = false;
            }
        }, 500);
    };
    ModelVersionSelectWithColorComponent.prototype.onTouchUp = function () {
        if (this.mouseIsDown) {
            this.mouseIsDown = false;
            this.onUpdate(this.currentItem.key, null);
        }
    };
    ModelVersionSelectWithColorComponent.prototype.closeSubSelect = function ($event) {
        var _this = this;
        $event.stopPropagation();
        this.isTouchable = true;
        this.openedSubSelect = null;
        setTimeout(function () {
            _this.isTouchable = false;
        }, 500);
    };
    ModelVersionSelectWithColorComponent.prototype.mouseLeaveOnlyDesktop = function () {
        if (window.innerWidth > 992) {
            this.openedSubSelect = null;
        }
    };
    ModelVersionSelectWithColorComponent.prototype.setBackgroundColor = function (item) {
        if (this.brandSlug === 'fiat') {
            switch (item) {
                case '500':
                    return '#84BABA';
                case 'nowa-500-elektryczna':
                    return '#5EAAB8';
                case '500e':
                    return '#5EAAB8';
                case '500c':
                    return '#84BABA';
                case '500x':
                    return '#5293CB';
                case 'tipo-sedan':
                    return '#EB7E39';
                case 'tipo-cross':
                    return '#EB7E39';
                case 'tipo-sw':
                    return '#EB7E39';
                case 'tipo-kombi':
                    return '#EB7E39';
                case 'tipo-5d':
                    return '#EB7E39';
                case 'tipo-hatchback':
                    return '#EB7E39';
                case 'tipo':
                    return '#EB7E39';
                case 'panda':
                    return '#F4D200';
                case '600':
                    return '#619CAA';
                default:
                    return '#5293CB';
            }
        }
        else if (this.brandSlug === 'fiat-professional') {
            if (item === 'nowa-panda-van' || item === 'fiorino' || item === 'nowe-doblo' || item === 'scudo' || item === 'ducato') {
                return '#224766';
            }
            else {
                return '#5EAAB8';
            }
        }
    };
    ModelVersionSelectWithColorComponent.prototype.setModelThumbnail = function (item) {
        if (this.brandSlug === 'fiat') {
            switch (item) {
                case '500':
                    return 'model-500.png';
                case 'nowa-500-elektryczna':
                    return 'model-500-bev.png';
                case '500e':
                    return 'model-500-bev.png';
                case '500c':
                    return 'model-500.png';
                case '500x':
                    return 'model-500x.png';
                case 'tipo-sedan':
                    return 'model-tipo-sedan.png';
                case 'tipo-cross':
                    return 'model-tipo.png';
                case 'tipo-sw':
                    return 'model-tipo-sw.png';
                case 'tipo-kombi':
                    return 'model-tipo-sw.png';
                case 'tipo-5d':
                    return 'model-tipo.png';
                case 'tipo-hatchback':
                    return 'model-tipo.png';
                case 'tipo':
                    return 'model-tipo.png';
                case 'panda':
                    return 'model-panda.png';
                case '600':
                    return 'model-600.png';
            }
        }
        else if (this.brandSlug === 'fiat-professional') {
            switch (item) {
                case 'nowa-panda-van':
                    return 'model-nowa-panda-van.png';
                case 'fiorino':
                    return 'model-fiorino.png';
                case 'nowe-doblo':
                    return 'model-nowe-doblo.png';
                case 'scudo':
                    return 'model-scudo.png';
                case 'ducato':
                    return 'model-ducato.png';
                case 'e-doblo':
                    return 'model-e-doblo.png';
                case 'e-scudo':
                    return 'model-e-scudo.png';
                case 'e-ducato':
                    return 'model-e-ducato.png';
            }
        }
    };
    return ModelVersionSelectWithColorComponent;
}());
export { ModelVersionSelectWithColorComponent };
