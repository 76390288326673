<script src="../color-select/color-select.component.spec.ts"></script>
<div class="stock-select {{class}}"
     *ngIf="availableValues !== undefined"
     [attr.data-open]="isOptionListOpened"
     [ngClass]="{'selected': value !== null, 'no-icon': icon == null}">
  <div class="select-selection" (click)="openOrCloseOptionList($event)">
    <i class="icon {{icon}}" [ngClass]="{icon: icon !== null}" *ngIf="icon !== null"></i>
    <div class="select-selection-label" *ngIf="value !== null">
      {{setValueName(value)}}
    </div>
    <div class="select-selection-label" *ngIf="value === null">
      {{nullValue}}
    </div>
    <div class="select-selection-icons" *ngIf="class !== 'stock-select--box-mileage'">
      <b *ngIf="value !== null" (click)="onUpdate(null)" class="remove"><i class="icon icon-x"></i></b>
      <b *ngIf="value == null && isOptionListOpened"> <i class="arrow arrow--down"></i></b>
      <b *ngIf="value == null && !isOptionListOpened" class="arrow arrow--up"></b>
    </div>
    <div class="select-selection-icons" *ngIf="class === 'stock-select--box-mileage'">
<!--      <span *ngIf="value !== null" (click)="onUpdate(null)" class="select-close-icon"></span>-->
<!--      <span *ngIf="value === null" class="select-arrow" [ngClass]="{'select-arrow-down':!isOptionListOpened}"></span>-->
      <span *ngIf="value !== null" (click)="onUpdate(null)" class="select-close-icon">
         <svg fill="none" height="15" viewBox="0 0 20 20" width="15" xmlns="http://www.w3.org/2000/svg">
          <path d="M18 18L2 2M18 2L2 18" stroke="#1B1C1B" stroke-linecap="round" stroke-width="2"></path>
        </svg>
      </span>
      <span *ngIf="value === null" class="select-arrow" [ngClass]="{'select-arrow-down':!isOptionListOpened}">
        <svg width="17" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.81 12.149l-7.852 7.66A.633.633 0 018.5 20a.647.647 0 01-.458-.192L.19 12.148a.624.624 0 010-.893.656.656 0 01.916 0l6.74 6.564V.64a.63.63 0 01.191-.452.663.663 0 01.926 0 .63.63 0 01.191.451V17.82l6.74-6.564a.656.656 0 01.916 0 .624.624 0 010 .894z" fill="#1B1C1B"/>
        </svg>
      </span>
    </div>
  </div>
  <div class="select-options">
    <div class="select-option-list" *ngIf="isOptionListOpened">
      <div *ngFor="let item of availableValues" class="option" (click)="onUpdate(item.key)" [ngClass]="{'selected': value !== null && value === item.key}">
        <div class="option__item">
            {{item.value}}
        </div>
      </div>
    </div>
  </div>
</div>
