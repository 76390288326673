import { StorageService } from 'angular-webstorage-service';
import { BrandService } from './brand.service';
import * as i0 from "@angular/core";
import * as i1 from "angular-webstorage-service";
var LocalStorageService = /** @class */ (function () {
    function LocalStorageService(storage) {
        var _this = this;
        this.storage = storage;
        this.get = function (key, defaultValue) {
            if (defaultValue === void 0) { defaultValue = null; }
            var k = key + '_' + BrandService.getCurrentBrand().slug;
            return _this.storage.get(k) === null ? defaultValue : _this.storage.get(k);
        };
        this.getMultiBrand = function (key, defaultValue) {
            if (defaultValue === void 0) { defaultValue = null; }
            var k = key + '_multi-brand';
            return _this.storage.get(k) === null ? defaultValue : _this.storage.get(k);
        };
        this.setMultiBrand = function (key, value) {
            var k = key + '_multi-brand';
            _this.storage.set(k, value);
        };
        this.set = function (key, value) {
            _this.storage.set(key + '_' + BrandService.getCurrentBrand().slug, value);
        };
        this.remove = function (key) {
            _this.storage.remove(key + '_' + BrandService.getCurrentBrand().slug);
        };
        this.removeMultiBrand = function (key) {
            _this.storage.remove(key + '_multi-brand');
        };
    }
    LocalStorageService.prototype.setGlobal = function (key, value) {
        this.storage.set(key, value);
    };
    LocalStorageService.ngInjectableDef = i0.defineInjectable({ factory: function LocalStorageService_Factory() { return new LocalStorageService(i0.inject(i1.LOCAL_STORAGE)); }, token: LocalStorageService, providedIn: "root" });
    return LocalStorageService;
}());
export { LocalStorageService };
