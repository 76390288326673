/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../app-components/form/form.component.ngfactory";
import * as i2 from "../../app-components/form/form.component";
import * as i3 from "../../services/stock-api.service";
import * as i4 from "../../services/adobe.service";
import * as i5 from "../../services/blue-kai.service";
import * as i6 from "@angular/common";
import * as i7 from "./newsletter-form.component";
import * as i8 from "../../services/local-storage.service";
import * as i9 from "../../services/query-string.service";
import * as i10 from "../../services/newsletter.service";
var styles_NewsletterFormComponent = [];
var RenderType_NewsletterFormComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NewsletterFormComponent, data: {} });
export { RenderType_NewsletterFormComponent as RenderType_NewsletterFormComponent };
function View_NewsletterFormComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "newsletter-banner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "/assets/img/brands/", _co.brandSlug, "/newsletter_banner.jpg"); _ck(_v, 1, 0, currVal_0); }); }
function View_NewsletterFormComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-form", [], null, [[null, "error"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("error" === en)) {
        var pd_0 = (_co.catchError($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_FormComponent_0, i1.RenderType_FormComponent)), i0.ɵdid(1, 114688, null, 0, i2.FormComponent, [i3.StockApiService, i4.AdobeService, i5.BlueKaiService], { cta: [0, "cta"], brand_slug: [1, "brand_slug"], adobeThankyouPageData: [2, "adobeThankyouPageData"] }, { error: "error" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "newsletter"; var currVal_1 = _co.brandSlug; var currVal_2 = _co.adobeThankyouData; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_NewsletterFormComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["aria-hidden", "true"], ["aria-labelledby", "newsletterLabel"], ["class", "modal modal--newsletter right"], ["id", "newsletter"], ["role", "dialog"], ["tabindex", "-1"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i6.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(2, { "newsletter-with-banner": 0 }), (_l()(), i0.ɵeld(3, 0, null, null, 8, "div", [["class", "modal-dialog modal-lg"], ["role", "document"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 7, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 4, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NewsletterFormComponent_1)), i0.ɵdid(7, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 1, "span", [["aria-label", "Zamknij"], ["class", "close"], ["data-dismiss", "modal"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 0, "span", [["class", "icon-x"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NewsletterFormComponent_2)), i0.ɵdid(11, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "modal modal--newsletter right"; var currVal_1 = _ck(_v, 2, 0, ((_co.brandSlug === "fiat") || (_co.brandSlug === "fiat-professional"))); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = ((_co.brandSlug === "fiat") || (_co.brandSlug === "fiat-professional")); _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.isVisible; _ck(_v, 11, 0, currVal_3); }, null); }
export function View_NewsletterFormComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-newsletter-form", [], null, null, null, View_NewsletterFormComponent_0, RenderType_NewsletterFormComponent)), i0.ɵdid(1, 114688, null, 0, i7.NewsletterFormComponent, [i8.LocalStorageService, i9.QueryStringService, i4.AdobeService, i10.NewsletterService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NewsletterFormComponentNgFactory = i0.ɵccf("app-newsletter-form", i7.NewsletterFormComponent, View_NewsletterFormComponent_Host_0, {}, {}, []);
export { NewsletterFormComponentNgFactory as NewsletterFormComponentNgFactory };
