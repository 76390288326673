import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BrandService} from './brand.service';
import {environment} from '../../environments/environment';
import {BasicOffer} from '../models/offer/basic-offer';
import {Brands} from '../models/Brands';

@Injectable({
  providedIn: 'root'
})
export class StockApiService {
  constructor(
    private http: HttpClient
  ) {
  }

  private async getBrandSlug(): Promise<string> {
    if (BrandService.getCurrentBrand() !== null) {
      return BrandService.getCurrentBrand().slug;
    } else {
      return new Promise( resolve => setTimeout(this.getBrandSlug, 100) ) as Promise<string>;
    }
  }

  private async getNewCarsUrl(): Promise<string> {
    if (BrandService.getCurrentBrand() !== null) {
      return BrandService.getCurrentBrand().newCarsUrl;
    } else {
      return new Promise(resolve => setTimeout(this.getBrandSlug, 100)) as Promise<string>;
    }
  }

  public async getSearchData(data: any): Promise<any> {
    const brandSlug = await this.getBrandSlug();
    return this.http.post(environment.APIBaseURL + 'offers/list-' + brandSlug + '.json', data).toPromise();
  }

  public async sendSearchStats(model: string, version: string): Promise<any> {
    const brandSlug = await this.getBrandSlug();
    // send stats only on production
    if (environment.production) {
      return this.http.put(environment.APIBaseURL + 'search-stats', {brand: brandSlug, model, version}).toPromise();
    }
  }

  public async getBasicSearchData(): Promise<any> {
    const brandSlug = await this.getBrandSlug();
    return this.http.get(environment.APIBaseURL + 'offers/basic-search-data-' + brandSlug + '.json').toPromise();
  }

  public async getOfferData(id: number): Promise<any> {
    const brandSlug = await this.getBrandSlug();
    return this.http.get(environment.APIBaseURL + 'offers/offer-' + brandSlug + '.json?id=' + id).toPromise();
  }

  public async getSimilarOffers(id: number, dealer: string = null): Promise<Array<BasicOffer>> {
    const brandSlug = await this.getBrandSlug();
    if (dealer !== null) {
      return this.http.get(
        environment.APIBaseURL + 'offers/similar-offers-' + brandSlug + '.json?id=' + id + '&dealer_code=' + dealer
      ).toPromise() as Promise<Array<BasicOffer>>;
    } else {
      return this.http.get(
        environment.APIBaseURL + 'offers/similar-offers-' + brandSlug + '.json?id=' + id
      ).toPromise() as Promise<Array<BasicOffer>>;
    }
  }

  public async getDealerList(): Promise<any> {
    const brandSlug = await this.getBrandSlug();
    return this.http.get(environment.APIBaseURL + 'offers/dealer-list-' + brandSlug + '.json').toPromise();
  }

  public async getSimpleOffersData(id: any): Promise<Array<BasicOffer>> {
    if (id instanceof Array) {
      id = id.join(':;');
    }
    return this.http.get(
        environment.APIBaseURL + 'offers/simple.json?brand=' + BrandService.getCurrentBrand().code + '&id=' + id
    ).toPromise() as Promise<Array<BasicOffer>>;
  }

  public getBrands(dealerCode: string = null): Promise<Brands> {
    if (dealerCode !== null) {
      return this.http.get(
        environment.APIBaseURL + 'brands.json?dealer=' + dealerCode + '&host=' + document.location.host
      ).toPromise() as Promise<Brands>;
    } else {
      return this.http.get(
        environment.APIBaseURL + 'brands.json?host=' + document.location.host
      ).toPromise() as Promise<Brands>;
    }
  }

  public async sendMasterkeyData(data) {
    const brandSlug = await this.getBrandSlug();
    this.http.post(environment.APIBaseURL + 'leads/save-client-params/' + brandSlug, data).toPromise();
  }

  public async getLeadForm(id: string): Promise <any> {
      const brandSlug = await this.getBrandSlug();
      return this.http.get(environment.APIBaseURL + 'leads/get-form-data/' + brandSlug + '/' + id + '.json').toPromise();
  }

  public async sendLead(id: string, data: any): Promise <any> {
    const brandSlug = await this.getBrandSlug();
    return this.http.post(environment.APIBaseURL + 'leads/submit-form-data/' + brandSlug + '/' + id, data).toPromise();
  }

  public getPrivacyNotes() {
    return this.http.get('https://api.fcapoland.pl/shared-content/disclaimer/1596/information', {responseType: 'text'}).toPromise();
  }

  public async getModelDiscount() {
    const brandSlug = await this.getBrandSlug();
    return this.http.get(environment.APIBaseURL + 'offers/model-discount-' + brandSlug + '.json').toPromise();
  }

  public async getNewCarsInfo(): Promise<any> {
    const newCarsUrl = await this.getNewCarsUrl();
    return this.http.get(newCarsUrl + environment.APIBaseURL + 'brands.json').toPromise();
  }
}
