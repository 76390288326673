import { EventEmitter } from '@angular/core';
import * as i0 from "@angular/core";
var NewsletterService = /** @class */ (function () {
    function NewsletterService() {
        this.showNL = new EventEmitter();
    }
    NewsletterService.prototype.clickShowNL = function () {
        this.showNL.emit();
    };
    NewsletterService.ngInjectableDef = i0.defineInjectable({ factory: function NewsletterService_Factory() { return new NewsletterService(); }, token: NewsletterService, providedIn: "root" });
    return NewsletterService;
}());
export { NewsletterService };
