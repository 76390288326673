import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ComparedOfferService} from '../../services/compared-offer.service';
import {StockApiService} from '../../services/stock-api.service';
import {BasicOffer} from '../../models/offer/basic-offer';
import {AppComponent} from '../../app.component';
import {AdobeService} from '../../services/adobe.service';
import {ErrorHandler} from '../../tools/error-handler';
import {BlueKaiService} from '../../services/blue-kai.service';
import {TitleService} from '../../services/title.service';
import {LabelProviderService} from '../../services/labelProvider.service';
import {BrandService} from '../../services/brand.service';
import {FullOffer} from '../../models/offer/full-offer';
import {throwError} from 'rxjs';
import {TrackingService} from '../../services/tracking.service';
import {NewsletterService} from '../../services/newsletter.service';
import * as Raven from 'raven-js';

declare let window: any;

@Component({
  selector: 'app-compared-offers',
  templateUrl: './compared-offers.component.html',
})
export class ComparedOffersComponent implements OnInit {
  offersBasic: Array<BasicOffer> = [];
  offers: Array<FullOffer> = [];
  loading = true;
  AppComponent = AppComponent;
  brandSlug = '';
  showDifferentClass = false;
  visibleNumber = [false, false];
  siteData = window.sitedata;

  constructor(public comparedOfferService: ComparedOfferService,
              public newsletterService: NewsletterService,
              private stockApiService: StockApiService,
              private adobeService: AdobeService,
              private blueKaiService: BlueKaiService,
              private titleService: TitleService,
              private labelProviderService: LabelProviderService,
              private changeDetectorRef: ChangeDetectorRef,
              ) {
    this.titleService.setTitleObserved();

    if (comparedOfferService.getAllOffers().length === 2) {

      for (const item of comparedOfferService.getAllOffers()) {
        this.stockApiService.getOfferData(parseInt(item, 10)).then(value => {
          value = this.labelProviderService.fillOfferLabels(value);
          this.offers.push(value);
        }).catch(reason => {
          comparedOfferService.removeOffer(parseInt(item, 10));
          ErrorHandler.log(reason);
          this.loading = false;
        });

        this.isFinally();
      }
    } else {
      this.loading = false;
    }
  }

  isFinally() {
    if (this.offers.length === 2) {
      this.loading = false;
    } else {
      setTimeout(() => {
        this.isFinally();
      }, 200);
    }
  }

  isActive(id: any) {
    id = +id;

    for (const offer of this.offers) {
      if (offer.id === id) {
        return true;
      }
    }

    return false;
  }

  removeObservedOffers() {
    for (const id of this.comparedOfferService.getAllOffers()) {
      this.comparedOfferService.removeOffer(id);
    }
  }

  ngOnInit() {
    setTimeout(() => {
      AppComponent.bodyClass = 'page compare-page';

      // this.adobeService.pageLoadObservedOffers();
      // this.blueKaiService.changeRoute();
    });
    this.brandSlug = BrandService.getCurrentBrand().slug;
  }

  showDifferent() {
    this.showDifferentClass = !this.showDifferentClass;
  }

  showPhone(offer, key) {
    const phone = offer.dealer.phones[0];

    if (phone) {
      this.visibleNumber[key] = offer.dealer.phones[0];
    } else {
      throwError('Dealer phone not found!');
    }
  }
}
