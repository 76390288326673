/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./brand-select/brand-select.component.ngfactory";
import * as i2 from "./brand-select/brand-select.component";
import * as i3 from "../services/local-storage.service";
import * as i4 from "./newsletter-form/newsletter-form.component.ngfactory";
import * as i5 from "./newsletter-form/newsletter-form.component";
import * as i6 from "../services/query-string.service";
import * as i7 from "../services/adobe.service";
import * as i8 from "../services/newsletter.service";
import * as i9 from "./empty-modal/empty-modal.component.ngfactory";
import * as i10 from "./empty-modal/empty-modal.component";
import * as i11 from "./share/share.component.ngfactory";
import * as i12 from "./share/share.component";
import * as i13 from "./compare-modal/compare-modal.component.ngfactory";
import * as i14 from "./compare-modal/compare-modal.component";
import * as i15 from "../services/compared-offer.service";
import * as i16 from "../services/stock-api.service";
import * as i17 from "../services/labelProvider.service";
import * as i18 from "./modals.component";
var styles_ModalsComponent = [];
var RenderType_ModalsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ModalsComponent, data: {} });
export { RenderType_ModalsComponent as RenderType_ModalsComponent };
export function View_ModalsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-brand-select", [], null, null, null, i1.View_BrandSelectComponent_0, i1.RenderType_BrandSelectComponent)), i0.ɵdid(1, 114688, null, 0, i2.BrandSelectComponent, [i3.LocalStorageService], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-newsletter-form", [["class", "modal-open"]], null, null, null, i4.View_NewsletterFormComponent_0, i4.RenderType_NewsletterFormComponent)), i0.ɵdid(3, 114688, null, 0, i5.NewsletterFormComponent, [i3.LocalStorageService, i6.QueryStringService, i7.AdobeService, i8.NewsletterService], null, null), (_l()(), i0.ɵeld(4, 0, null, null, 1, "app-empty-modal", [], null, null, null, i9.View_EmptyModalComponent_0, i9.RenderType_EmptyModalComponent)), i0.ɵdid(5, 114688, null, 0, i10.EmptyModalComponent, [], null, null), (_l()(), i0.ɵeld(6, 0, null, null, 1, "app-share", [], null, null, null, i11.View_ShareComponent_0, i11.RenderType_ShareComponent)), i0.ɵdid(7, 114688, null, 0, i12.ShareComponent, [], null, null), (_l()(), i0.ɵeld(8, 0, null, null, 1, "app-compare-modal", [], null, null, null, i13.View_CompareModalComponent_0, i13.RenderType_CompareModalComponent)), i0.ɵdid(9, 114688, null, 0, i14.CompareModalComponent, [i15.ComparedOfferService, i3.LocalStorageService, i16.StockApiService, i17.LabelProviderService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); _ck(_v, 5, 0); _ck(_v, 7, 0); _ck(_v, 9, 0); }, null); }
export function View_ModalsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-modals", [], null, null, null, View_ModalsComponent_0, RenderType_ModalsComponent)), i0.ɵdid(1, 114688, null, 0, i18.ModalsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalsComponentNgFactory = i0.ɵccf("app-modals", i18.ModalsComponent, View_ModalsComponent_Host_0, {}, {}, []);
export { ModalsComponentNgFactory as ModalsComponentNgFactory };
