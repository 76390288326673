import {Component, Input, OnInit} from '@angular/core';
import {BasicOffer} from '../../models/offer/basic-offer';
import {FullOffer} from '../../models/offer/full-offer';
import {environment} from '../../../environments/environment';
import {BrandService} from '../../services/brand.service';
import {Brands} from '../../models/Brands';

@Component({
  selector: 'app-offer-labels',
  templateUrl: './offer-labels.component.html',
})
export class OfferLabelsComponent implements OnInit {

  brand = BrandService.getCurrentBrand();
  @Input() offer: BasicOffer|FullOffer;

  private isProd;

  constructor() {
    this.isProd = environment.production;
  }

  ngOnInit() {
    // Promocja RabatyAlfa201911 - ukrycie labeli innych niż "RabatyAlfa201911"
    // if (BrandService.getCurrentBrand().slug === Brands.ALFA_ROMEO) {
    //   if (this.offer.labels.indexOf('RabatyAlfa201911') !== -1) {
    //     this.offer.labels = ['RabatyAlfa201911'];
    //   } else {
    //     this.offer.labels = [];
    //   }
    // }
    // / Promocja RabatyAlfa201911 - ukrycie labeli innych niż "RabatyAlfa201911"
    this.offer.labels = [];
  }

  getPercentDiscount() {
    return Math.round((1 - Math.ceil(this.offer.price.final.netto) / Math.ceil(this.offer.price.base.netto)) * 100);
  }

  getDiscountAmount() {
    return Math.round(Math.ceil(this.offer.price.base.brutto) - Math.ceil(this.offer.price.final.brutto));
  }
}
