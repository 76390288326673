import { environment } from '../../environments/environment';
import { BrandService } from './brand.service';
import { TrackingService } from './tracking.service';
import { TrackingDebuggerComponent } from '../app-components/tracking-debugger/tracking-debugger.component';
import * as i0 from "@angular/core";
var BlueKaiService = /** @class */ (function () {
    function BlueKaiService() {
        this.pushAttempsNumber = 0;
    }
    BlueKaiService.prototype.formFillingOutStart = function (formType) {
        var _this = this;
        if (environment.version !== 'dev' && TrackingService.isEnabled(TrackingService.BLUEKAI)) {
            if (typeof window.bk_addPageCtx !== 'undefined') {
                window.bk_addPageCtx('form type', formType);
                window.bk_addPageCtx('form step', 'start');
                this.push();
                TrackingService.debug({
                    type: TrackingDebuggerComponent.TYPE_SUCCESS,
                    action: 'formFillingOutStart',
                    service: 'BlueKaiService',
                    data: { 'form type': formType, 'form step': 'start' }
                });
            }
            else {
                if (this.pushAttempsNumber < 10) {
                    this.nextAttemp('formFillingOutStart');
                    this.pushAttempsNumber++;
                    setTimeout(function () {
                        _this.formFillingOutStart(formType);
                    }, 500);
                }
                else {
                    this.trackerNotLoaded('formFillingOutStart');
                }
            }
        }
    };
    BlueKaiService.prototype.click2call = function () {
        var _this = this;
        if (environment.version !== 'dev' && TrackingService.isEnabled(TrackingService.BLUEKAI)) {
            if (typeof window.bk_addPageCtx !== 'undefined') {
                window.bk_addPageCtx('form type', 'click2call');
                window.bk_addPageCtx('form step', 'start');
                this.push();
                TrackingService.debug({
                    type: TrackingDebuggerComponent.TYPE_SUCCESS,
                    action: 'click2call',
                    service: 'BlueKaiService',
                    data: { 'form type': 'click2call', 'form step': 'start' }
                });
            }
            else {
                if (this.pushAttempsNumber < 10) {
                    this.nextAttemp('click2call');
                    this.pushAttempsNumber++;
                    setTimeout(function () {
                        _this.click2call();
                    }, 500);
                }
                else {
                    this.trackerNotLoaded('click2call');
                }
            }
        }
    };
    BlueKaiService.prototype.changeRoute = function (model, dealer) {
        var _this = this;
        if (model === void 0) { model = null; }
        if (dealer === void 0) { dealer = null; }
        if (environment.version !== 'dev' && TrackingService.isEnabled(TrackingService.BLUEKAI)) {
            if (typeof window.bk_addPageCtx !== 'undefined') {
                window.bk_addPageCtx('site section', window.location.href);
                window.bk_addPageCtx('brand', BrandService.getCurrentBrand().name);
                if (model !== null) {
                    window.bk_addPageCtx('model', model);
                }
                if (dealer !== null) {
                    window.bk_addPageCtx('dealer', dealer);
                }
                this.push();
                TrackingService.debug({
                    type: TrackingDebuggerComponent.TYPE_SUCCESS,
                    action: 'changeRoute',
                    service: 'BlueKaiService',
                    data: { 'site section': window.location.href, 'brand': BrandService.getCurrentBrand().name, model: model, dealer: dealer }
                });
            }
            else {
                if (this.pushAttempsNumber < 10) {
                    this.nextAttemp('changeRoute');
                    this.pushAttempsNumber++;
                    setTimeout(function () {
                        _this.changeRoute(model, dealer);
                    }, 500);
                }
                else {
                    this.trackerNotLoaded('changeRoute');
                }
            }
        }
    };
    BlueKaiService.prototype.thankYouPage = function (formType) {
        var _this = this;
        if (environment.version !== 'dev' && TrackingService.isEnabled(TrackingService.BLUEKAI)) {
            if (typeof window.bk_addPageCtx !== 'undefined') {
                window.bk_addPageCtx('form type', formType);
                window.bk_addPageCtx('form step', 'thankyou');
                this.push();
                TrackingService.debug({
                    type: TrackingDebuggerComponent.TYPE_SUCCESS,
                    action: 'thankYouPage',
                    service: 'BlueKaiService',
                    data: { 'form type': formType, 'form step': 'thankyou' }
                });
            }
            else {
                if (this.pushAttempsNumber < 10) {
                    this.nextAttemp('thankYouPage');
                    this.pushAttempsNumber++;
                    setTimeout(function () {
                        _this.thankYouPage(formType);
                    }, 500);
                }
                else {
                    this.trackerNotLoaded('thankYouPage');
                }
            }
        }
    };
    BlueKaiService.prototype.push = function () {
        var _this = this;
        if (TrackingService.isEnabled(TrackingService.BLUEKAI)) {
            if (typeof window.blueKaiKey !== 'undefined' && window.blueKaiKey !== null) {
                window.bk_doJSTag(window.blueKaiKey, 5);
                this.pushAttempsNumber = 0;
            }
            else {
                if (this.pushAttempsNumber < 10) {
                    this.nextAttemp('push');
                    this.pushAttempsNumber++;
                    setTimeout(function () {
                        _this.push();
                    }, 500);
                }
                else {
                    this.trackerNotLoaded('push');
                }
            }
        }
    };
    BlueKaiService.prototype.nextAttemp = function (methodName) {
        TrackingService.debug({
            type: TrackingDebuggerComponent.TYPE_WARNING,
            action: methodName,
            service: 'BlueKaiService',
            data: { message: 'Tracker not loaded, next attempt after 500ms' }
        });
    };
    BlueKaiService.prototype.trackerNotLoaded = function (methodName) {
        TrackingService.debug({
            type: TrackingDebuggerComponent.TYPE_ERROR,
            action: methodName,
            service: 'BlueKaiService',
            data: { message: 'Tracker not loaded, number of attempts exceeded' }
        });
    };
    BlueKaiService.ngInjectableDef = i0.defineInjectable({ factory: function BlueKaiService_Factory() { return new BlueKaiService(); }, token: BlueKaiService, providedIn: "root" });
    return BlueKaiService;
}());
export { BlueKaiService };
