/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "ng-lazyload-image";
import * as i2 from "@angular/common";
import * as i3 from "./banner.component";
var styles_BannerComponent = [];
var RenderType_BannerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BannerComponent, data: {} });
export { RenderType_BannerComponent as RenderType_BannerComponent };
function View_BannerComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "a", [["class", "header__banner d-none d-lg-block"]], [[8, "href", 4], [8, "target", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "img", [["class", "d-none d-xl-block"]], null, null, null, null, null)), i0.ɵdid(2, 1720320, null, 0, i1.LazyLoadImageDirective, [i0.ElementRef, i0.NgZone, i0.PLATFORM_ID, [2, "options"]], { lazyImage: [0, "lazyImage"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "img", [["class", "d-none d-lg-block d-xl-none"]], null, null, null, null, null)), i0.ɵdid(4, 1720320, null, 0, i1.LazyLoadImageDirective, [i0.ElementRef, i0.NgZone, i0.PLATFORM_ID, [2, "options"]], { lazyImage: [0, "lazyImage"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = ((("assets/img/brands/" + _co.brand.slug) + "/banner/") + _co.banner_620x100); _ck(_v, 2, 0, currVal_2); var currVal_3 = ((("assets/img/brands/" + _co.brand.slug) + "/banner/") + _co.banner_470x85); _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.bannerLink; var currVal_1 = _co.bannerTarget; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_BannerComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "span", [["class", "header__banner d-none d-lg-block"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "img", [["class", "d-none d-xl-block"]], null, null, null, null, null)), i0.ɵdid(2, 1720320, null, 0, i1.LazyLoadImageDirective, [i0.ElementRef, i0.NgZone, i0.PLATFORM_ID, [2, "options"]], { lazyImage: [0, "lazyImage"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "img", [["class", "d-none d-lg-block d-xl-none"]], null, null, null, null, null)), i0.ɵdid(4, 1720320, null, 0, i1.LazyLoadImageDirective, [i0.ElementRef, i0.NgZone, i0.PLATFORM_ID, [2, "options"]], { lazyImage: [0, "lazyImage"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((("assets/img/brands/" + _co.brand.slug) + "/banner/") + _co.banner_620x100); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((("assets/img/brands/" + _co.brand.slug) + "/banner/F") + _co.banner_470x85); _ck(_v, 4, 0, currVal_1); }, null); }
function View_BannerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BannerComponent_2)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BannerComponent_3)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.bannerType === "link"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.bannerType === "span"); _ck(_v, 4, 0, currVal_1); }, null); }
function View_BannerComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "a", [["class", "header__banner d-block"]], [[8, "href", 4], [8, "target", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "img", [["class", "mx-auto mw-100"], ["data-version", "mobile-320"]], null, null, null, null, null)), i0.ɵdid(2, 1720320, null, 0, i1.LazyLoadImageDirective, [i0.ElementRef, i0.NgZone, i0.PLATFORM_ID, [2, "options"]], { lazyImage: [0, "lazyImage"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "img", [["class", "mx-auto mw-100"], ["data-version", "mobile"]], null, null, null, null, null)), i0.ɵdid(4, 1720320, null, 0, i1.LazyLoadImageDirective, [i0.ElementRef, i0.NgZone, i0.PLATFORM_ID, [2, "options"]], { lazyImage: [0, "lazyImage"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 1, "img", [["class", "mx-auto mw-100"], ["data-version", "tablet"]], null, null, null, null, null)), i0.ɵdid(6, 1720320, null, 0, i1.LazyLoadImageDirective, [i0.ElementRef, i0.NgZone, i0.PLATFORM_ID, [2, "options"]], { lazyImage: [0, "lazyImage"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = ((("assets/img/brands/" + _co.brand.slug) + "/banner/") + _co.banner_320x50); _ck(_v, 2, 0, currVal_2); var currVal_3 = ((("assets/img/brands/" + _co.brand.slug) + "/banner/") + _co.banner_470x85); _ck(_v, 4, 0, currVal_3); var currVal_4 = ((("assets/img/brands/" + _co.brand.slug) + "/banner/") + _co.banner_690x100); _ck(_v, 6, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.bannerLink; var currVal_1 = _co.bannerTarget; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_BannerComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "span", [["class", "header__banner d-lg-block"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "img", [["class", "mx-auto mw-100"], ["data-version", "mobile-320"]], null, null, null, null, null)), i0.ɵdid(2, 1720320, null, 0, i1.LazyLoadImageDirective, [i0.ElementRef, i0.NgZone, i0.PLATFORM_ID, [2, "options"]], { lazyImage: [0, "lazyImage"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "img", [["class", "mx-auto mw-100"], ["data-version", "mobile"]], null, null, null, null, null)), i0.ɵdid(4, 1720320, null, 0, i1.LazyLoadImageDirective, [i0.ElementRef, i0.NgZone, i0.PLATFORM_ID, [2, "options"]], { lazyImage: [0, "lazyImage"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 1, "img", [["class", "mx-auto mw-100"], ["data-version", "tablet"]], null, null, null, null, null)), i0.ɵdid(6, 1720320, null, 0, i1.LazyLoadImageDirective, [i0.ElementRef, i0.NgZone, i0.PLATFORM_ID, [2, "options"]], { lazyImage: [0, "lazyImage"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((("assets/img/brands/" + _co.brand.slug) + "/banner/") + _co.banner_320x50); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((("assets/img/brands/" + _co.brand.slug) + "/banner/") + _co.banner_470x85); _ck(_v, 4, 0, currVal_1); var currVal_2 = ((("assets/img/brands/" + _co.brand.slug) + "/banner/") + _co.banner_690x100); _ck(_v, 6, 0, currVal_2); }, null); }
function View_BannerComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "section", [["class", "section section-banner mt-5 mt-sm-3 mb-3 d-lg-none"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 6, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 4, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BannerComponent_5)), i0.ɵdid(6, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BannerComponent_6)), i0.ɵdid(8, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.bannerType === "link"); _ck(_v, 6, 0, currVal_0); var currVal_1 = (_co.bannerType === "span"); _ck(_v, 8, 0, currVal_1); }, null); }
export function View_BannerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_BannerComponent_1)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BannerComponent_4)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.bannerPosition === "header") && (_co.generateBanner() === true)); _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_co.brand && (_co.bannerPosition === "section")) && (_co.generateBanner() === true)); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_BannerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-banner", [], null, null, null, View_BannerComponent_0, RenderType_BannerComponent)), i0.ɵdid(1, 114688, null, 0, i3.BannerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BannerComponentNgFactory = i0.ɵccf("app-banner", i3.BannerComponent, View_BannerComponent_Host_0, { bodyClass: "bodyClass", bannerPosition: "bannerPosition", brand: "brand", currentModel: "currentModel", model: "model" }, {}, []);
export { BannerComponentNgFactory as BannerComponentNgFactory };
