import { EventEmitter } from '@angular/core';
import { TrackingDebuggerComponent } from '../app-components/tracking-debugger/tracking-debugger.component';
import * as i0 from "@angular/core";
var TrackingService = /** @class */ (function () {
    function TrackingService() {
    }
    TrackingService.isEnabled = function (tracker) {
        // switching with a `tracker` parameter in future
        if (window && window.sitedata && window.sitedata.enable_tracking !== undefined) {
            return window.sitedata.enable_tracking;
        }
        // default - if enable_tracking is not defined
        return true;
    };
    TrackingService.isOpenclPerformanceCookieEnabled = function () {
        var name = 'opncl_performance';
        var nameLenPlus = (name.length + 1);
        var value = document.cookie
            .split(';')
            .map(function (c) { return c.trim(); })
            .filter(function (cookie) {
            return cookie.substring(0, nameLenPlus) === name + "=";
        })
            .map(function (cookie) {
            return decodeURIComponent(cookie.substring(nameLenPlus));
        })[0] || null;
        return value !== 'false';
    };
    TrackingService.debug = function (data) {
        if (TrackingDebuggerComponent && TrackingDebuggerComponent.emitEvent instanceof EventEmitter) {
            TrackingDebuggerComponent.emitEvent.emit(data);
        }
    };
    TrackingService.checkCookie = function (name) {
        // const name = 'opncl_advertising';
        var nameLenPlus = (name.length + 1);
        return document.cookie
            .split(';')
            .map(function (c) { return c.trim(); })
            .filter(function (cookie) {
            return cookie.substring(0, nameLenPlus) === name + "=";
        })
            .map(function (cookie) {
            return decodeURIComponent(cookie.substring(nameLenPlus));
        })[0] || null;
    };
    TrackingService.ADOBE_DTM = 'adobe_dtm';
    TrackingService.BLUEKAI = 'blue_kai';
    TrackingService.GOOGLE_ANALYTICS = 'google_analytics';
    TrackingService.SIZMEK = 'sizmek';
    TrackingService.GTM = 'gtm';
    TrackingService.ngInjectableDef = i0.defineInjectable({ factory: function TrackingService_Factory() { return new TrackingService(); }, token: TrackingService, providedIn: "root" });
    return TrackingService;
}());
export { TrackingService };
