import {Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ISearchFormElement} from '../../interface/i-search-form-element';
import {StockApiService} from '../../services/stock-api.service';
import {ErrorHandler} from '../../tools/error-handler';

declare var $: any;

@Component({
  selector: 'app-model-version-select',
  templateUrl: './model-version-select.component.html',
})
export class ModelVersionSelectComponent implements OnInit, OnDestroy, ISearchFormElement {

  @Input() protected name: string;
  @Input() icon: string;
  @Input() model: any = null;
  @Input() version: any = null;
  @Input() availableValues: Array<any>;
  @Input() nullValue: string;
  @Input() sendStats = false;
  isOptionListOpened = false;
  openedSubSelect = false;
  allVersions = false;

  @Output() update = new EventEmitter<any>();

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.closeOptionList();
    }
  }

  constructor(private eRef: ElementRef,
              private stockApiService: StockApiService) {
  }


  ngOnInit() {
    this.setAvailableValues(this.availableValues);
  }

  getCountOfSelectedModels() {
    let counter = 0;
    if (this.model !== null && this.model.split(':;').length > 1) {
        for (const model of this.availableValues) {
          for (const m of this.model.split(':;')) {
            if (model.key === m) {
              counter++;
            }
          }
        }
     }
    return counter;
  }

  ngOnDestroy() {
  }

  public setName(name): void {
    this.name = name;
  }

  public getName(): string {
    return this.name;
  }

  public setIcon(icon): void {
      this.icon = icon;
  }

  public getIcon(): string {
      return this.icon;
  }

  public setValue(value: any): void {
    this.model = value.model;
    this.version = value.version;
  }

  public getValue(): any {
    return {
      model: this.model,
      version: this.version
    };
  }

  public setNullValue(nullValue: string): void {
    this.nullValue = nullValue;
  }

  public setAvailableValues(availableValues: Array<any>): void {
    this.availableValues = availableValues;
  }

  public onUpdate(model: any, modelVersion: any): void {

    if (this.model !== model) {
      this.version = null;
    }
    this.model = model;

    if (this.sendStats && this.model !== null) {
      this.stockApiService.sendSearchStats(this.model, this.version).catch(reason => {
        ErrorHandler.log(reason);
      });
    }

    this.emitUpdateEvent();
    this.closeOptionList();
  }

  public onUpdateVersion( model: any, modelVersion: any): void {

    if (this.model !== model) {
      this.version = null;
    }
    this.model = model;

    if (this.version) {
      const versionsList: Array<any> = this.version.split(':;');
      if (versionsList.indexOf(modelVersion) > -1) {
        versionsList.splice(versionsList.indexOf(modelVersion), 1);
      } else {
        versionsList.push(modelVersion);
      }
      this.version = versionsList.join(':;');
    } else {
      this.version = modelVersion;
    }


    if (this.sendStats && this.model !== null) {
      this.stockApiService.sendSearchStats(this.model, this.version).catch(reason => {
        ErrorHandler.log(reason);
      });
    }

    this.emitUpdateEvent();

  }

  private emitUpdateEvent() {
    const modelUpdateObject = {
      name: 'models',
      value: this.model,
    };
    const versionUpdateObject = {
      name: 'version',
      value: this.version,
    };
    this.update.emit(modelUpdateObject);
    this.update.emit(versionUpdateObject);
  }

  public openOrCloseOptionList($event = null) {
    const isTargetHasIconClas = $($event.target).hasClass('icon-x');
    const isTargetHasRemoveClass = $($event.target).hasClass('remove');
    const clickOnRemoveButton = isTargetHasIconClas || isTargetHasRemoveClass;
    if ($event === null || !clickOnRemoveButton) {
      this.isOptionListOpened = !this.isOptionListOpened;
    }
  }


  closeOptionList() {
     this.isOptionListOpened = false;
     this.openedSubSelect = null;
  }

  getDisplayModel() {
    if (this.model !== null && this.availableValues !== undefined) {
      for (const model of this.availableValues) {
        if (model.key === this.model) {
          return model.value;
        }
      }
    }
  }

  getDisplayVersion() {
    if (this.model !== null && this.availableValues !== undefined) {
      for (const model of this.availableValues) {
        if (model.key === this.model) {
          if (model.versions && this.version !== null) {
            let versionsList = '';
            for (const modelVersion of model.versions) {

              const versions: Array<any> = this.version.split(':;');

              versions.forEach((val) => {
                if ( this.availableValues !== undefined ) {
                  if (modelVersion.key === val) {
                    if (versionsList === '') {
                      versionsList = ': ' + val;
                    } else {
                      versionsList += ', ' + val;
                    }
                  }
                }
              });

            }
            return versionsList;
          }
        }
      }
    }
  }

  getDisplayValue() {
    if (this.model !== null) {
      const count = this.getCountOfSelectedModels();
      if (count > 1) {
        return 'Wybrane modele: ' + count;
      } else {
        if (this.version !== null && this.version.split(':;').length >= 1) {
           return this.getDisplayModel() + this.getDisplayVersion();
        } else {
          return this.getDisplayModel();
        }
      }
    }

    return this.nullValue;
  }

  isSelectedModel(val) {
    if (this.model !== null && this.availableValues !== undefined) {
      for (const m of this.model.split(':;')) {
        if (val === m) {
          return true;
        }
      }
    }

    return false;
  }

  isSelectedVersion(val) {
    if (val === this.version) {
      return true;
    }

    if (this.version !== null) {
      for (const v of this.version.split(':;')) {
        if (val === v) {
          return true;
        }
      }
    }

    return false;
  }

  public onFocus(item) {
    if (item.versions && item.versions.length > 0) {
      this.openedSubSelect = item.key;
    } else {
      this.openedSubSelect = null;
    }
  }


}
