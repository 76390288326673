import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
var QueryStringService = /** @class */ (function () {
    function QueryStringService(router, location, route) {
        var _this = this;
        this.router = router;
        this.location = location;
        this.route = route;
        this.route.queryParams.subscribe(function (params) {
            if (params.length > 0) {
                Object.keys(params).forEach(function (key) {
                    if (typeof params[key] !== 'string' && params[key].length > 0) {
                        QueryStringService.params[key] = params[key][0];
                    }
                    else {
                        QueryStringService.params[key] = params[key];
                    }
                });
            }
            else {
                QueryStringService.params = _this.getParamsFromUrl();
            }
        });
    }
    QueryStringService.prototype.getParamsFromUrl = function () {
        var params = {};
        if (document.location.href.indexOf('?') >= 0) {
            var qParams = document.location.href.substr(document.location.href.indexOf('?') + 1);
            for (var _i = 0, _a = qParams.split('&'); _i < _a.length; _i++) {
                var paramString = _a[_i];
                var key = paramString.substr(0, paramString.indexOf('='));
                params[key] = decodeURIComponent(paramString.substr(paramString.indexOf('=') + 1));
            }
        }
        return params;
    };
    QueryStringService.prototype.setParam = function (name, value) {
        if (!value) {
            this.removeParam(name);
        }
        else {
            QueryStringService.params[name] = value;
            this.updateUrlQueryString();
        }
    };
    QueryStringService.prototype.getAllParams = function () {
        return QueryStringService.params;
    };
    QueryStringService.prototype.removeParam = function (name) {
        if (QueryStringService.params[name]) {
            QueryStringService.params[name] = null;
            this.updateUrlQueryString();
        }
    };
    QueryStringService.prototype.getParam = function (name, forceArray) {
        if (forceArray === void 0) { forceArray = false; }
        if (QueryStringService.params[name] !== undefined) {
            if (forceArray && QueryStringService.params[name] instanceof String) {
                return [QueryStringService.params[name]];
            }
            return QueryStringService.params[name];
        }
        if (forceArray) {
            return [];
        }
        else {
            return null;
        }
    };
    QueryStringService.prototype.updateUrlQueryString = function () {
        var currentUrl = this.router.url.split('?')[0];
        this.router.navigate([currentUrl], { queryParams: QueryStringService.params, queryParamsHandling: 'merge' });
    };
    QueryStringService.prototype.navigateByUrl = function (route) {
        this.router.navigate([route], { queryParams: QueryStringService.params });
    };
    QueryStringService.params = {};
    QueryStringService.ngInjectableDef = i0.defineInjectable({ factory: function QueryStringService_Factory() { return new QueryStringService(i0.inject(i1.Router), i0.inject(i2.Location), i0.inject(i1.ActivatedRoute)); }, token: QueryStringService, providedIn: "root" });
    return QueryStringService;
}());
export { QueryStringService };
