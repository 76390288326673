/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../tools/url-prefix.pipe";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./not-found.component";
import * as i5 from "../../services/stock-api.service";
var styles_NotFoundComponent = [];
var RenderType_NotFoundComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NotFoundComponent, data: {} });
export { RenderType_NotFoundComponent as RenderType_NotFoundComponent };
export function View_NotFoundComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.UrlPrefixPipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 16, "section", [["class", "section section--info-error"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 15, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 14, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 3, "div", [["class", "headline-wrap col-6 col-lg-4 offset-lg-0 col-xl-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "headline"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "h3", [["class", "headline__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["B\u0141\u0104D 404"])), (_l()(), i0.ɵeld(8, 0, null, null, 9, "div", [["class", "col-12 col-lg-8 col-xl-9 py-5 my-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Nie znaleziono takiej strony"])), (_l()(), i0.ɵeld(11, 0, null, null, 6, "p", [["class", "intro"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Przepraszamy. Strona, kt\u00F3rej szukasz nie zosta\u0142a odnaleziona. Prawdopodobnie zosta\u0142a skasowana, zmieniono jej nazw\u0119 albo zosta\u0142a czasowo usuni\u0119ta. Sprawd\u017A poprawno\u015B\u0107 adresu lub zajrzyj na "])), (_l()(), i0.ɵeld(13, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 14).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(14, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵppd(15, 1), (_l()(), i0.ɵted(-1, null, ["stron\u0119 g\u0142\u00F3wn\u0105"])), (_l()(), i0.ɵted(-1, null, ["."]))], function (_ck, _v) { var currVal_2 = i0.ɵunv(_v, 14, 0, _ck(_v, 15, 0, i0.ɵnov(_v, 0), "")); _ck(_v, 14, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 14).target; var currVal_1 = i0.ɵnov(_v, 14).href; _ck(_v, 13, 0, currVal_0, currVal_1); }); }
export function View_NotFoundComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-not-found", [], null, null, null, View_NotFoundComponent_0, RenderType_NotFoundComponent)), i0.ɵdid(1, 114688, null, 0, i4.NotFoundComponent, [i5.StockApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotFoundComponentNgFactory = i0.ɵccf("app-not-found", i4.NotFoundComponent, View_NotFoundComponent_Host_0, {}, {}, []);
export { NotFoundComponentNgFactory as NotFoundComponentNgFactory };
