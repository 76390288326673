<div class="box-model align-items-end d-flex" [routerLink]="!isActive && isOffersLoaded ? null : item | offerUrl" *ngIf="observedOffers.isObserved(item.uid)" [ngClass]="{'is-disabled': !isActive && isOffersLoaded}">
  <div class="box-model__photo">
    <div class="label-wrap">
      <app-offer-labels [offer]="item"></app-offer-labels>
    </div>

    <app-picture [offer]="item" [toAlt]="brandName + ' ' + item.model + ' ' + item.version + ' ' + item.engineType"></app-picture>

    <div class="label label--reservation text-uppercase" *ngIf="item.status === 'reserved'">Rezerwacja</div>
  </div>
  <div class="box-model__info d-flex">
    <div class="box-model__information text-uppercase w-100 text-center" *ngIf="!isActive && isOffersLoaded">Oferta nieaktualna</div>
    <ul class="box-model__nav d-flex">
      <li class="box-model__nav-item my-2">
        <span class="box-model__nav-icon icon-camera"></span><span class="label-quantity">{{ item.countOfImage }}</span>
      </li>
      <li class="box-model__nav-item my-2" *ngIf="item.countOfVideos > 0">
        <span class="box-model__nav-icon icon-video"><span class="label-quantity">{{ item.countOfVideos }}</span></span>
      </li>
      <li class="box-model__nav-item box-model__nav-item--clipboard">
        <span class="btn-icon btn-icon--clipboard"
              [ngClass]="{active: observedOffers.isObserved(item.uid)}"
              (click)="observedOffers.changeObservedStatus(item.uid)"
        >
          <span class="box-model__nav-icon icon-star_contour"></span>
          <span class="box-model__nav-icon icon-star"></span>
        </span>
      </li>
    </ul>
    <div class="box-model__col">
      <div class="box-model__wrap d-flex">
        <h3 class="box-model__name text-uppercase w-100" title="{{ item.model }}">{{ item.model }}</h3>
        <div class="box-model__version text-uppercase" title="{{ item.version }} {{ item.engineType }}">{{ item.version }}<br />{{ item.engineType }}<br />Rok produkcji: {{ item.productionYear }}<br />Przebieg: {{ item.mileage|formatMoney }} <span class="text-lowercase">km</span></div>
        <div class="price">
          <div class="price__tax d-flex pb-1">
            <small class="price__text">BRUTTO:</small>
            <span class="price__item-1">{{ item.price.final.brutto | formatMoney }}<small>ZŁ</small></span>
            <span class="price__item-2" *ngIf="(item.price.base.brutto|formatMoney) !== (item.price.final.brutto|formatMoney)"><span>{{ item.price.base.brutto | formatMoney }}</span><small>ZŁ</small></span>
          </div>
          <div class="price__notax d-flex">
            <small class="price__text">NETTO:</small>
            <span class="price__item-1">{{ item.price.final.netto | formatMoney }}<small>ZŁ</small></span>
            <span class="price__item-2" *ngIf="(item.price.base.netto|formatMoney) !== (item.price.final.netto|formatMoney)"><span>{{ item.price.base.netto | formatMoney }}</span><small>ZŁ</small></span>
          </div>
        </div>
        <span class="box-model__local w-100 mt-2">
            <span class="box-model__local-item d-flex text-uppercase aling-item-center">
              <span class="icon-marker"></span>
              <span class="box-model__local-text ml-1"> {{item.localization}}</span>
            </span>
        </span>
      </div>
    </div>
  </div>
</div>
