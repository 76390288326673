/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./color-select.component";
var styles_ColorSelectComponent = [];
var RenderType_ColorSelectComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ColorSelectComponent, data: {} });
export { RenderType_ColorSelectComponent as RenderType_ColorSelectComponent };
function View_ColorSelectComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "stock-select-color__option d-flex"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onUpdate(_v.parent.context.$implicit.key) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(2, { "selected": 0 }), (_l()(), i0.ɵeld(3, 0, null, null, 5, "div", [["class", "stock-select-color__checkbox"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [], [[4, "background", null], [4, "border-color", null]], null, null, null, null)), (_l()(), i0.ɵted(6, null, [" (", ") "])), (_l()(), i0.ɵeld(7, 0, null, null, 1, "div", [["class", "stock-select-color__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "stock-select-color__option d-flex"; var currVal_1 = _ck(_v, 2, 0, _co.isSelected(_v.parent.context.$implicit.key)); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵinlineInterpolate(1, "stock-select-color__field ", _co.getColorByValue(_v.parent.context.$implicit.key), ""); _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.getColorClassValue(_v.parent.context.$implicit.key); var currVal_4 = _co.getColorClassValue(_v.parent.context.$implicit.key); _ck(_v, 5, 0, currVal_3, currVal_4); var currVal_5 = _v.parent.context.$implicit.count; _ck(_v, 6, 0, currVal_5); var currVal_6 = _v.parent.context.$implicit.value; _ck(_v, 8, 0, currVal_6); }); }
function View_ColorSelectComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ColorSelectComponent_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = ((_v.context.$implicit.key !== null) && (_v.context.$implicit.key.length > 0)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ColorSelectComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "stock-select-color row justify-content-center"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ColorSelectComponent_1)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.availableValues; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ColorSelectComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-color-select", [], null, null, null, View_ColorSelectComponent_0, RenderType_ColorSelectComponent)), i0.ɵdid(1, 114688, null, 0, i2.ColorSelectComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ColorSelectComponentNgFactory = i0.ɵccf("app-color-select", i2.ColorSelectComponent, View_ColorSelectComponent_Host_0, { name: "name", icon: "icon", value: "value", availableValues: "availableValues", nullValue: "nullValue" }, { update: "update" }, []);
export { ColorSelectComponentNgFactory as ColorSelectComponentNgFactory };
