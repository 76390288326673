import { OnInit } from '@angular/core';
import { ObservedOfferService } from '../../services/observed-offer.service';
import { StockApiService } from '../../services/stock-api.service';
import { AppComponent } from '../../app.component';
import { AdobeService } from '../../services/adobe.service';
import { ErrorHandler } from '../../tools/error-handler';
import { BlueKaiService } from '../../services/blue-kai.service';
import { TitleService } from '../../services/title.service';
import { LabelProviderService } from '../../services/labelProvider.service';
import { BrandService } from '../../services/brand.service';
var ObservedOffersComponent = /** @class */ (function () {
    function ObservedOffersComponent(observedOfferService, stockApiService, adobeService, blueKaiService, titleService, labelProviderService) {
        var _this = this;
        this.observedOfferService = observedOfferService;
        this.stockApiService = stockApiService;
        this.adobeService = adobeService;
        this.blueKaiService = blueKaiService;
        this.titleService = titleService;
        this.labelProviderService = labelProviderService;
        this.offers = [];
        this.loading = true;
        this.AppComponent = AppComponent;
        this.brandSlug = '';
        this.titleService.setTitleObserved();
        if (observedOfferService.getAllOffers().length > 0) {
            var observedOffers_1 = observedOfferService.getAllOffers(AppComponent.dealerCode);
            this.stockApiService.getSimpleOffersData(observedOffers_1).then(function (value) {
                var tmpOffers = [];
                value = _this.labelProviderService.fillOfferLabelsByArray(value);
                var _loop_1 = function (offer) {
                    if (observedOffers_1.find(function (el) { return el === offer.uid; })) {
                        tmpOffers.push(offer);
                    }
                };
                for (var _i = 0, value_1 = value; _i < value_1.length; _i++) {
                    var offer = value_1[_i];
                    _loop_1(offer);
                }
                _this.offers = tmpOffers;
                _this.loading = false;
            }).catch(function (reason) {
                ErrorHandler.log(reason);
            });
        }
        else {
            this.loading = false;
        }
    }
    ObservedOffersComponent.prototype.isActive = function (id) {
        for (var _i = 0, _a = this.offers; _i < _a.length; _i++) {
            var offer = _a[_i];
            if (offer.uid === id) {
                return true;
            }
        }
        return false;
    };
    ObservedOffersComponent.prototype.removeObservedOffers = function () {
        for (var _i = 0, _a = this.observedOfferService.getAllOffers(AppComponent.dealerCode); _i < _a.length; _i++) {
            var id = _a[_i];
            this.observedOfferService.removeOffer(id);
        }
    };
    ObservedOffersComponent.prototype.ngOnInit = function () {
        var _this = this;
        setTimeout(function () {
            AppComponent.bodyClass = 'page';
            _this.adobeService.pageLoadObservedOffers();
            _this.blueKaiService.changeRoute();
        });
        this.brandSlug = BrandService.getCurrentBrand().slug;
    };
    return ObservedOffersComponent;
}());
export { ObservedOffersComponent };
