import { DigitalDataAdobeTM } from '../models/digitalDataAdobeTM';
import { BrandService } from './brand.service';
import { environment } from '../../environments/environment';
import { TrackingService } from './tracking.service';
import { TrackingDebuggerComponent } from '../app-components/tracking-debugger/tracking-debugger.component';
import * as i0 from "@angular/core";
var AdobeService = /** @class */ (function () {
    function AdobeService() {
        this.sateliteAttempsNumber = 0;
        this.lastPageName = '';
    }
    AdobeService.prototype.pageLoadHomepage = function () {
        var data = new DigitalDataAdobeTM(true);
        TrackingService.debug({ type: TrackingDebuggerComponent.TYPE_FIRED, action: 'pageLoadHomepage', service: 'AdobeService', data: data });
        this.executeAction(data, 'pageload', 'pageLoadHomepage');
    };
    AdobeService.prototype.pageLoadDealerList = function () {
        var data = new DigitalDataAdobeTM(true);
        data.page.pageInfo.pageName = BrandService.getCurrentBrand().slug + ':pl:shopping-tools:used:dealer-list';
        data.page.category.primaryCategory = BrandService.getCurrentBrand().slug + ':pl:shopping-tools:used:dealer-list';
        TrackingService.debug({
            type: TrackingDebuggerComponent.TYPE_FIRED,
            action: 'pageLoadDealerList',
            service: 'AdobeService',
            data: data
        });
        this.executeAction(data, 'pageload', 'pageLoadDealerList');
    };
    AdobeService.prototype.pageLoadObservedOffers = function () {
        var data = new DigitalDataAdobeTM(true);
        data.page.pageInfo.pageName = BrandService.getCurrentBrand().slug + ':pl:shopping-tools:used:favourites';
        data.page.category.primaryCategory = BrandService.getCurrentBrand().slug + ':pl:shopping-tools:used:favourites';
        TrackingService.debug({
            type: TrackingDebuggerComponent.TYPE_FIRED,
            action: 'pageLoadObservedOffers',
            service: 'AdobeService',
            data: data
        });
        this.executeAction(data, 'pageload', 'pageLoadObservedOffers');
    };
    AdobeService.prototype.pageLoadOffer = function (vehicleTrim, vehicleModel, vehicleID) {
        var data = new DigitalDataAdobeTM(true);
        data.page.pageInfo.pageName = BrandService.getCurrentBrand().slug + ':pl:shopping-tools:used:offer';
        data.page.category.primaryCategory = BrandService.getCurrentBrand().slug + ':pl:shopping-tools:used:offer';
        data.page.pageInfo.vehicleTrim = vehicleTrim;
        data.page.pageInfo.vehicleModel = vehicleModel;
        data.page.pageInfo.vehicleID = vehicleID;
        data.page.component.form.stepName = 'form';
        data.page.component.form.nameType = 'lead:quote-request';
        data.page.component.form.leadType = 'quote-request';
        TrackingService.debug({ type: TrackingDebuggerComponent.TYPE_FIRED, action: 'pageLoadOffer', service: 'AdobeService', data: data });
        this.executeAction(data, 'pageload', 'pageLoadOffer');
    };
    AdobeService.prototype.getThankyouPageOfferData = function (vehicleTrim, vehicleModel, vehicleID) {
        var data = new DigitalDataAdobeTM(true);
        data.page.pageInfo.pageName = BrandService.getCurrentBrand().slug + ':pl:shopping-tools:used:offer';
        data.page.category.primaryCategory = BrandService.getCurrentBrand().slug + ':pl:shopping-tools:used:offer';
        data.page.pageInfo.vehicleTrim = vehicleTrim;
        data.page.pageInfo.vehicleModel = vehicleModel;
        data.page.pageInfo.vehicleID = vehicleID;
        data.page.component.form.stepName = 'thankyou';
        data.page.component.form.nameType = 'lead:quote-request';
        data.page.component.form.leadType = 'quote-request';
        return data;
    };
    AdobeService.prototype.newsletterLoaded = function () {
        var data = new DigitalDataAdobeTM();
        data.page.pageInfo.pageName = this.lastPageName;
        data.page.component.form.stepName = 'form';
        data.page.component.form.nameType = 'permission:sign-up-for-updates';
        TrackingService.debug({ type: TrackingDebuggerComponent.TYPE_FIRED, action: 'newsletterLoaded', service: 'AdobeService', data: data });
        this.executeAction(data, 'pageload', 'newsletterLoaded');
    };
    AdobeService.prototype.thankYouPage = function (data, leadId) {
        data.page.component.form.transactionID = 'CCPOL' + leadId;
        data.page.pageInfo.pageName = this.lastPageName;
        TrackingService.debug({ type: TrackingDebuggerComponent.TYPE_FIRED, action: 'thankYouPage', service: 'AdobeService', data: data });
        this.executeAction(data, 'thankyou', 'thankYouPage');
    };
    AdobeService.prototype.getNewsletterThankYouData = function () {
        var data = new DigitalDataAdobeTM();
        data.page.pageInfo.pageName = this.lastPageName;
        data.page.component.form.stepName = 'thankyou';
        data.page.component.form.nameType = 'permission:sign-up-for-updates';
        return data;
    };
    AdobeService.prototype.offerFinancingRecalculation = function (term, downPayment, purchaseType, tradeIn) {
        var data = new DigitalDataAdobeTM(true);
        data.page.component.paymentCalc.term = term;
        data.page.component.paymentCalc.downPayment = downPayment;
        data.page.component.paymentCalc.purchaseType = purchaseType;
        data.page.component.paymentCalc.tradeIn = tradeIn;
        data.page.pageInfo.pageName = BrandService.getCurrentBrand().slug + ':pl:shopping-tools:used:results';
        data.page.category.primaryCategory = BrandService.getCurrentBrand().slug + ':pl:shopping-tools:used:results';
        TrackingService.debug({
            type: TrackingDebuggerComponent.TYPE_FIRED,
            action: 'offerFinancingRecalculation',
            service: 'AdobeService',
            data: data
        });
        this.executeAction(data, 'recalculation', 'offerFinancingRecalculation');
    };
    AdobeService.prototype.loadedSearchResults = function (filters, resultCount) {
        var data = new DigitalDataAdobeTM(true);
        data.page.component.inventorySearch.filterList = filters;
        data.page.component.inventorySearch.numberOfVehicles = resultCount;
        TrackingService.debug({
            type: TrackingDebuggerComponent.TYPE_FIRED,
            action: 'loadedSearchResults',
            service: 'AdobeService',
            data: data
        });
        this.executeAction(data, 'used', 'loadedSearchResults');
    };
    AdobeService.prototype.executeAction = function (data, action, methodName) {
        var _this = this;
        this.lastPageName = data.page.pageInfo.pageName;
        if (environment.version !== 'dev' && TrackingService.isEnabled(TrackingService.ADOBE_DTM)) {
            // wait with first track until _satellite is loaded
            window.digitalData = data;
            if (typeof _satellite !== 'undefined' && _satellite.track) {
                _satellite.track(action);
                TrackingService.debug({
                    type: TrackingDebuggerComponent.TYPE_SUCCESS,
                    action: methodName,
                    service: 'AdobeService',
                    data: data
                });
            }
            else {
                // when _satelite is not loaded, try after 100ms
                this.sateliteAttempsNumber++;
                if (this.sateliteAttempsNumber < 10) {
                    setTimeout(function () {
                        TrackingService.debug({
                            type: TrackingDebuggerComponent.TYPE_WARNING,
                            action: methodName,
                            service: 'AdobeService',
                            data: { message: 'Tracker not loaded, next attempt after 500ms' }
                        });
                        _this.executeAction(data, action, methodName);
                    }, 500);
                }
                else {
                    TrackingService.debug({
                        type: TrackingDebuggerComponent.TYPE_ERROR,
                        action: methodName,
                        service: 'AdobeService',
                        data: { message: 'Tracker not loaded, number of attempts exceeded' }
                    });
                }
            }
        }
    };
    AdobeService.ngInjectableDef = i0.defineInjectable({ factory: function AdobeService_Factory() { return new AdobeService(); }, token: AdobeService, providedIn: "root" });
    return AdobeService;
}());
export { AdobeService };
