import {EventEmitter, Injectable} from '@angular/core';
import {TrackingDebuggerComponent} from '../app-components/tracking-debugger/tracking-debugger.component';
import {environment} from '../../environments/environment';

declare let window: any;

@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  static ADOBE_DTM = 'adobe_dtm';
  static BLUEKAI = 'blue_kai';
  static GOOGLE_ANALYTICS = 'google_analytics';
  static SIZMEK = 'sizmek';
  static GTM = 'gtm';

  constructor() { }

  static isEnabled(tracker: string): boolean {
    // switching with a `tracker` parameter in future
    if (window && window.sitedata && window.sitedata.enable_tracking !== undefined) {
      return window.sitedata.enable_tracking;
    }
    // default - if enable_tracking is not defined
    return true;
  }

  static isOpenclPerformanceCookieEnabled(): boolean {
    const name = 'opncl_performance';
    const nameLenPlus = (name.length + 1);
    const value = document.cookie
      .split(';')
      .map(c => c.trim())
      .filter(cookie => {
        return cookie.substring(0, nameLenPlus) === `${name}=`;
      })
      .map(cookie => {
        return decodeURIComponent(cookie.substring(nameLenPlus));
      })[0] || null;
    return value !== 'false';
  }

  static debug(data) {
    if (TrackingDebuggerComponent && TrackingDebuggerComponent.emitEvent instanceof EventEmitter) {
      TrackingDebuggerComponent.emitEvent.emit(data);
    }
  }

  static checkCookie(name): string {
    // const name = 'opncl_advertising';
    const nameLenPlus = (name.length + 1);
    return document.cookie
      .split(';')
      .map(c => c.trim())
      .filter(cookie => {
        return cookie.substring(0, nameLenPlus) === `${name}=`;
      })
      .map(cookie => {
        return decodeURIComponent(cookie.substring(nameLenPlus));
      })[0] || null;
  }
}
