export const Config = {
    'fiat': {
        homeSite: 'https:\/\/www.fiat.pl',
        contactFormUrl: 'https:\/\/www.fiat.pl\/kontakt',
        externalAssets: {
            'Twitter': 'https:\/\/twitter.com\/Fiat_Polska',
            'YouTube': 'https:\/\/www.youtube.com\/user\/fappl?feature=watch',
            'Facebook': 'https:\/\/www.facebook.com\/FiatPL',
            'Instagram': 'https:\/\/www.instagram.com\/fiat_pl\/',
            'Pinterest': 'https:\/\/it.pinterest.com\/fiatpolska\/',
            'Google+': 'https:\/\/plus.google.com\/105141453899731255861?hl=pl',
            'Linkedin': 'https:\/\/www.linkedin.com\/company\/fca-fleet-&-business\/',
        },
        infoLineNumber: '00800 3428 0000',
        googleAnalyticsKey: 'UA-57786469-3',
    },
    'fiat-professional': {
        homeSite: 'https:\/\/www.fiatprofessional.pl',
        contactFormUrl: null,
        externalAssets: {
            'Twitter': 'https:\/\/twitter.com\/fiatproofficial',
            'YouTube': 'https:\/\/www.youtube.com\/user\/FIATPROFESSIONAL',
            'Facebook': 'https:\/\/www.facebook.com\/FiatProfessionalPL',
            'Instagram': 'https:\/\/www.instagram.com\/fiatprofessional\/?hl=pl',
            'Pinterest': null,
            'Google+': null,
            'Linkedin': 'https:\/\/www.linkedin.com\/company\/fca-fleet-&-business\/',
        },
        infoLineNumber: '00800 3428 0000',
        googleAnalyticsKey: 'UA-57786469-6',
    },
    'jeep': {
        homeSite: 'https:\/\/www.jeep.pl',
        contactFormUrl: 'https:\/\/www.jeep.pl\/kontakt',
        externalAssets: {
            'Twitter': 'https:\/\/twitter.com\/JeepPolska',
            'YouTube': 'http:\/\/www.youtube.com\/jeeppolska',
            'Facebook': 'https:\/\/www.facebook.com\/JeepPL\/',
            'Instagram': 'http:\/\/instagram.com\/jeeppolska',
            'Pinterest': null,
            'Google+': null,
            'Linkedin': 'https:\/\/www.linkedin.com\/company\/fca-fleet-&-business\/',
        },
        infoLineNumber: '0080004265337',
        googleAnalyticsKey: 'UA-57786469-4',
    },
    'abarth': {
        homeSite: 'https:\/\/www.abarth.pl',
        contactFormUrl: 'https:\/\/www.abarth.pl\/kontakt',
        externalAssets: {
            'Twitter': 'https:\/\/twitter.com\/#!\/teamabarth',
            'YouTube': 'https:\/\/www.youtube.com\/channel\/UCVEqw7skHhDNodW3SHvG6cw\/',
            'Facebook': 'https:\/\/www.facebook.com\/Abarth.Poland',
            'Instagram': 'http:\/\/instagram.com\/abarthpolska',
            'Pinterest': null,
            'Google+': null,
            'Linkedin': 'https:\/\/www.linkedin.com\/company\/fca-fleet-&-business\/',
        },
        infoLineNumber: '00800 222784 00',
        googleAnalyticsKey: 'UA-57786469-1',
    },
    'alfa-romeo': {
        homeSite: 'https:\/\/www.alfaromeo.pl',
        contactFormUrl: 'https:\/\/www.alfaromeo.pl\/kontakt',
        externalAssets: {
            'Twitter': 'https:\/\/twitter.com\/alfaromeopolska',
            'YouTube': 'https:\/\/www.youtube.com\/user\/alfaromeowpolsce',
            'Facebook': 'https:\/\/www.facebook.com\/AlfaRomeoPL\/',
            'Instagram': 'https:\/\/www.instagram.com\/alfaromeopolska\/',
            'Pinterest': null,
            'Google+': null,
            'Linkedin': 'https:\/\/www.linkedin.com\/company\/alfa-romeo\/',
        },
        infoLineNumber: '00800 2532 0000',
        googleAnalyticsKey: 'UA-57786469-2',
    }
};


export class ConfigModel {
    homeSite: string;
    contactFormUrl: string;
    externalAssets: ExternalAssets;
    infoLineNumber: string;
    googleAnalyticsKey: string;
}

class ExternalAssets {
    Twitter: string;
    YouTube: string;
    Facebook: string;
    Instagram: string;
    Pinterest: string;
    Linkedin: string;
}
