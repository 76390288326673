<section class="section section--page-content mt-0">
  <div class="container">
    <div class="row">
      <div class="col-12 col-sm-7">
        <div class="headline-wrap">
          <div class="headline">
            <h3 class="headline__title text-uppercase">Lista dealerów</h3>
            <div class="headline__footer-link" *ngIf="brandSlug !== 'fiat' && brandSlug !== 'fiat-professional'"><a class="text-uppercase" [routerLink]="'/' | addUrlPrefix"><span class="icon-arrow_left"></span> wróć do strony głównej</a></div>
          </div>
        </div>
      </div>
      <div class="col-lg-9 offset-lg-3" *ngIf="brandSlug !== 'fiat' && brandSlug !== 'fiat-professional'">
        <div class="page-content">
          <div class="dealer-list">
            <div *ngFor="let item of dealers" class="dealer-list__item row">
              <div class="col-12">
                <h3 class="dealer-list__name">{{item.name}}</h3>
              </div>
              <div class="col-7 col-xl-8">
                <p>
                  {{item.street}}<br />
                  {{item.postCode}} {{item.city}}<br />
                  <a href="https://www.google.com/maps/dir/?api=1&destination={{item.coordinates.latitude}},{{item.coordinates.longitude}}"
                     target="_blank"
                     class="btn-icon btn-icon&#45;&#45;show-map">
                    <span class="icon-marker"></span> Pokaż na mapie
                  </a>
                </p>
              </div>
              <div class="col-5 col-xl-4">
                <div *ngFor="let phone of item.phones"><a class="dealer-list__phone" href="tel:{{phone}}"><span class="icon icon-phone"></span> {{phone}}</a></div>
                <!--<a class="dealer-list__email" href="mailto:{{item.email}}">{{item.email}}</a>-->
              </div>
              <span class="dealer-list__label">OFERT: <strong>{{item.offer_count}}</strong></span>
              <div class="col-md-5 offset-md-7 col-xl-4 offset-xl-8 d-flex justify-content-end px-0 pl-md-3">
                <span class="dealer-list__btn btn btn-icon d-block text-center w-100" [routerLink]="'/dealer/' + item.code | addUrlPrefix">Zobacz oferty tego dealera <span class="icon-right_arrow"></span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--      NEW LAYOUT-->
      <div class="col-12" *ngIf="brandSlug === 'fiat' || brandSlug === 'fiat-professional'">
        <div class="page-content">
          <div class="dealer-list">
            <div *ngFor="let item of dealers" class="dealer-list__item row mx-0">
              <div class="col-12 col-md-8 col-xl-9">
                <div class="row">
                  <div class="col-12 col-xl-6 d-flex align-items-center dealer-list__col">
                    <span class="dealer-list__name">{{item.name}}</span>
                  </div>
                  <div class="col-12 col-sm-6 col-xl-3 dealer-list__col">
                    <p>
                      {{item.street}}<br />
                      {{item.postCode}} {{item.city}}<br />
                      <a href="https://www.google.com/maps/dir/?api=1&destination={{item.coordinates.latitude}},{{item.coordinates.longitude}}"
                         target="_blank"
                         class="btn-icon btn-icon--show-map">
                        <span class="icon-marker"></span> Pokaż na mapie
                      </a>
                    </p>
                  </div>
                  <div class="col-12 col-sm-6 col-xl-3 dealer-list__col">
                    <div *ngFor="let phone of item.phones">
                      <a class="dealer-list__phone" href="tel:{{phone}}">
                        <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.5 0H2.5C1.83696 0 1.20107 0.263392 0.732233 0.732233C0.263392 1.20107 0 1.83696 0 2.5V19.5C0 20.163 0.263392 20.7989 0.732233 21.2678C1.20107 21.7366 1.83696 22 2.5 22H10.5C11.163 22 11.7989 21.7366 12.2678 21.2678C12.7366 20.7989 13 20.163 13 19.5V2.5C13 1.83696 12.7366 1.20107 12.2678 0.732233C11.7989 0.263392 11.163 0 10.5 0ZM6.5 21C5.67 21 5 20.33 5 19.5C5 18.67 5.67 18 6.5 18C7.33 18 8 18.67 8 19.5C8 20.33 7.33 21 6.5 21ZM11 17H2V3H11V17Z" fill="#FEFFFF"/>
                        </svg>
                        {{phone}}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4 col-xl-3 d-flex align-items-center dealer-list__all-offers"
                   [routerLink]="'/dealer/' + item.code | addUrlPrefix">
                Zobacz wszystkie oferty tego dealera <small>({{item.offer_count}})</small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- / NEW LAYOUT-->
    </div>
  </div>
</section>
