import {Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ISearchFormElement} from '../../interface/i-search-form-element';
import {FormatMoneyPipe} from '../../tools/format-money.pipe';

declare var $: any;

@Component({
  selector: 'app-price-select',
  templateUrl: './price-select.component.html',
})
export class PriceSelectComponent implements OnInit, OnDestroy, ISearchFormElement {

  @Input() protected name: string;
  @Input() icon: string;
  @Input() value: number = null;
  @Input() formattedValue: string;
  @Input() availableValues: Array<any>;
  @Input() nullValue: string;
  isOptionListOpened = false;
  blurExec: number;

  @Output() update = new EventEmitter<any>();

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.closeOptionList();
    }
  }

  constructor(private eRef: ElementRef) {
  }

  ngOnInit() {
    // this.setValue(this.value);
    this.setValueFormater(this.value);
    this.setAvailableValues(this.availableValues);
  }

  ngOnDestroy() {
  }

  public setName(name): void {
    this.name = name;
  }

  public getName(): string {
    return this.name;
  }

  public setIcon(icon): void {
      this.icon = icon;
  }

  public setValueFormater(value: number): void {
    this.value = value;
    this.setFormattedValue();
  }

  public setValue(value: number): void {
    this.value = value;
    this.closeOptionList();
    this.setFormattedValue();
    this.emitUpdateEvent();
  }

  public getValue(): any {
    return this.value;
  }

  public setNullValue(nullValue: string): void {
    this.nullValue = nullValue;
  }

  public setAvailableValues(availableValues: Array<any>): void {
    this.availableValues = availableValues;
  }

  public onUpdate(value: number): void {
    this.setValue(value);
    this.closeOptionList();
    clearTimeout(this.blurExec);
  }

  private emitUpdateEvent() {
    const updateObject = {
      name: this.name,
      value: this.value,
    };
    this.update.emit(updateObject);
  }

  private setFormattedValue() {
    this.formattedValue = FormatMoneyPipe.format(this.value);
  }

  public openOrCloseOptionList($event = null) {
    const isTargetHasIconClas = $($event.target).hasClass('icon-x');
    const isTargetHasRemoveClass = $($event.target).hasClass('remove');
    const clickOnRemoveButton = isTargetHasIconClas || isTargetHasRemoveClass;
    if ($event === null || !clickOnRemoveButton) {
      this.isOptionListOpened = !this.isOptionListOpened;
    }
  }
  closeOptionList() {
      if (this.isOptionListOpened) {
        this.isOptionListOpened = false;
      }
  }

  onClickInput($event: Event) {
    const target: HTMLInputElement = $event.target as HTMLInputElement;
    if (this.value !== null) {
      target.value = this.value.toString();
    } else {
      target.value = '';
    }
  }

  onBlurInput($event: Event) {
    const target: HTMLInputElement = $event.target as HTMLInputElement;
    this.blurExec = setTimeout(() => {
      const value = target.value.replace(/[^0-9]/g, '');
      if (target.value.length > 0 && Number(value) > 0) {
        this.onUpdate(Number(value));
        this.setFormattedValue();
        target.value = this.nullValue + ' ' + this.formattedValue + ' zł';
      } else {
        this.value = null;
        target.value = this.nullValue;
      }
    }, 200);
  }
}
