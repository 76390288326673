<div class="stock-select stock-select--model-version"
     *ngIf="availableValues !== undefined"
     [attr.data-open]="isOptionListOpened"
     [ngClass]="{'selected': model !== null}">
    <div class="select-selection" (click)="openOrCloseOptionList($event)">
        <i class="icon {{icon}}" [ngClass]="{icon: icon !== null}" *ngIf="icon !== null"></i>
        <div class="select-selection-label">
            {{getDisplayValue()}}
        </div>
        <div class="select-selection-icons">
            <b *ngIf="model !== null" (click)="onUpdate(null, null)" class="remove"><i class="icon icon-x"></i></b>
            <b *ngIf="model === null && isOptionListOpened"> <i class="arrow arrow--down"></i></b>
            <b *ngIf="model === null && !isOptionListOpened" class="arrow arrow--up"></b>
        </div>
    </div>
    <div class="select-options">
        <div class="select-option-list" *ngIf="isOptionListOpened">
            <div *ngFor="let item of availableValues" class="option" (mouseover)="onFocus(item)" [ngClass]="{'selected': isSelectedModel(item.key)}">
                <div class="option__item" (click)="onUpdate(item.key, null)">
                    <div class="option__label">
                        <span class="option__model">
                            <img class="option__img" src="{{item.img}}"/>
                        </span>
                        <span class="option__model-name">
                            {{item.value}}
                        </span>
                        <i *ngIf="item.versions && item.versions.length > 0 && openedSubSelect !== item.key" class="icon icon-arrow_right"></i>
                        <i *ngIf="item.versions && item.versions.length > 0 && openedSubSelect === item.key" class="icon icon-arrow_left"></i>
                    </div>
                </div>

                <div class="option__select-list" *ngIf="openedSubSelect === item.key" (mouseleave)="openedSubSelect=null">
                    <div class="option__select-list__item">
                        Wersje wyposażenia
                    </div>

                    <div *ngFor="let v of item.versions" class="option__select-list__item option" (click)="onUpdateVersion(item.key,v.key)" [ngClass]="{'selected': isSelectedModel(item.key) && isSelectedVersion(v.key)}">
                        <div class="option__item">
                            <input class="option__checkbox" type="checkbox" [checked]="isSelectedModel(item.key) && isSelectedVersion(v.key)">
                            <div class="option__label">
                                <span class="option__check">
                                    <i class="icon icon-check"></i>
                                </span>
                                {{v.value}}
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</div>
