import {Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ISearchFormElement} from '../../interface/i-search-form-element';

declare var $: any;

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html'
})
export class SelectComponent implements OnInit, OnDestroy, ISearchFormElement {

  @Input() protected name: string;
  @Input() icon: any = null;
  @Input() value: any = null;
  @Input() availableValues: Array<any>;
  @Input() nullValue: string;
  isOptionListOpened = false;

  @Output() update = new EventEmitter<any>();

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.closeOptionList();
    }
  }

  constructor(private eRef: ElementRef) {
  }

  ngOnInit() {
    this.setAvailableValues(this.availableValues);
  }

  ngOnDestroy() {
  }

  public setName(name): void {
    this.name = name;
  }

  public getName(): string {
    return this.name;
  }

  public setIcon(icon): void {
      this.icon = icon;
  }

  public getIcon(): string {
      return this.icon;
  }

  public setValue(value: any): void {
    this.value = value;
    this.closeOptionList();
    this.emitUpdateEvent();
  }

  public getValue(): any {
    return this.value;
  }

  public setNullValue(nullValue: string): void {
    this.nullValue = nullValue;
  }

  public setAvailableValues(availableValues: Array<any>): void {
    this.availableValues = availableValues;
  }

  public onUpdate(value: any): void {
    this.setValue(value);
    this.closeOptionList();
  }

  private emitUpdateEvent() {
    const updateObject = {
      name: this.name,
      value: this.value,
    };
    this.update.emit(updateObject);
  }

  public getValueToDisplay(value: any): string {
    let val = null;
    this.availableValues.forEach(value1 => {
      if (value1.key === value) {
        val = value1.value;
      }
    });

    return val === null ? this.nullValue : val;
  }

  public openOrCloseOptionList($event = null) {
    const isTargetHasIconClas = $($event.target).hasClass('icon-x');
    const isTargetHasRemoveClass = $($event.target).hasClass('remove');
    const clickOnRemoveButton = isTargetHasIconClas || isTargetHasRemoveClass;
    if ($event === null || !clickOnRemoveButton) {
      this.isOptionListOpened = !this.isOptionListOpened;
    }
  }

  closeOptionList() {
    this.isOptionListOpened = false;
  }
}
