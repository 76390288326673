import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { StockApiService } from './stock-api.service';
import { BrandService } from '../services/brand.service';
import * as i0 from "@angular/core";
import * as i1 from "./stock-api.service";
import * as i2 from "@angular/router";
import * as i3 from "@angular/platform-browser";
var TitleService = /** @class */ (function () {
    function TitleService(stockApiService, router, title) {
        this.stockApiService = stockApiService;
        this.router = router;
        this.title = title;
    }
    TitleService.prototype.setTitleList = function (model, dealer) {
        var brand = BrandService.getCurrentBrand().name;
        if (model !== null) {
            model = ' ' + model;
        }
        else {
            model = '';
        }
        if (dealer !== null) {
            dealer = ' - Samochody demonstracyjne dostępne u dealera ' + dealer;
            this.title.setTitle(brand + model + dealer);
        }
        else {
            // this.title.setTitle('Salon ' + brand + model + ' - Wyprzedaż samochodów dostępnych u dealerów');
            this.title.setTitle('Samochody demonstracyjne u dealerów marki ' + brand);
        }
    };
    TitleService.prototype.setTitleOffer = function (model, version, dealer) {
        var brand = BrandService.getCurrentBrand().name;
        if (dealer !== 'invenduto') {
            this.title.setTitle(brand + ' ' + model + ' ' + version + ' - Samochód dostępny u dealera ' + dealer);
        }
        else {
            this.title.setTitle(brand + ' ' + model + ' ' + version +
                ' - Samochód dostępny na placu centralnym FCA Poland oraz u każdego dealera w Polsce');
        }
    };
    TitleService.prototype.setTitleObserved = function () {
        var brand = BrandService.getCurrentBrand().name;
        this.title.setTitle(brand + ' - Obserwowane ogłoszenia');
    };
    TitleService.prototype.setTitleDealerList = function () {
        var brand = BrandService.getCurrentBrand().name;
        this.title.setTitle(brand + ' - Lista dealerów');
    };
    TitleService.ngInjectableDef = i0.defineInjectable({ factory: function TitleService_Factory() { return new TitleService(i0.inject(i1.StockApiService), i0.inject(i2.Router), i0.inject(i3.Title)); }, token: TitleService, providedIn: "root" });
    return TitleService;
}());
export { TitleService };
