import { ElementRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { StockApiService } from '../../services/stock-api.service';
import { ErrorHandler } from '../../tools/error-handler';
var ModelVersionSelectComponent = /** @class */ (function () {
    function ModelVersionSelectComponent(eRef, stockApiService) {
        this.eRef = eRef;
        this.stockApiService = stockApiService;
        this.model = null;
        this.version = null;
        this.sendStats = false;
        this.isOptionListOpened = false;
        this.openedSubSelect = false;
        this.allVersions = false;
        this.update = new EventEmitter();
    }
    ModelVersionSelectComponent.prototype.clickout = function (event) {
        if (!this.eRef.nativeElement.contains(event.target)) {
            this.closeOptionList();
        }
    };
    ModelVersionSelectComponent.prototype.ngOnInit = function () {
        this.setAvailableValues(this.availableValues);
    };
    ModelVersionSelectComponent.prototype.getCountOfSelectedModels = function () {
        var counter = 0;
        if (this.model !== null && this.model.split(':;').length > 1) {
            for (var _i = 0, _a = this.availableValues; _i < _a.length; _i++) {
                var model = _a[_i];
                for (var _b = 0, _c = this.model.split(':;'); _b < _c.length; _b++) {
                    var m = _c[_b];
                    if (model.key === m) {
                        counter++;
                    }
                }
            }
        }
        return counter;
    };
    ModelVersionSelectComponent.prototype.ngOnDestroy = function () {
    };
    ModelVersionSelectComponent.prototype.setName = function (name) {
        this.name = name;
    };
    ModelVersionSelectComponent.prototype.getName = function () {
        return this.name;
    };
    ModelVersionSelectComponent.prototype.setIcon = function (icon) {
        this.icon = icon;
    };
    ModelVersionSelectComponent.prototype.getIcon = function () {
        return this.icon;
    };
    ModelVersionSelectComponent.prototype.setValue = function (value) {
        this.model = value.model;
        this.version = value.version;
    };
    ModelVersionSelectComponent.prototype.getValue = function () {
        return {
            model: this.model,
            version: this.version
        };
    };
    ModelVersionSelectComponent.prototype.setNullValue = function (nullValue) {
        this.nullValue = nullValue;
    };
    ModelVersionSelectComponent.prototype.setAvailableValues = function (availableValues) {
        this.availableValues = availableValues;
    };
    ModelVersionSelectComponent.prototype.onUpdate = function (model, modelVersion) {
        if (this.model !== model) {
            this.version = null;
        }
        this.model = model;
        if (this.sendStats && this.model !== null) {
            this.stockApiService.sendSearchStats(this.model, this.version).catch(function (reason) {
                ErrorHandler.log(reason);
            });
        }
        this.emitUpdateEvent();
        this.closeOptionList();
    };
    ModelVersionSelectComponent.prototype.onUpdateVersion = function (model, modelVersion) {
        if (this.model !== model) {
            this.version = null;
        }
        this.model = model;
        if (this.version) {
            var versionsList = this.version.split(':;');
            if (versionsList.indexOf(modelVersion) > -1) {
                versionsList.splice(versionsList.indexOf(modelVersion), 1);
            }
            else {
                versionsList.push(modelVersion);
            }
            this.version = versionsList.join(':;');
        }
        else {
            this.version = modelVersion;
        }
        if (this.sendStats && this.model !== null) {
            this.stockApiService.sendSearchStats(this.model, this.version).catch(function (reason) {
                ErrorHandler.log(reason);
            });
        }
        this.emitUpdateEvent();
    };
    ModelVersionSelectComponent.prototype.emitUpdateEvent = function () {
        var modelUpdateObject = {
            name: 'models',
            value: this.model,
        };
        var versionUpdateObject = {
            name: 'version',
            value: this.version,
        };
        this.update.emit(modelUpdateObject);
        this.update.emit(versionUpdateObject);
    };
    ModelVersionSelectComponent.prototype.openOrCloseOptionList = function ($event) {
        if ($event === void 0) { $event = null; }
        var isTargetHasIconClas = $($event.target).hasClass('icon-x');
        var isTargetHasRemoveClass = $($event.target).hasClass('remove');
        var clickOnRemoveButton = isTargetHasIconClas || isTargetHasRemoveClass;
        if ($event === null || !clickOnRemoveButton) {
            this.isOptionListOpened = !this.isOptionListOpened;
        }
    };
    ModelVersionSelectComponent.prototype.closeOptionList = function () {
        this.isOptionListOpened = false;
        this.openedSubSelect = null;
    };
    ModelVersionSelectComponent.prototype.getDisplayModel = function () {
        if (this.model !== null && this.availableValues !== undefined) {
            for (var _i = 0, _a = this.availableValues; _i < _a.length; _i++) {
                var model = _a[_i];
                if (model.key === this.model) {
                    return model.value;
                }
            }
        }
    };
    ModelVersionSelectComponent.prototype.getDisplayVersion = function () {
        var _this = this;
        if (this.model !== null && this.availableValues !== undefined) {
            var _loop_1 = function (model) {
                if (model.key === this_1.model) {
                    if (model.versions && this_1.version !== null) {
                        var versionsList_1 = '';
                        var _loop_2 = function (modelVersion) {
                            var versions = this_1.version.split(':;');
                            versions.forEach(function (val) {
                                if (_this.availableValues !== undefined) {
                                    if (modelVersion.key === val) {
                                        if (versionsList_1 === '') {
                                            versionsList_1 = ': ' + val;
                                        }
                                        else {
                                            versionsList_1 += ', ' + val;
                                        }
                                    }
                                }
                            });
                        };
                        for (var _i = 0, _a = model.versions; _i < _a.length; _i++) {
                            var modelVersion = _a[_i];
                            _loop_2(modelVersion);
                        }
                        return { value: versionsList_1 };
                    }
                }
            };
            var this_1 = this;
            for (var _i = 0, _a = this.availableValues; _i < _a.length; _i++) {
                var model = _a[_i];
                var state_1 = _loop_1(model);
                if (typeof state_1 === "object")
                    return state_1.value;
            }
        }
    };
    ModelVersionSelectComponent.prototype.getDisplayValue = function () {
        if (this.model !== null) {
            var count = this.getCountOfSelectedModels();
            if (count > 1) {
                return 'Wybrane modele: ' + count;
            }
            else {
                if (this.version !== null && this.version.split(':;').length >= 1) {
                    return this.getDisplayModel() + this.getDisplayVersion();
                }
                else {
                    return this.getDisplayModel();
                }
            }
        }
        return this.nullValue;
    };
    ModelVersionSelectComponent.prototype.isSelectedModel = function (val) {
        if (this.model !== null && this.availableValues !== undefined) {
            for (var _i = 0, _a = this.model.split(':;'); _i < _a.length; _i++) {
                var m = _a[_i];
                if (val === m) {
                    return true;
                }
            }
        }
        return false;
    };
    ModelVersionSelectComponent.prototype.isSelectedVersion = function (val) {
        if (val === this.version) {
            return true;
        }
        if (this.version !== null) {
            for (var _i = 0, _a = this.version.split(':;'); _i < _a.length; _i++) {
                var v = _a[_i];
                if (val === v) {
                    return true;
                }
            }
        }
        return false;
    };
    ModelVersionSelectComponent.prototype.onFocus = function (item) {
        if (item.versions && item.versions.length > 0) {
            this.openedSubSelect = item.key;
        }
        else {
            this.openedSubSelect = null;
        }
    };
    return ModelVersionSelectComponent;
}());
export { ModelVersionSelectComponent };
