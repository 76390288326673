import {Component, Input, OnInit} from '@angular/core';
import {ObservedOfferService} from '../../services/observed-offer.service';
import {ComparedOfferService} from '../../services/compared-offer.service';
import {BrandService} from '../../services/brand.service';
import {AppComponent} from '../../app.component';
import {FullOffer} from '../../models/offer/full-offer';

@Component({
  selector: 'app-offer-box-compare',
  templateUrl: './offer-box-compare.component.html',
})
export class OfferBoxCompareComponent implements OnInit {

  @Input() item: FullOffer;
  brandName: string;
  brandSlug: string;
  subscriptionInstallment = false;
  rentingInstallment = false;

  constructor(
    public observedOffers: ObservedOfferService,
    public comparedOffers: ComparedOfferService,
  ) {
  }

  setDefaultImg(event) {
    if (event.currentTarget && event.currentTarget.parentElement) {
      event.currentTarget.parentElement.innerHTML = '<img src="/assets/img/default_img_290x158.png" class="box-model__photo-img" />';
    }
  }

  ngOnInit() {
    this.brandName = BrandService.getCurrentBrand().name;
    this.brandSlug = BrandService.getCurrentBrand().slug;

    if (this.item !== undefined && this.item.version.name.toLowerCase() === 'electric vehicle') {
      this.item.version = null;
    }
  }

  getFinancialProductPriceType(parameters): string {
    let priceType;
    for (const val in parameters) {
      if (parameters[val].netto) {
        priceType = 'netto';
      } else {
        priceType = 'brutto';
      }
    }
    return priceType;
  }
}
