import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-form-field',
  templateUrl: './field.component.html',
})
export class FieldComponent implements OnInit {
  @Input() data;
  @Input() form;
  @Input() changeZipCode: Observable<any>;
  @Input() errors: string[] = [];
  @Input() sendEvent: Observable<any> = null;
  @Input() brandSlug: string = null;
  value: string = null;
  @Output() valueChange: EventEmitter<object> = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
    this.value = this.data.value;
  }

  changeValue() {
    this.errors = [];
    this.valueChange.emit({name: this.data.name, value: this.getMaskPrefix() + this.value});
  }

  getPlaceholder() {
    if (this.data.placeholder !== null) {
      return this.data.placeholder + (this.data.required ? '*' : '');
    } else {
      return null;
    }
   }

  getMask() {
    if (this.data.format !== null) {

      if (/^\+48.*/.test(this.data.format)) {
        return this.data.format.replace(/^\+48\s/, '').replace(/9/g, '0');
      }

      return this.data.format.replace(/9/g, '0');
    }

    return null;
  }

  getMaskPrefix() {
    if (/^\+48\s/.test(this.data.format)) {
      return '+48 ';
    }

    return '';
  }

  setCaptchaResponse($event) {
    this.value = $event;
    this.valueChange.emit({name: this.data.name, value: this.value});
  }

  setValue(value: any) {
    this.value = value;

    if (this.data.name === 'communication_channel') {
      if (value === 'M') {
        this.form.fields['phone']['required'] = false;
      } else {
        this.form.fields['phone']['required'] = true;
      }
    }

    this.changeValue();
  }
}
