import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfferCardComponent } from './offer-card/offer-card.component';
import { OfferBoxComponent } from './offer-box/offer-box.component';
import { OfferBoxCompareComponent } from './offer-box-compare/offer-box-compare.component';
import {ToolsModule} from '../tools/tools.module';
import {RouterModule} from '@angular/router';
import { LoaderComponent } from './loader/loader.component';
import { FormComponent } from './form/form.component';
import { FieldComponent } from './form/field/field.component';
import { AgreementComponent } from './form/agreement/agreement.component';
import { DealerSelectComponent } from './form/field/dealer-select/dealer-select.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxMaskModule} from 'ngx-mask';
import {NgxCaptchaModule} from 'ngx-captcha';
import { CaptchaComponent } from './form/field/captcha/captcha.component';
import { ObservedOfferCardComponent } from './observed-offer-card/observed-offer-card.component';
import { ObservedOfferBoxComponent } from './observed-offer-box/observed-offer-box.component';
import { OfferLabelsComponent } from './offer-labels/offer-labels.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PreferredContactComponent } from './form/field/preferred-contact/preferred-contact.component';
import { PictureComponent } from './picture/picture.component';
import { BoxComparePictureComponent } from './box-compare-picture/box-compare-picture.component';
import { LazyLoadImageModule, scrollPreset } from 'ng-lazyload-image';
import { BannerComponent } from './banner/banner.component';
import { PreReservationComponent } from './form/field/pre-reservation-checkbox/pre-reservation-component';
import { TrackingDebuggerComponent } from './tracking-debugger/tracking-debugger.component';
import {PortalModule} from '@angular/cdk/portal';
import { NgxJsonViewerModule } from 'ngx-json-viewer';

@NgModule({
  declarations: [
    OfferCardComponent,
    OfferBoxComponent,
    OfferBoxCompareComponent,
    LoaderComponent,
    FormComponent,
    FieldComponent,
    AgreementComponent,
    DealerSelectComponent,
    CaptchaComponent,
    DealerSelectComponent,
    PreReservationComponent,
    ObservedOfferCardComponent,
    ObservedOfferBoxComponent,
    OfferLabelsComponent,
    NotFoundComponent,
    PreferredContactComponent,
    PictureComponent,
    BoxComparePictureComponent,
    BannerComponent,
    TrackingDebuggerComponent
  ],
  imports: [
    CommonModule,
    ToolsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule,
    NgxCaptchaModule,
    LazyLoadImageModule.forRoot({
      preset: scrollPreset
    }),
    PortalModule,
    NgxJsonViewerModule,
  ],
    exports: [
        OfferCardComponent,
        OfferBoxComponent,
        OfferBoxCompareComponent,
        LoaderComponent,
        FormComponent,
        ObservedOfferCardComponent,
        ObservedOfferBoxComponent,
        NotFoundComponent,
        OfferLabelsComponent,
        BannerComponent,
        TrackingDebuggerComponent,
    ],
  entryComponents: [
    OfferCardComponent,
    OfferBoxComponent,
    LoaderComponent,
    FormComponent,
  ]
})
export class AppComponentsModule { }
