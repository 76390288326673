/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../app-components/not-found/not-found.component.ngfactory";
import * as i2 from "../app-components/not-found/not-found.component";
import * as i3 from "../services/stock-api.service";
import * as i4 from "./page-not-found.component";
import * as i5 from "../services/blue-kai.service";
var styles_PageNotFoundComponent = [];
var RenderType_PageNotFoundComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PageNotFoundComponent, data: {} });
export { RenderType_PageNotFoundComponent as RenderType_PageNotFoundComponent };
export function View_PageNotFoundComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-not-found", [], null, null, null, i1.View_NotFoundComponent_0, i1.RenderType_NotFoundComponent)), i0.ɵdid(1, 114688, null, 0, i2.NotFoundComponent, [i3.StockApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_PageNotFoundComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-page-not-found", [], null, null, null, View_PageNotFoundComponent_0, RenderType_PageNotFoundComponent)), i0.ɵdid(1, 114688, null, 0, i4.PageNotFoundComponent, [i5.BlueKaiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageNotFoundComponentNgFactory = i0.ɵccf("app-page-not-found", i4.PageNotFoundComponent, View_PageNotFoundComponent_Host_0, {}, {}, []);
export { PageNotFoundComponentNgFactory as PageNotFoundComponentNgFactory };
