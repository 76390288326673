<ng-container *ngIf="bannerPosition === 'header' && generateBanner() === true">
    <a [href]="bannerLink"
       [target]="bannerTarget"
       *ngIf="bannerType === 'link'"
        class="header__banner d-none d-lg-block">
      <img class="d-none d-xl-block"
           [lazyLoad]="'assets/img/brands/' + brand.slug + '/banner/' + banner_620x100"/>
      <img class="d-none d-lg-block d-xl-none"
           [lazyLoad]="'assets/img/brands/' + brand.slug + '/banner/' + banner_470x85"/>
    </a>
    <span *ngIf="bannerType === 'span'" class="header__banner d-none d-lg-block">
      <img class="d-none d-xl-block"
           [lazyLoad]="'assets/img/brands/' + brand.slug + '/banner/' + banner_620x100"/>
      <img class="d-none d-lg-block d-xl-none"
           [lazyLoad]="'assets/img/brands/' + brand.slug + '/banner/F' + banner_470x85"/>
    </span>
</ng-container>
<ng-container *ngIf="brand && bannerPosition === 'section' && generateBanner() === true">
  <section class="section section-banner mt-5 mt-sm-3 mb-3 d-lg-none">
    <div class="container">
      <div class="row">
<!--        <div class="col-12" [ngClass]="{'m-banner-ar': brand.slug === 'alfa-romeo'}">-->
        <div class="col-12">
          <a [href]="bannerLink"
             [target]="bannerTarget"
             *ngIf="bannerType === 'link'"
             class="header__banner d-block">
            <img class="mx-auto mw-100" data-version="mobile-320"
                 [lazyLoad]="'assets/img/brands/' + brand.slug + '/banner/' + banner_320x50"/>
            <img class="mx-auto mw-100" data-version="mobile"
                 [lazyLoad]="'assets/img/brands/' + brand.slug + '/banner/' + banner_470x85"/>
            <img class="mx-auto mw-100" data-version="tablet"
                 [lazyLoad]="'assets/img/brands/' + brand.slug + '/banner/' + banner_690x100"/>
          </a>
          <span *ngIf="bannerType === 'span'" class="header__banner d-lg-block">
            <img class="mx-auto mw-100" data-version="mobile-320"
                 [lazyLoad]="'assets/img/brands/' + brand.slug + '/banner/' + banner_320x50"/>
            <img class="mx-auto mw-100" data-version="mobile"
                 [lazyLoad]="'assets/img/brands/' + brand.slug + '/banner/' + banner_470x85"/>
            <img class="mx-auto mw-100" data-version="tablet"
                 [lazyLoad]="'assets/img/brands/' + brand.slug + '/banner/' + banner_690x100"/>
          </span>
        </div>
      </div>
    </div>
  </section>
</ng-container>
