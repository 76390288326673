import { StockApiService } from './stock-api.service';
import * as i0 from "@angular/core";
import * as i1 from "./stock-api.service";
var LabelProviderService = /** @class */ (function () {
    function LabelProviderService(stockApiService) {
        this.stockApiService = stockApiService;
        this.modelDiscount = null;
    }
    LabelProviderService.prototype.init = function () {
        var _this = this;
        this.stockApiService.getModelDiscount().then(function (value) {
            _this.modelDiscount = value;
            console.log(value);
        });
    };
    LabelProviderService.prototype.fillOfferLabelsByArray = function (offers) {
        var newOffersArray = [];
        for (var _i = 0, offers_1 = offers; _i < offers_1.length; _i++) {
            var offer = offers_1[_i];
            newOffersArray.push(this.fillOfferLabels(offer));
        }
        return newOffersArray;
    };
    LabelProviderService.prototype.fillOfferLabels = function (offer) {
        var model = null;
        if (typeof offer.model === 'string') {
            model = offer.model;
        }
        else {
            model = offer.model.group.name;
        }
        if (this.hasLowestPrice(model, offer.price.final.netto) && offer.labels.indexOf('Najniższa cena') === -1) {
            offer.labels.push('Najniższa cena');
        }
        if (this.hasMaxDiscount(model, offer.price.discount.percent) && offer.labels.indexOf('Największy rabat') === -1) {
            offer.labels.push('Największy rabat');
        }
        return offer;
    };
    LabelProviderService.prototype.hasMaxDiscount = function (model, discount) {
        for (var _i = 0, _a = this.modelDiscount.max_discount; _i < _a.length; _i++) {
            var item = _a[_i];
            if (item.model === model && discount >= item.max_discount && discount > 0) {
                return true;
            }
        }
        return false;
    };
    LabelProviderService.prototype.hasLowestPrice = function (model, price) {
        for (var _i = 0, _a = this.modelDiscount.lowest_price; _i < _a.length; _i++) {
            var item = _a[_i];
            if (item.model === model && price <= item.lowest_price) {
                return true;
            }
        }
        return false;
    };
    LabelProviderService.ngInjectableDef = i0.defineInjectable({ factory: function LabelProviderService_Factory() { return new LabelProviderService(i0.inject(i1.StockApiService)); }, token: LabelProviderService, providedIn: "root" });
    return LabelProviderService;
}());
export { LabelProviderService };
