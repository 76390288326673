import { Component, OnInit, Input } from '@angular/core';
import {Brand} from '../../models/Brands';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html'
})
export class BannerComponent implements OnInit {

  @Input() bodyClass: string;
  @Input() bannerPosition: string;
  @Input() brand: Brand;
  @Input() currentModel: string;
  @Input() model: string;
  bannerLCV = 0;
  bannerType: string;
  bannerTarget: string;
  bannerLink: string;
  banner_320x50: string;
  banner_470x85: string;
  banner_620x100: string;
  banner_690x100: string;
  arrayBanners = {
    'fiat': {
      'default': {
        '20201001': {
          0: {
            'type': 'link', // or span -> 'target': '', 'url': ''
            'target': '_blank', // or _self
            'url': '',
            'showInOffer': false,
            '320x50': '',
            '470x85': '',
            '620x100': '',
            '690x100': ''
          }
        }
      }
    },
    'fiat-professional': {
      'rotate': {
        '20201006': {
          0: {
            'type': 'link',
            'target': '_blank',
            'url': '',
            'showInOffer': false,
            '320x50': '',
            '470x85': '',
            '620x100': '',
            '690x100': ''
          },
          1: {
            'type': 'link',
            'target': '_blank',
            'url': '',
            'showInOffer': false,
            '320x50': '',
            '470x85': '',
            '620x100': '',
            '690x100': ''
          }
        }
      }
    },
    'alfa-romeo': {
      'default': {
        '20201001': {
          0: {
            'type': 'link',
            'target': '_self',
            'url': '',
            'showInOffer': false,
            '320x50': '',
            '470x85': '',
            '620x100': '',
            '690x100': ''
          }
        }
      }
    },
    'jeep': {
      'default': {
        '20200525': {
          0: {
            'type': 'link',
            'target': '_blank',
            'url': '',
            'showInOffer': false,
            '320x50': '',
            '470x85': '',
            '620x100': '',
            '690x100': ''
          }
        }
      }
    },
    'abarth': {
      'default': {
        '20200525': {
          0: {
            'type': 'link',
            'target': '_blank',
            'url': '',
            'showInOffer': false,
            '320x50': '',
            '470x85': '',
            '620x100': '',
            '690x100': ''
          }
        }
      }
    }
  };

  constructor() {
    this.bannerLCV = this.generateNumberRandomForBanner(3);
  }

  ngOnInit() {
  }

  generateNumberRandomForBanner(numberOfBanners) {
    return Math.floor(Math.random() * numberOfBanners);
  }
  getCurrentDate() {
    const today = new Date();
    const day = ('0' + today.getDate()).slice(-2);
    const month = ('0' + (today.getMonth() + 1)).slice(-2);
    const year = today.getFullYear();

    return year + '' + month + '' + day;
  }

  generateBanner() {

    const now = this.getCurrentDate();

    let model;
    if (this.bodyClass === 'page page-offert') {
      model = this.currentModel;
    } else {
      model = this.model;
    }

    switch (this.brand.slug) {
      case 'fiat':
        return false;
      case 'fiat-professional':
        return false;
      case 'alfa-romeo':
        return false;
      case 'jeep':
          return false;
      case 'abarth':
          return false;
      default:
        return false;
    }
  }

  setBanner(brand, model, key, id) {

    if (this.bodyClass === 'page page-offert' && this.arrayBanners[brand][model][key][id]['showInOffer'] === false) {
      return false;
    }

    this.bannerLink = this.arrayBanners[brand][model][key][id]['url'];
    this.bannerTarget = this.arrayBanners[brand][model][key][id]['target'];
    this.bannerType = this.arrayBanners[brand][model][key][id]['type'];

    this.banner_320x50 = this.arrayBanners[brand][model][key][id]['320x50'];
    this.banner_470x85 = this.arrayBanners[brand][model][key][id]['470x85'];
    this.banner_620x100 = this.arrayBanners[brand][model][key][id]['620x100'];
    this.banner_690x100 = this.arrayBanners[brand][model][key][id]['690x100'];

    return true;
  }
}
