<section *ngIf="brandSlug !== 'fiat' && brandSlug !== 'fiat-professional'"
  class="section section--list-offers-result row horizontal-view section--page-content mt-0">
  <div class="container">
    <div class="row">
      <div class="col-12 col-sm-7">
        <div class="headline-wrap">
          <div class="headline">
            <h3 class="headline__title text-uppercase">Obserwowane ogłoszenia <strong>({{observedOfferService.getAllOffers(AppComponent.dealerCode).length}})</strong></h3>
            <div class="headline__footer-link"><a class="text-uppercase" [routerLink]="'/' | addUrlPrefix"><span class="icon-arrow_left"></span> wróć do strony głównej</a></div>
          </div>
        </div>
        <a class="link link--under-headline" (click)="removeObservedOffers()">WYCZYŚĆ WSZYSTKIE<br />OBSERWOWANE</a>
      </div>
      <div class="col-lg-9 offset-lg-3">
        <div class="page-content">
          <div class="row models-wrap models--list horizontal-view">
            <app-observed-offer-card class="col-12" *ngFor="let item of offers" [item]="item" [isActive]="true" [isOffersLoaded]="!loading"></app-observed-offer-card>
            <ng-container *ngFor="let item of observedOfferService.getAllOffers(AppComponent.dealerCode)">
              <app-observed-offer-card class="col-12" *ngIf="!isActive(item) && observedOfferService.getOfferData(item) !== null" [item]="observedOfferService.getOfferData(item)" [isActive]="false" [isOffersLoaded]="!loading"></app-observed-offer-card>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <loader [visible]="loading"></loader>
</section>
<section *ngIf="brandSlug === 'fiat' || brandSlug === 'fiat-professional'"
         class="section section--list-offers-result section--page-content mt-0">
  <div class="container">
    <div class="row">
      <div class="sidebar--filters col-12 col-lg-4 offset-lg-0 col-xl-3">
        <div class="headline-wrap">
          <div class="headline">
            <h3 class="headline__title">ULUBIONE</h3>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-8 col-xl-9">
        <div class="row mt-3">
          <div  class="col-6 pb-3 d-flex d-lg-none">

            <div class="title-offer-list">
              <span>ULUBIONE</span>
            </div>
          </div>
          <h3 class="result-nav__count col-6 d-inline-block d-lg-none"><strong>{{observedOfferService.getAllOffers(AppComponent.dealerCode).length}}</strong> {{observedOfferService.getAllOffers(AppComponent.dealerCode).length | offerWord}}</h3>
        </div>

        <div class="page-content">
          <div class="row models-wrap models--list">
            <h1 *ngIf="offers.length === 0 && observedOfferService.getAllOffers(AppComponent.dealerCode).length === 0" class="w-100 mt-5 text-center">Brak ofert</h1>
            <ng-container *ngFor="let item of offers">
              <app-observed-offer-box class="col-6 col-md-4 col-lg-6 col-xl-4" *ngIf="observedOfferService.isObserved(item.uid)" [item]="item" [isActive]="true" [isOffersLoaded]="!loading"></app-observed-offer-box>
            </ng-container>
            <ng-container *ngFor="let item of observedOfferService.getAllOffers(AppComponent.dealerCode)">
              <app-observed-offer-box class="col-6 col-md-4 col-lg-6 col-xl-4" *ngIf="!isActive(item) && observedOfferService.getOfferData(item) !== null" [item]="observedOfferService.getOfferData(item)" [isActive]="false" [isOffersLoaded]="!loading"></app-observed-offer-box>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <loader [visible]="loading"></loader>
</section>
