import {Component, Input, OnInit} from '@angular/core';
import {BasicOffer} from '../../models/offer/basic-offer';
import {ObservedOfferService} from '../../services/observed-offer.service';
import {BrandService} from '../../services/brand.service';
import {AppComponent} from '../../app.component';
import {ComparedOfferService} from '../../services/compared-offer.service';

@Component({
  selector: 'app-observed-offer-box',
  templateUrl: './observed-offer-box.component.html',
})
export class ObservedOfferBoxComponent implements OnInit {

  @Input() item: BasicOffer;
  @Input() isOffersLoaded: boolean;
  @Input() isActive: boolean;
  brandName: string;
  brandSlug: string;

  constructor(
      public observedOffers: ObservedOfferService,
      public comparedOffers: ComparedOfferService,
  ) {
  }

  setDefaultImg(event) {
    if (event.currentTarget && event.currentTarget.parentElement) {
      event.currentTarget.parentElement.innerHTML = '<img src="/assets/img/default_img_290x158.png" class="box-model__photo-img" />';
    }
  }

  ngOnInit() {
    this.brandName = BrandService.getCurrentBrand().name;
    this.brandSlug = BrandService.getCurrentBrand().slug;

    if (this.item.version.toLowerCase() === 'electric vehicle') {
      this.item.version = null;
    }
  }
}
