<section *ngIf="brandSlug === 'fiat' || brandSlug === 'fiat-professional'"
         class="section section--offers-compare section--page-content">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="headline-wrap">
          <div class="headline">
            <h3 class="headline__title">PORÓWNAJ OFERTY</h3>
          </div>
        </div>
        <div class="offers-compare-close">
          <a [routerLink]="'/' | addUrlPrefix">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18 18L2 2M18 2L2 18" stroke="#1B1C1B" stroke-width="2" stroke-linecap="round"/>
            </svg>
          </a>
        </div>
      </div>
      <div class="col-12" *ngIf="offers.length < 2 && !loading" style="padding: 100px 0; text-align: center">
        <div class="headline-wrap">
          <div class="headline">
            <h3 class="headline__title">ZA MAŁO OFERT, MUSZISZ WYBRAĆ DWIE OFERTY</h3>
          </div>
        </div>
      </div>
      <div class="col-12" *ngIf="offers.length === 2">

        <div class="models-compare" [ngClass]="{'showDifferent': showDifferentClass}">
          <div class="compare-row">
            <div class="compare-col-1 d-none d-lg-block">
              <span class="compare-check" (click)="showDifferent()">POKAŻ RÓŻNICE

                <span class="compare-check-icon">
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 18C1.45 18 0.979 17.8043 0.587 17.413C0.195667 17.021 0 16.55 0 16V2C0 1.45 0.195667 0.979 0.587 0.587C0.979 0.195667 1.45 0 2 0H16C16.55 0 17.021 0.195667 17.413 0.587C17.8043 0.979 18 1.45 18 2V16C18 16.55 17.8043 17.021 17.413 17.413C17.021 17.8043 16.55 18 16 18H2ZM7.6 12.775C7.73333 12.775 7.85833 12.7543 7.975 12.713C8.09167 12.671 8.2 12.6 8.3 12.5L13.975 6.825C14.1583 6.64167 14.25 6.41667 14.25 6.15C14.25 5.88333 14.15 5.65 13.95 5.45C13.7667 5.26667 13.5333 5.175 13.25 5.175C12.9667 5.175 12.7333 5.26667 12.55 5.45L7.6 10.4L5.425 8.225C5.24167 8.04167 5.01667 7.95 4.75 7.95C4.48333 7.95 4.25 8.05 4.05 8.25C3.86667 8.43333 3.775 8.66667 3.775 8.95C3.775 9.23333 3.86667 9.46667 4.05 9.65L6.9 12.5C7 12.6 7.10833 12.671 7.225 12.713C7.34167 12.7543 7.46667 12.775 7.6 12.775Z" fill="#01BDAE"/>
                  </svg>
                </span>
              </span>
            </div>
            <div class="compare-col-2">
              <app-offer-box-compare class="" [item]="offers[0]"></app-offer-box-compare>
            </div>
            <div class="compare-col-3">
              <app-offer-box-compare class="" [item]="offers[1]"></app-offer-box-compare>
            </div>
          </div>
          <ng-container *ngIf="offers[0] !== undefined && offers[1] !== undefined">
            <hr />

            <ng-container *ngIf="offers[0].technicalData.engineCapacity && offers[0].technicalData.engineCapacity != '0.0' && offers[0].technicalData.engineCapacity != 0.0 &&
            offers[1].technicalData.engineCapacity && offers[1].technicalData.engineCapacity != '0.0' && offers[1].technicalData.engineCapacity != 0.0">
              <div class="compare-row">
                <div class="compare-col-1">
                  POJEMNOŚĆ SKOKOWA
                </div>
                <div class="compare-col-2" [ngClass]="{'is-different': offers[0].technicalData.engineCapacity !== offers[1].technicalData.engineCapacity}">
                  {{offers[0].technicalData.engineCapacity}} cm<sup>3</sup>
                </div>
                <div class="compare-col-3">
                  {{offers[1].technicalData.engineCapacity}} cm<sup>3</sup>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="offers[0].technicalData.enginePower && offers[0].technicalData.averageFuelConsumption != 'N/A' && offers[0].technicalData.averageFuelConsumption != 'NA' &&
            offers[1].technicalData.enginePower && offers[1].technicalData.averageFuelConsumption != 'N/A' && offers[1].technicalData.averageFuelConsumption != 'NA'">
              <div class="compare-row">
                <div class="compare-col-1">
                  MOC SILNIKA
                </div>
                <div class="compare-col-2" [ngClass]="{'is-different': offers[0].technicalData.enginePower !== offers[1].technicalData.enginePower}">
                  {{offers[0].technicalData.enginePower}}
                </div>
                <div class="compare-col-3">
                  {{offers[1].technicalData.enginePower}}
                </div>
              </div>
            </ng-container>
            <div class="compare-row">
              <div class="compare-col-1">
                SKRZYNIA BIEGÓW
              </div>
              <div class="compare-col-2" [ngClass]="{'is-different': offers[0].technicalData.gearboxType !== offers[1].technicalData.gearboxType}">
                {{offers[0].technicalData.gearboxType}}
              </div>
              <div class="compare-col-3">
                {{offers[1].technicalData.gearboxType}}
              </div>
            </div>
            <ng-container *ngIf="offers[0].technicalData.fuelType && offers[0].technicalData.fuelType != 'nieokreślony' &&
            offers[1].technicalData.fuelType && offers[1].technicalData.fuelType != 'nieokreślony'">
              <div class="compare-row">
                <div class="compare-col-1">
                  RODZAJ PALIWA
                </div>
                <div class="compare-col-2" [ngClass]="{'is-different': offers[0].technicalData.fuelType !== offers[1].technicalData.fuelType}">
                  {{offers[0].technicalData.fuelType}}
                </div>
                <div class="compare-col-3">
                  {{offers[1].technicalData.fuelType}}
                </div>
              </div>
            </ng-container>
            <div class="compare-row">
              <div class="compare-col-1">
                ROK PRODUKCJI
              </div>
              <div class="compare-col-2" [ngClass]="{'is-different': offers[0].productionYear !== offers[1].productionYear}">
                {{offers[0].productionYear}}
              </div>
              <div class="compare-col-3">
                {{offers[1].productionYear}}
              </div>
            </div>
            <div class="compare-row">
              <div class="compare-col-1">
                PRZEBIEG
              </div>
              <div class="compare-col-2" [ngClass]="{'is-different': offers[0].mileage !== offers[1].mileage}">
                {{offers[0].mileage|formatMoney}} km
              </div>
              <div class="compare-col-3">
                {{offers[1].mileage|formatMoney}} km
              </div>
            </div>
            <div class="compare-row row-dark">
              <div class="compare-col-1">
                SPRZEDAWCY
              </div>
              <div class="compare-col-2 compare-dealer">
                <div class="compare-dealer-name">
                  {{offers[0].dealer.name}}
                </div>

                <div class="compare-dealer-phone" *ngIf="!visibleNumber[0] && offers[0].dealer.phones && offers[0].dealer.phones.length > 0" (click)="showPhone(offers[0], 0)">
                  <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.5 0H2.5C1.83696 0 1.20107 0.263392 0.732233 0.732233C0.263392 1.20107 0 1.83696 0 2.5V19.5C0 20.163 0.263392 20.7989 0.732233 21.2678C1.20107 21.7366 1.83696 22 2.5 22H10.5C11.163 22 11.7989 21.7366 12.2678 21.2678C12.7366 20.7989 13 20.163 13 19.5V2.5C13 1.83696 12.7366 1.20107 12.2678 0.732233C11.7989 0.263392 11.163 0 10.5 0ZM6.5 21C5.67 21 5 20.33 5 19.5C5 18.67 5.67 18 6.5 18C7.33 18 8 18.67 8 19.5C8 20.33 7.33 21 6.5 21ZM11 17H2V3H11V17Z" fill="#FEFFFF"/>
                  </svg>
                  <span>+48 ... <small>POKAŻ NUMER</small></span>
                </div>

                <div class="compare-dealer-phone" *ngIf="visibleNumber[0] && offers[0].dealer.phones && offers[0].dealer.phones.length > 0">
                  <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.5 0H2.5C1.83696 0 1.20107 0.263392 0.732233 0.732233C0.263392 1.20107 0 1.83696 0 2.5V19.5C0 20.163 0.263392 20.7989 0.732233 21.2678C1.20107 21.7366 1.83696 22 2.5 22H10.5C11.163 22 11.7989 21.7366 12.2678 21.2678C12.7366 20.7989 13 20.163 13 19.5V2.5C13 1.83696 12.7366 1.20107 12.2678 0.732233C11.7989 0.263392 11.163 0 10.5 0ZM6.5 21C5.67 21 5 20.33 5 19.5C5 18.67 5.67 18 6.5 18C7.33 18 8 18.67 8 19.5C8 20.33 7.33 21 6.5 21ZM11 17H2V3H11V17Z" fill="#FEFFFF"/>
                  </svg>
                  <span>{{ visibleNumber[0] }}</span>
                </div>

                <div class="compare-dealer-all-offer">
                  <a [href]="'/dealer/' + offers[0].dealer.code | addUrlPrefix">
                    <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.1489 0.189729L19.8085 8.04195C19.869 8.10103 19.9172 8.17209 19.9501 8.25083C19.983 8.32957 20 8.41434 20 8.5C20 8.58566 19.983 8.67043 19.9501 8.74917C19.9172 8.82791 19.869 8.89897 19.8085 8.95805L12.1489 16.8103C12.0304 16.9318 11.8697 17 11.7021 17C11.5345 17 11.3738 16.9318 11.2553 16.8103C11.1368 16.6888 11.0702 16.524 11.0702 16.3522C11.0702 16.1804 11.1368 16.0157 11.2553 15.8942L17.8191 9.15435L0.638298 9.15435C0.46901 9.15435 0.306657 9.08541 0.186953 8.9627C0.0672486 8.83998 -3.63961e-07 8.67354 -3.71547e-07 8.5C-3.79133e-07 8.32646 0.0672486 8.16002 0.186953 8.0373C0.306657 7.91459 0.46901 7.84565 0.638298 7.84565L17.8191 7.84565L11.2553 1.10582C11.1368 0.98434 11.0702 0.819576 11.0702 0.647775C11.0702 0.475974 11.1368 0.311211 11.2553 0.189729C11.3738 0.0682477 11.5345 -5.00804e-07 11.7021 -5.11516e-07C11.8697 -5.22228e-07 12.0304 0.0682477 12.1489 0.189729Z" fill="#FEFFFF"/>
                    </svg>
                    <span>ZOBACZ WSZYSTKIE OFERTY TEGO&nbsp;DEALERA</span>
                  </a>
                </div>
              </div>
              <div class="compare-col-3 compare-dealer">
                <div class="compare-dealer-name">
                  {{offers[1].dealer.name}}
                </div>

                <div class="compare-dealer-phone" *ngIf="!visibleNumber[1] && offers[1].dealer.phones && offers[1].dealer.phones.length > 0"(click)="showPhone(offers[1], 1)">
                  <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.5 0H2.5C1.83696 0 1.20107 0.263392 0.732233 0.732233C0.263392 1.20107 0 1.83696 0 2.5V19.5C0 20.163 0.263392 20.7989 0.732233 21.2678C1.20107 21.7366 1.83696 22 2.5 22H10.5C11.163 22 11.7989 21.7366 12.2678 21.2678C12.7366 20.7989 13 20.163 13 19.5V2.5C13 1.83696 12.7366 1.20107 12.2678 0.732233C11.7989 0.263392 11.163 0 10.5 0ZM6.5 21C5.67 21 5 20.33 5 19.5C5 18.67 5.67 18 6.5 18C7.33 18 8 18.67 8 19.5C8 20.33 7.33 21 6.5 21ZM11 17H2V3H11V17Z" fill="#FEFFFF"/>
                  </svg>
                  <span>+48 ... <small>POKAŻ NUMER</small></span>
                </div>

                <div class="compare-dealer-phone" *ngIf="visibleNumber[1] && offers[1].dealer.phones && offers[1].dealer.phones.length > 0">
                  <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.5 0H2.5C1.83696 0 1.20107 0.263392 0.732233 0.732233C0.263392 1.20107 0 1.83696 0 2.5V19.5C0 20.163 0.263392 20.7989 0.732233 21.2678C1.20107 21.7366 1.83696 22 2.5 22H10.5C11.163 22 11.7989 21.7366 12.2678 21.2678C12.7366 20.7989 13 20.163 13 19.5V2.5C13 1.83696 12.7366 1.20107 12.2678 0.732233C11.7989 0.263392 11.163 0 10.5 0ZM6.5 21C5.67 21 5 20.33 5 19.5C5 18.67 5.67 18 6.5 18C7.33 18 8 18.67 8 19.5C8 20.33 7.33 21 6.5 21ZM11 17H2V3H11V17Z" fill="#FEFFFF"/>
                  </svg>
                  <span>{{ visibleNumber[1] }}</span>
                </div>

                <div class="compare-dealer-all-offer">
                  <a [href]="'/dealer/' + offers[1].dealer.code | addUrlPrefix">
                    <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.1489 0.189729L19.8085 8.04195C19.869 8.10103 19.9172 8.17209 19.9501 8.25083C19.983 8.32957 20 8.41434 20 8.5C20 8.58566 19.983 8.67043 19.9501 8.74917C19.9172 8.82791 19.869 8.89897 19.8085 8.95805L12.1489 16.8103C12.0304 16.9318 11.8697 17 11.7021 17C11.5345 17 11.3738 16.9318 11.2553 16.8103C11.1368 16.6888 11.0702 16.524 11.0702 16.3522C11.0702 16.1804 11.1368 16.0157 11.2553 15.8942L17.8191 9.15435L0.638298 9.15435C0.46901 9.15435 0.306657 9.08541 0.186953 8.9627C0.0672486 8.83998 -3.63961e-07 8.67354 -3.71547e-07 8.5C-3.79133e-07 8.32646 0.0672486 8.16002 0.186953 8.0373C0.306657 7.91459 0.46901 7.84565 0.638298 7.84565L17.8191 7.84565L11.2553 1.10582C11.1368 0.98434 11.0702 0.819576 11.0702 0.647775C11.0702 0.475974 11.1368 0.311211 11.2553 0.189729C11.3738 0.0682477 11.5345 -5.00804e-07 11.7021 -5.11516e-07C11.8697 -5.22228e-07 12.0304 0.0682477 12.1489 0.189729Z" fill="#FEFFFF"/>
                    </svg>
                    <span>ZOBACZ WSZYSTKIE OFERTY TEGO&nbsp;DEALERA</span>
                  </a>
                </div>
              </div>
              <div class="compare-mobile-dealer-all-offer">
                <a [href]="'/dealer/' + offers[0].dealer.code | addUrlPrefix">
                  <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.1489 0.189729L19.8085 8.04195C19.869 8.10103 19.9172 8.17209 19.9501 8.25083C19.983 8.32957 20 8.41434 20 8.5C20 8.58566 19.983 8.67043 19.9501 8.74917C19.9172 8.82791 19.869 8.89897 19.8085 8.95805L12.1489 16.8103C12.0304 16.9318 11.8697 17 11.7021 17C11.5345 17 11.3738 16.9318 11.2553 16.8103C11.1368 16.6888 11.0702 16.524 11.0702 16.3522C11.0702 16.1804 11.1368 16.0157 11.2553 15.8942L17.8191 9.15435L0.638298 9.15435C0.46901 9.15435 0.306657 9.08541 0.186953 8.9627C0.0672486 8.83998 -3.63961e-07 8.67354 -3.71547e-07 8.5C-3.79133e-07 8.32646 0.0672486 8.16002 0.186953 8.0373C0.306657 7.91459 0.46901 7.84565 0.638298 7.84565L17.8191 7.84565L11.2553 1.10582C11.1368 0.98434 11.0702 0.819576 11.0702 0.647775C11.0702 0.475974 11.1368 0.311211 11.2553 0.189729C11.3738 0.0682477 11.5345 -5.00804e-07 11.7021 -5.11516e-07C11.8697 -5.22228e-07 12.0304 0.0682477 12.1489 0.189729Z" fill="#FEFFFF"/>
                  </svg>
                  <span>ZOBACZ WSZYSTKIE OFERTY TEGO&nbsp;DEALERA</span>
                </a>
              </div>
              <div class="compare-mobile-dealer-all-offer">
                <a [href]="'/dealer/' + offers[1].dealer.code | addUrlPrefix">
                  <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.1489 0.189729L19.8085 8.04195C19.869 8.10103 19.9172 8.17209 19.9501 8.25083C19.983 8.32957 20 8.41434 20 8.5C20 8.58566 19.983 8.67043 19.9501 8.74917C19.9172 8.82791 19.869 8.89897 19.8085 8.95805L12.1489 16.8103C12.0304 16.9318 11.8697 17 11.7021 17C11.5345 17 11.3738 16.9318 11.2553 16.8103C11.1368 16.6888 11.0702 16.524 11.0702 16.3522C11.0702 16.1804 11.1368 16.0157 11.2553 15.8942L17.8191 9.15435L0.638298 9.15435C0.46901 9.15435 0.306657 9.08541 0.186953 8.9627C0.0672486 8.83998 -3.63961e-07 8.67354 -3.71547e-07 8.5C-3.79133e-07 8.32646 0.0672486 8.16002 0.186953 8.0373C0.306657 7.91459 0.46901 7.84565 0.638298 7.84565L17.8191 7.84565L11.2553 1.10582C11.1368 0.98434 11.0702 0.819576 11.0702 0.647775C11.0702 0.475974 11.1368 0.311211 11.2553 0.189729C11.3738 0.0682477 11.5345 -5.00804e-07 11.7021 -5.11516e-07C11.8697 -5.22228e-07 12.0304 0.0682477 12.1489 0.189729Z" fill="#FEFFFF"/>
                  </svg>
                  <span>ZOBACZ WSZYSTKIE OFERTY TEGO&nbsp;DEALERA</span>
                </a>
              </div>
            </div>
          </ng-container>

        </div>
      </div>
    </div>
  </div>

  <div class="section--banner-bottom mt-0">
    <img src="assets/img/brands/{{brandSlug}}/offer-banner-bottom.jpg" class="d-none d-lg-inline" />
    <img src="assets/img/brands/{{brandSlug}}/offer-banner-bottom-mobile.jpg" class="d-inline d-lg-none" />
    <div class="banner-bottom-headline">
      <span>wybierzemy dla ciebie najlepsze oferty</span><br />
      <button class="btn banner-btn" (click)="newsletterService.clickShowNL()"> SPRAWDŹ
        <svg fill="none" height="17" viewBox="0 0 20 17" width="20" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.1489 0.189729L19.8085 8.04195C19.869 8.10103 19.9172 8.17209 19.9501 8.25083C19.983 8.32957 20 8.41434 20 8.5C20 8.58566 19.983 8.67043 19.9501 8.74917C19.9172 8.82791 19.869 8.89897 19.8085 8.95805L12.1489 16.8103C12.0304 16.9318 11.8697 17 11.7021 17C11.5345 17 11.3738 16.9318 11.2553 16.8103C11.1368 16.6888 11.0702 16.524 11.0702 16.3522C11.0702 16.1804 11.1368 16.0157 11.2553 15.8942L17.8191 9.15435L0.638298 9.15435C0.46901 9.15435 0.306657 9.08541 0.186953 8.9627C0.0672486 8.83998 -3.63961e-07 8.67354 -3.71547e-07 8.5C-3.79133e-07 8.32646 0.0672486 8.16002 0.186953 8.0373C0.306657 7.91459 0.46901 7.84565 0.638298 7.84565L17.8191 7.84565L11.2553 1.10582C11.1368 0.98434 11.0702 0.819576 11.0702 0.647775C11.0702 0.475974 11.1368 0.311211 11.2553 0.189729C11.3738 0.0682477 11.5345 -5.00804e-07 11.7021 -5.11516e-07C11.8697 -5.22228e-07 12.0304 0.0682477 12.1489 0.189729Z" fill="#FEFFFF"></path>
        </svg>
      </button>
    </div>
  </div>

  <loader [visible]="loading"></loader>
</section>
