import { Pipe, PipeTransform } from '@angular/core';
import {environment} from '../../environments/environment';
import {BrandService} from '../services/brand.service';

@Pipe({
  name: 'defaultImage'
})
export class DefaultImagePipe implements PipeTransform {

  public static getDefaultImage(offerId: string): string {
    return environment.CDNBaseURL + '/uploads/' + BrandService.getCurrentBrand().code + '/' + offerId + '/default.jpg';
  }

  transform(value: any, args?: any): any {
    if (value === null) {
      return DefaultImagePipe.getDefaultImage(args.toString());
    } else {
      return value;
    }
  }

}
