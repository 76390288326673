import {Injectable} from '@angular/core';
import {StockApiService} from './stock-api.service';
import {BasicOffer} from '../models/offer/basic-offer';
import {FullOffer} from '../models/offer/full-offer';

@Injectable({
  providedIn: 'root'
})
export class LabelProviderService {

  modelDiscount = null;

  constructor(
    private stockApiService: StockApiService,
  ) {
  }

  public init() {
    this.stockApiService.getModelDiscount().then(value => {
      this.modelDiscount = value;
      console.log(value);
    });
  }

  public fillOfferLabelsByArray(offers: BasicOffer[]): BasicOffer[] {
    const newOffersArray = [];

    for (const offer of offers) {
      newOffersArray.push(this.fillOfferLabels(offer));
    }

    return newOffersArray;
  }

  public fillOfferLabels(offer: BasicOffer|FullOffer): BasicOffer|FullOffer {
    let model = null;

    if (typeof offer.model === 'string') {
      model = offer.model;
    } else {
      model = offer.model.group.name;
    }

    if (this.hasLowestPrice(model, offer.price.final.netto) && offer.labels.indexOf('Najniższa cena') === -1) {
      offer.labels.push('Najniższa cena');
    }

    if (this.hasMaxDiscount(model, offer.price.discount.percent) && offer.labels.indexOf('Największy rabat') === -1) {
      offer.labels.push('Największy rabat');
    }

    return offer;
  }

  private hasMaxDiscount(model, discount) {
    for (const item of this.modelDiscount.max_discount) {
      if (item.model === model && discount >= item.max_discount && discount > 0) {
        return true;
      }
    }

    return false;
  }

  private hasLowestPrice(model, price) {
    for (const item of this.modelDiscount.lowest_price) {
      if (item.model === model && price <= item.lowest_price) {
        return true;
      }
    }

    return false;
  }
}
