import { Routes } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ObservedOffersComponent } from './subpages/observed-offers/observed-offers.component';
import { DealerListComponent } from './subpages/dealer-list/dealer-list.component';
import { ComparedOffersComponent } from './subpages/compared-offers/compared-offers.component';
var ɵ0 = { routeType: 'error' };
var routes = [
    {
        path: '',
        pathMatch: 'full',
        loadChildren: './modules/list/list.module#ListModule',
    },
    {
        path: 'szukaj',
        pathMatch: 'full',
        loadChildren: './modules/list/list.module#ListModule',
    },
    {
        path: 'dealerzy',
        pathMatch: 'full',
        component: DealerListComponent,
    },
    {
        path: 'obserwowane-ogloszenia',
        pathMatch: 'full',
        component: ObservedOffersComponent,
    },
    {
        path: 'porownaj-oferty',
        pathMatch: 'full',
        component: ComparedOffersComponent,
    },
    {
        path: 'dealer',
        pathMatch: 'prefix',
        loadChildren: './modules/list/list.module#ListModule',
    },
    {
        path: 'dealer-site/:code/obserwowane-ogloszenia',
        pathMatch: 'full',
        component: ObservedOffersComponent,
    },
    {
        path: 'dealer-site/:code/oferta',
        pathMatch: 'prefix',
        loadChildren: './modules/offer/offer.module#OfferModule',
    },
    {
        path: 'dealer-site',
        pathMatch: 'prefix',
        loadChildren: './modules/list/list.module#ListModule',
    },
    {
        path: 'oferta',
        pathMatch: 'prefix',
        loadChildren: './modules/offer/offer.module#OfferModule',
    },
    { path: '**', component: PageNotFoundComponent, data: ɵ0 }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0 };
