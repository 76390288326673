/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loader.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./loader.component";
var styles_LoaderComponent = [i0.styles];
var RenderType_LoaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoaderComponent, data: { "animation": [{ type: 7, name: "animation", definitions: [{ type: 0, name: "visible", styles: { type: 6, styles: { opacity: 0.75, display: "block" }, offset: null }, options: undefined }, { type: 0, name: "invisible", styles: { type: 6, styles: { opacity: 0, display: "none" }, offset: null }, options: undefined }, { type: 1, expr: "visible => invisible", animation: { type: 4, styles: null, timings: 300 }, options: null }, { type: 1, expr: "invisible => visible", animation: { type: 4, styles: null, timings: 50 }, options: null }], options: {} }] } });
export { RenderType_LoaderComponent as RenderType_LoaderComponent };
export function View_LoaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "loading"]], [[24, "@animation", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "row align-items-center justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "lds-ellipsis"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.visible ? "visible" : "invisible"); _ck(_v, 0, 0, currVal_0); }); }
export function View_LoaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "loader", [], null, null, null, View_LoaderComponent_0, RenderType_LoaderComponent)), i1.ɵdid(1, 114688, null, 0, i2.LoaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoaderComponentNgFactory = i1.ɵccf("loader", i2.LoaderComponent, View_LoaderComponent_Host_0, { visible: "visible" }, {}, []);
export { LoaderComponentNgFactory as LoaderComponentNgFactory };
