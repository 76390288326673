/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../tools/offer-url.pipe";
import * as i3 from "../../tools/format-money.pipe";
import * as i4 from "../../tools/url-prefix.pipe";
import * as i5 from "./compare-modal.component";
import * as i6 from "../../services/compared-offer.service";
import * as i7 from "../../services/local-storage.service";
import * as i8 from "../../services/stock-api.service";
import * as i9 from "../../services/labelProvider.service";
var styles_CompareModalComponent = [];
var RenderType_CompareModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CompareModalComponent, data: {} });
export { RenderType_CompareModalComponent as RenderType_CompareModalComponent };
function View_CompareModalComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "modal--offers-compare-info"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, ":svg:svg", [["fill", "none"], ["height", "18"], ["viewBox", "0 0 18 18"], ["width", "18"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, ":svg:path", [["d", "M2 18C1.45 18 0.979 17.8043 0.587 17.413C0.195667 17.021 0 16.55 0 16V2C0 1.45 0.195667 0.979 0.587 0.587C0.979 0.195667 1.45 0 2 0H16C16.55 0 17.021 0.195667 17.413 0.587C17.8043 0.979 18 1.45 18 2V16C18 16.55 17.8043 17.021 17.413 17.413C17.021 17.8043 16.55 18 16 18H2ZM7.6 12.775C7.73333 12.775 7.85833 12.7543 7.975 12.713C8.09167 12.671 8.2 12.6 8.3 12.5L13.975 6.825C14.1583 6.64167 14.25 6.41667 14.25 6.15C14.25 5.88333 14.15 5.65 13.95 5.45C13.7667 5.26667 13.5333 5.175 13.25 5.175C12.9667 5.175 12.7333 5.26667 12.55 5.45L7.6 10.4L5.425 8.225C5.24167 8.04167 5.01667 7.95 4.75 7.95C4.48333 7.95 4.25 8.05 4.05 8.25C3.86667 8.43333 3.775 8.66667 3.775 8.95C3.775 9.23333 3.86667 9.46667 4.05 9.65L6.9 12.5C7 12.6 7.10833 12.671 7.225 12.713C7.34167 12.7543 7.46667 12.775 7.6 12.775Z"], ["fill", "#01BDAE"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Oferta zosta\u0142a dodana do por\u00F3wnywarki."]))], null, null); }
function View_CompareModalComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "modal--offers-compare-info info-warning"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, ":svg:svg", [["fill", "none"], ["height", "24"], ["viewBox", "0 0 24 24"], ["width", "24"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, ":svg:path", [["d", "M5 21C4.45 21 3.979 20.8043 3.587 20.413C3.19567 20.021 3 19.55 3 19V5C3 4.45 3.19567 3.979 3.587 3.587C3.979 3.19567 4.45 3 5 3H19C19.55 3 20.021 3.19567 20.413 3.587C20.8043 3.979 21 4.45 21 5V19C21 19.55 20.8043 20.021 20.413 20.413C20.021 20.8043 19.55 21 19 21H5Z"], ["fill", "#A80C42"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, ":svg:path", [["d", "M15.4238 7.27043C15.597 7.09728 15.8318 7 16.0767 7C16.3216 7 16.5564 7.09728 16.7296 7.27043C16.9027 7.44359 17 7.67844 17 7.92332C17 8.1682 16.9027 8.40305 16.7296 8.5762L13.3045 12L16.729 15.4238C16.9021 15.597 16.9994 15.8318 16.9994 16.0767C16.9994 16.3216 16.9021 16.5564 16.729 16.7296C16.5558 16.9027 16.3209 17 16.0761 17C15.8312 17 15.5963 16.9027 15.4232 16.7296L12 13.3045L8.5762 16.729C8.40305 16.9021 8.1682 16.9994 7.92332 16.9994C7.67844 16.9994 7.44359 16.9021 7.27043 16.729C7.09728 16.5558 7 16.3209 7 16.0761C7 15.8312 7.09728 15.5963 7.27043 15.4232L10.6955 12L7.27105 8.5762C7.09789 8.40305 7.00062 8.1682 7.00062 7.92332C7.00062 7.67844 7.09789 7.44359 7.27105 7.27043C7.4442 7.09728 7.67905 7 7.92393 7C8.16881 7 8.40366 7.09728 8.57682 7.27043L12 10.6955L15.4238 7.27105V7.27043Z"], ["fill", "#F4F4F4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Mo\u017Cesz por\u00F3wna\u0107 tylko dwie oferty."]))], null, null); }
function View_CompareModalComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "compare-model-link"]], [[8, "href", 4], [8, "title", 0]], null, null, null, null)), i0.ɵppd(1, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i0.ɵnov(_v.parent.parent.parent, 0), _v.parent.context.$implicit)); var currVal_1 = i0.ɵinlineInterpolate(4, "", _co.brandName, " ", _v.parent.context.$implicit.model, " ", _v.parent.context.$implicit.version, " ", _v.parent.context.$implicit.engineType, ""); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_CompareModalComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "compare-model-link"]], [[8, "href", 4], [8, "title", 0]], null, null, null, null)), i0.ɵppd(1, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i0.ɵnov(_v.parent.parent.parent, 0), _v.parent.context.$implicit)); var currVal_1 = i0.ɵinlineInterpolate(3, "", _co.brandName, " ", _v.parent.context.$implicit.model, " ", _v.parent.context.$implicit.engineType, ""); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_CompareModalComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "compare-model-box--price"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", " Z\u0141"])), i0.ɵppd(2, 1)], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent.parent, 1), _v.parent.context.$implicit.price.final.brutto)); _ck(_v, 1, 0, currVal_0); }); }
function View_CompareModalComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "compare-model-box--price"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", " z\u0142 netto"])), i0.ɵppd(2, 1)], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent.parent, 1), _v.parent.context.$implicit.price.final.netto)); _ck(_v, 1, 0, currVal_0); }); }
function View_CompareModalComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 18, "div", [["class", "compare-model-box"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CompareModalComponent_5)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CompareModalComponent_6)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [["class", "compare-model-box--thumbnail"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 11, "div", [["class", "compare-model-box--info"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CompareModalComponent_7)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CompareModalComponent_8)), i0.ɵdid(11, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(12, 0, null, null, 1, "div", [["class", "compare-model-box--name"]], null, null, null, null, null)), (_l()(), i0.ɵted(13, null, ["", ""])), (_l()(), i0.ɵeld(14, 0, null, null, 1, "div", [["class", "compare-model-box--version"]], null, null, null, null, null)), (_l()(), i0.ɵted(15, null, ["", " - ", ""])), (_l()(), i0.ɵeld(16, 0, null, null, 2, "div", [["class", "compare-model--remove"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.comparedOfferService.removeOffer(_v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(17, 0, null, null, 1, ":svg:svg", [["fill", "none"], ["height", "13"], ["viewBox", "0 0 13 13"], ["width", "13"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 0, ":svg:path", [["d", "M11.5 11.5L1.5 1.5M11.5 1.5L1.5 11.5"], ["stroke", "#858585"], ["stroke-linecap", "round"], ["stroke-width", "2"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.version !== null); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit.version === null); _ck(_v, 4, 0, currVal_1); var currVal_3 = (_co.brandSlug === "fiat"); _ck(_v, 9, 0, currVal_3); var currVal_4 = (_co.brandSlug === "fiat-professional"); _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = (_v.context.$implicit.image + "?fm=webp&w=263&h=143&fit=crop&bg=white"); _ck(_v, 6, 0, currVal_2); var currVal_5 = _v.context.$implicit.model; _ck(_v, 13, 0, currVal_5); var currVal_6 = _v.context.$implicit.version; var currVal_7 = _v.context.$implicit.engineType; _ck(_v, 15, 0, currVal_6, currVal_7); }); }
function View_CompareModalComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "a", [["class", "btn-compare"], ["type", "button"]], [[8, "href", 4]], null, null, null, null)), i0.ɵppd(1, 1), (_l()(), i0.ɵted(-1, null, [" Por\u00F3wnaj oferty "])), (_l()(), i0.ɵeld(3, 0, null, null, 1, ":svg:svg", [["fill", "none"], ["height", "17"], ["viewBox", "0 0 20 17"], ["width", "20"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, ":svg:path", [["d", "M12.1489 0.189729L19.8085 8.04195C19.869 8.10103 19.9172 8.17209 19.9501 8.25083C19.983 8.32957 20 8.41434 20 8.5C20 8.58566 19.983 8.67043 19.9501 8.74917C19.9172 8.82791 19.869 8.89897 19.8085 8.95805L12.1489 16.8103C12.0304 16.9318 11.8697 17 11.7021 17C11.5345 17 11.3738 16.9318 11.2553 16.8103C11.1368 16.6888 11.0702 16.524 11.0702 16.3522C11.0702 16.1804 11.1368 16.0157 11.2553 15.8942L17.8191 9.15435L0.638298 9.15435C0.46901 9.15435 0.306657 9.08541 0.186953 8.9627C0.0672486 8.83998 -3.63961e-07 8.67354 -3.71547e-07 8.5C-3.79133e-07 8.32646 0.0672486 8.16002 0.186953 8.0373C0.306657 7.91459 0.46901 7.84565 0.638298 7.84565L17.8191 7.84565L11.2553 1.10582C11.1368 0.98434 11.0702 0.819576 11.0702 0.647775C11.0702 0.475975 11.1368 0.31121 11.2553 0.189729C11.3738 0.0682483 11.5345 -5.04191e-07 11.7021 -5.11516e-07C11.8697 -5.18842e-07 12.0304 0.0682482 12.1489 0.189729Z"], ["fill", "#1B1C1B"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i0.ɵnov(_v.parent.parent, 2), "/porownaj-oferty")); _ck(_v, 0, 0, currVal_0); }); }
function View_CompareModalComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [["class", "modal--offers-compare"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CompareModalComponent_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CompareModalComponent_3)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 7, "div", [["class", "container d-none d-md-block"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 5, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 4, "div", [["class", "compare-content"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CompareModalComponent_4)), i0.ɵdid(10, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CompareModalComponent_9)), i0.ɵdid(12, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.addNewOffer; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.compareOnlyTwoOffers; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.offers; _ck(_v, 10, 0, currVal_2); var currVal_3 = (_co.offers.length === 2); _ck(_v, 12, 0, currVal_3); }, null); }
export function View_CompareModalComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i2.OfferUrlPipe, []), i0.ɵpid(0, i3.FormatMoneyPipe, []), i0.ɵpid(0, i4.UrlPrefixPipe, []), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CompareModalComponent_1)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.brandSlug === "fiat") || (_co.brandSlug === "fiat-professional")) && (_co.offers.length > 0)); _ck(_v, 4, 0, currVal_0); }, null); }
export function View_CompareModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-compare-modal", [], null, null, null, View_CompareModalComponent_0, RenderType_CompareModalComponent)), i0.ɵdid(1, 114688, null, 0, i5.CompareModalComponent, [i6.ComparedOfferService, i7.LocalStorageService, i8.StockApiService, i9.LabelProviderService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CompareModalComponentNgFactory = i0.ɵccf("app-compare-modal", i5.CompareModalComponent, View_CompareModalComponent_Host_0, {}, {}, []);
export { CompareModalComponentNgFactory as CompareModalComponentNgFactory };
