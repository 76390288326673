/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "ng-lazyload-image";
import * as i2 from "@angular/common";
import * as i3 from "../../tools/offer-word.pipe";
import * as i4 from "./brand-select.component";
import * as i5 from "../../services/local-storage.service";
var styles_BrandSelectComponent = [];
var RenderType_BrandSelectComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BrandSelectComponent, data: {} });
export { RenderType_BrandSelectComponent as RenderType_BrandSelectComponent };
function View_BrandSelectComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "img", [], [[8, "alt", 0]], null, null, null, null)), i0.ɵdid(1, 1720320, null, 0, i1.LazyLoadImageDirective, [i0.ElementRef, i0.NgZone, i0.PLATFORM_ID, [2, "options"]], { lazyImage: [0, "lazyImage"], customObservable: [1, "customObservable"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (("assets/img/brands/" + _v.parent.context.$implicit.slug) + "/logo.png"); var currVal_2 = _co.loading$; _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.name; _ck(_v, 0, 0, currVal_0); }); }
function View_BrandSelectComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "img", [], [[8, "alt", 0]], null, null, null, null)), i0.ɵdid(1, 1720320, null, 0, i1.LazyLoadImageDirective, [i0.ElementRef, i0.NgZone, i0.PLATFORM_ID, [2, "options"]], { lazyImage: [0, "lazyImage"], customObservable: [1, "customObservable"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (("assets/img/brands/" + _v.parent.context.$implicit.slug) + "/logo_white.png"); var currVal_2 = _co.loading$; _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.name; _ck(_v, 0, 0, currVal_0); }); }
function View_BrandSelectComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 9, "li", [["class", "nav-brands__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 8, "a", [["class", "nav-brands__link"]], [[8, "href", 4], [1, "data-adobe", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeBrand(_v.context.$implicit.slug, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 4, "span", [["class", "nav-brands__logo"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BrandSelectComponent_2)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BrandSelectComponent_3)), i0.ɵdid(7, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 2, "span", [["class", "nav-brands__text"]], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, [" ", " ", " "])), i0.ɵppd(10, 1)], function (_ck, _v) { var currVal_2 = (((_v.context.$implicit.slug !== "jeep") && (_v.context.$implicit.slug !== "fiat-professional")) && (_v.context.$implicit.slug !== "alfa-romeo")); _ck(_v, 5, 0, currVal_2); var currVal_3 = (((_v.context.$implicit.slug === "jeep") || (_v.context.$implicit.slug === "fiat-professional")) || (_v.context.$implicit.slug === "alfa-romeo")); _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.stockUrl; var currVal_1 = ("top-nav:used:" + _v.context.$implicit.slug); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_4 = _v.context.$implicit.offerCount; var currVal_5 = i0.ɵunv(_v, 9, 1, _ck(_v, 10, 0, i0.ɵnov(_v.parent, 0), _v.context.$implicit.offerCount)); _ck(_v, 9, 0, currVal_4, currVal_5); }); }
export function View_BrandSelectComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i3.OfferWordPipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 11, "div", [["aria-hidden", "true"], ["aria-labelledby", "selectBrandLabel"], ["class", "modal modal--select-brand right"], ["id", "selectBrand"], ["role", "dialog"], ["tabindex", "-1"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 10, "div", [["class", "modal-dialog ie-modal-dialog"], ["role", "document"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 9, "div", [["class", "modal-content ie-modal-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 4, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["aria-label", "Zamknij"], ["class", "close"], ["data-dismiss", "modal"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "span", [["class", "icon-x"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "h5", [["class", "modal-title"], ["id", "selectBrandLabel"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["SPRAWD\u0179 OFERTY INNYCH MAREK"])), (_l()(), i0.ɵeld(9, 0, null, null, 3, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 2, "ul", [["class", "nav-brands"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BrandSelectComponent_1)), i0.ɵdid(12, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.brandList; _ck(_v, 12, 0, currVal_0); }, null); }
export function View_BrandSelectComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-brand-select", [], null, null, null, View_BrandSelectComponent_0, RenderType_BrandSelectComponent)), i0.ɵdid(1, 114688, null, 0, i4.BrandSelectComponent, [i5.LocalStorageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BrandSelectComponentNgFactory = i0.ɵccf("app-brand-select", i4.BrandSelectComponent, View_BrandSelectComponent_Host_0, {}, {}, []);
export { BrandSelectComponentNgFactory as BrandSelectComponentNgFactory };
