import { EventEmitter } from '@angular/core';
import { Brand } from '../models/Brands';
import { Config } from '../config/config';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
var BrandService = /** @class */ (function () {
    function BrandService() {
    }
    BrandService.getCurrentBrand = function () {
        return BrandService.brand;
    };
    BrandService.getBrandList = function () {
        return BrandService.brandList;
    };
    BrandService.setData = function (data) {
        BrandService.brand = data.currentBrand;
        BrandService.brandList = data.brands;
        this.changeBrand.emit(data.currentBrand);
    };
    BrandService.setBrand = function (brand) {
        if (!environment.production) {
            this.brand = brand;
            this.changeBrand.emit(brand);
        }
    };
    BrandService.getConfig = function () {
        return Config[BrandService.getCurrentBrand().slug];
    };
    BrandService.prototype.setNewDataWithoutBrandChanging = function (data) {
        BrandService.brand = data.currentBrand;
        BrandService.brandList = data.brands;
    };
    BrandService.brand = new Brand();
    BrandService.brandList = null;
    BrandService.changeBrand = new EventEmitter();
    BrandService.ngInjectableDef = i0.defineInjectable({ factory: function BrandService_Factory() { return new BrandService(); }, token: BrandService, providedIn: "root" });
    return BrandService;
}());
export { BrandService };
