var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HttpClient } from '@angular/common/http';
import { BrandService } from './brand.service';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var StockApiService = /** @class */ (function () {
    function StockApiService(http) {
        this.http = http;
    }
    StockApiService.prototype.getBrandSlug = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                if (BrandService.getCurrentBrand() !== null) {
                    return [2 /*return*/, BrandService.getCurrentBrand().slug];
                }
                else {
                    return [2 /*return*/, new Promise(function (resolve) { return setTimeout(_this.getBrandSlug, 100); })];
                }
                return [2 /*return*/];
            });
        });
    };
    StockApiService.prototype.getNewCarsUrl = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                if (BrandService.getCurrentBrand() !== null) {
                    return [2 /*return*/, BrandService.getCurrentBrand().newCarsUrl];
                }
                else {
                    return [2 /*return*/, new Promise(function (resolve) { return setTimeout(_this.getBrandSlug, 100); })];
                }
                return [2 /*return*/];
            });
        });
    };
    StockApiService.prototype.getSearchData = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var brandSlug;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getBrandSlug()];
                    case 1:
                        brandSlug = _a.sent();
                        return [2 /*return*/, this.http.post(environment.APIBaseURL + 'offers/list-' + brandSlug + '.json', data).toPromise()];
                }
            });
        });
    };
    StockApiService.prototype.sendSearchStats = function (model, version) {
        return __awaiter(this, void 0, void 0, function () {
            var brandSlug;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getBrandSlug()];
                    case 1:
                        brandSlug = _a.sent();
                        // send stats only on production
                        if (environment.production) {
                            return [2 /*return*/, this.http.put(environment.APIBaseURL + 'search-stats', { brand: brandSlug, model: model, version: version }).toPromise()];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    StockApiService.prototype.getBasicSearchData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var brandSlug;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getBrandSlug()];
                    case 1:
                        brandSlug = _a.sent();
                        return [2 /*return*/, this.http.get(environment.APIBaseURL + 'offers/basic-search-data-' + brandSlug + '.json').toPromise()];
                }
            });
        });
    };
    StockApiService.prototype.getOfferData = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var brandSlug;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getBrandSlug()];
                    case 1:
                        brandSlug = _a.sent();
                        return [2 /*return*/, this.http.get(environment.APIBaseURL + 'offers/offer-' + brandSlug + '.json?id=' + id).toPromise()];
                }
            });
        });
    };
    StockApiService.prototype.getSimilarOffers = function (id, dealer) {
        if (dealer === void 0) { dealer = null; }
        return __awaiter(this, void 0, void 0, function () {
            var brandSlug;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getBrandSlug()];
                    case 1:
                        brandSlug = _a.sent();
                        if (dealer !== null) {
                            return [2 /*return*/, this.http.get(environment.APIBaseURL + 'offers/similar-offers-' + brandSlug + '.json?id=' + id + '&dealer_code=' + dealer).toPromise()];
                        }
                        else {
                            return [2 /*return*/, this.http.get(environment.APIBaseURL + 'offers/similar-offers-' + brandSlug + '.json?id=' + id).toPromise()];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    StockApiService.prototype.getDealerList = function () {
        return __awaiter(this, void 0, void 0, function () {
            var brandSlug;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getBrandSlug()];
                    case 1:
                        brandSlug = _a.sent();
                        return [2 /*return*/, this.http.get(environment.APIBaseURL + 'offers/dealer-list-' + brandSlug + '.json').toPromise()];
                }
            });
        });
    };
    StockApiService.prototype.getSimpleOffersData = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (id instanceof Array) {
                    id = id.join(':;');
                }
                return [2 /*return*/, this.http.get(environment.APIBaseURL + 'offers/simple.json?brand=' + BrandService.getCurrentBrand().code + '&id=' + id).toPromise()];
            });
        });
    };
    StockApiService.prototype.getBrands = function (dealerCode) {
        if (dealerCode === void 0) { dealerCode = null; }
        if (dealerCode !== null) {
            return this.http.get(environment.APIBaseURL + 'brands.json?dealer=' + dealerCode + '&host=' + document.location.host).toPromise();
        }
        else {
            return this.http.get(environment.APIBaseURL + 'brands.json?host=' + document.location.host).toPromise();
        }
    };
    StockApiService.prototype.sendMasterkeyData = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var brandSlug;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getBrandSlug()];
                    case 1:
                        brandSlug = _a.sent();
                        this.http.post(environment.APIBaseURL + 'leads/save-client-params/' + brandSlug, data).toPromise();
                        return [2 /*return*/];
                }
            });
        });
    };
    StockApiService.prototype.getLeadForm = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var brandSlug;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getBrandSlug()];
                    case 1:
                        brandSlug = _a.sent();
                        return [2 /*return*/, this.http.get(environment.APIBaseURL + 'leads/get-form-data/' + brandSlug + '/' + id + '.json').toPromise()];
                }
            });
        });
    };
    StockApiService.prototype.sendLead = function (id, data) {
        return __awaiter(this, void 0, void 0, function () {
            var brandSlug;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getBrandSlug()];
                    case 1:
                        brandSlug = _a.sent();
                        return [2 /*return*/, this.http.post(environment.APIBaseURL + 'leads/submit-form-data/' + brandSlug + '/' + id, data).toPromise()];
                }
            });
        });
    };
    StockApiService.prototype.getPrivacyNotes = function () {
        return this.http.get('https://api.fcapoland.pl/shared-content/disclaimer/1596/information', { responseType: 'text' }).toPromise();
    };
    StockApiService.prototype.getModelDiscount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var brandSlug;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getBrandSlug()];
                    case 1:
                        brandSlug = _a.sent();
                        return [2 /*return*/, this.http.get(environment.APIBaseURL + 'offers/model-discount-' + brandSlug + '.json').toPromise()];
                }
            });
        });
    };
    StockApiService.prototype.getNewCarsInfo = function () {
        return __awaiter(this, void 0, void 0, function () {
            var newCarsUrl;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getNewCarsUrl()];
                    case 1:
                        newCarsUrl = _a.sent();
                        return [2 /*return*/, this.http.get(newCarsUrl + environment.APIBaseURL + 'brands.json').toPromise()];
                }
            });
        });
    };
    StockApiService.ngInjectableDef = i0.defineInjectable({ factory: function StockApiService_Factory() { return new StockApiService(i0.inject(i1.HttpClient)); }, token: StockApiService, providedIn: "root" });
    return StockApiService;
}());
export { StockApiService };
