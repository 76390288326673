<div class="offer-box align-items-end d-flex">
  <div class="offer-box__photo">
    <div class="label-wrap" [ngClass]="{'fiat-label-five-year-warranty': brandSlug === 'fiat'}">
      <app-offer-labels [offer]="item"></app-offer-labels>
    </div>
    <a [routerLink]="item | offerUrl" class="offer-box__link" *ngIf="item.version !== null" title="{{ brandName }} {{ item.model }} {{ item.version }} {{ item.engineType }}"></a>
    <a [routerLink]="item | offerUrl" class="offer-box__link" *ngIf="item.version === null" title="{{ brandName }} {{ item.model }} {{ item.engineType }}"></a>

    <app-picture [offer]="item" [brandSlug]="brandSlug" *ngIf="item.version !== null" [toAlt]="brandName + ' ' + item.model + ' ' + item.version + ' ' + item.engineType"></app-picture>
    <app-picture [offer]="item" [brandSlug]="brandSlug" *ngIf="item.version === null" [toAlt]="brandName + ' ' + item.model + ' ' + item.engineType"></app-picture>
    <div class="offer-more-details">
      <div class="offer-box__compare" (click)="comparedOffers.changeComparedStatus(item.id)">
        <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.5189 8.91072L1.08583 5.21555C1.05871 5.18775 1.03711 5.15431 1.02236 5.11726C1.00761 5.0802 1 5.04031 1 5C1 4.95969 1.00761 4.9198 1.02236 4.88274C1.03711 4.84569 1.05871 4.81225 1.08583 4.78445L4.5189 1.08928C4.57201 1.03212 4.64405 1 4.71916 1C4.79427 1 4.86631 1.03212 4.91942 1.08928C4.97254 1.14645 5.00237 1.22399 5.00237 1.30484C5.00237 1.38568 4.97254 1.46322 4.91942 1.52039L1.97747 4.69207L16.7139 4.69207C16.7898 4.69207 16.8626 4.72451 16.9162 4.78226C16.9699 4.84001 17 4.91833 17 5C17 5.08167 16.9699 5.15999 16.9162 5.21774C16.8626 5.27549 16.7898 5.30793 16.7139 5.30793L1.97747 5.30793L4.91942 8.47961C4.97254 8.53678 5.00237 8.61432 5.00237 8.69516C5.00237 8.77601 4.97254 8.85355 4.91942 8.91072C4.86631 8.96788 4.79427 9 4.71916 9C4.64405 9 4.57201 8.96788 4.5189 8.91072Z" fill="#FEFFFF" stroke="#FEFFFF" stroke-width="0.7"/>
          <path d="M13.4811 1.08929L16.9142 4.78445C16.9413 4.81225 16.9629 4.84569 16.9776 4.88274C16.9924 4.9198 17 4.95969 17 5C17 5.04031 16.9924 5.0802 16.9776 5.11726C16.9629 5.15431 16.9413 5.18775 16.9142 5.21555L13.4811 8.91072C13.428 8.96788 13.356 9 13.2808 9C13.2057 9 13.1337 8.96788 13.0806 8.91072C13.0275 8.85355 12.9976 8.77601 12.9976 8.69516C12.9976 8.61432 13.0275 8.53678 13.0806 8.47961L16.0225 5.30793L1.28609 5.30793C1.21021 5.30793 1.13745 5.27549 1.08379 5.21774C1.03014 5.15999 1 5.08167 1 5C1 4.91833 1.03014 4.84001 1.08379 4.78226C1.13745 4.72451 1.21021 4.69207 1.28609 4.69207L16.0225 4.69207L13.0806 1.52039C13.0275 1.46322 12.9976 1.38568 12.9976 1.30484C12.9976 1.22399 13.0275 1.14645 13.0806 1.08929C13.1337 1.03212 13.2057 1 13.2808 1C13.356 1 13.428 1.03212 13.4811 1.08929Z" fill="#FEFFFF" stroke="#FEFFFF" stroke-width="0.7"/>
        </svg>
        PORÓWNAJ
      </div>

      <span class="offer-box__local">
        <span class="offer-box__local-item text-uppercase aling-item-center">
          <span class="icon-marker-local">
            <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.49992 7.50008C6.8895 7.50008 7.22313 7.36125 7.50079 7.08358C7.77799 6.80639 7.91658 6.473 7.91658 6.08342C7.91658 5.69383 7.77799 5.36021 7.50079 5.08254C7.22313 4.80535 6.8895 4.66675 6.49992 4.66675C6.11034 4.66675 5.77695 4.80535 5.49975 5.08254C5.22209 5.36021 5.08325 5.69383 5.08325 6.08342C5.08325 6.473 5.22209 6.80639 5.49975 7.08358C5.77695 7.36125 6.11034 7.50008 6.49992 7.50008ZM6.49992 14.5834C4.59922 12.9661 3.17972 11.4637 2.24142 10.0763C1.30264 8.68937 0.833252 7.40564 0.833252 6.22508C0.833252 4.45425 1.40299 3.04348 2.54246 1.99279C3.68146 0.942095 5.00061 0.416748 6.49992 0.416748C7.99922 0.416748 9.31838 0.942095 10.4574 1.99279C11.5968 3.04348 12.1666 4.45425 12.1666 6.22508C12.1666 7.40564 11.6974 8.68937 10.7591 10.0763C9.82035 11.4637 8.40061 12.9661 6.49992 14.5834Z" fill="#FEFFFF"/>
            </svg>
          </span>
          <span class="offer-box__local-text ml-1"> {{item.localization}}</span>
        </span>
      </span>

      <ul class="offer-box__nav d-flex">
        <li class="offer-box__nav-item my-1">
          <a [routerLink]="item | offerUrl" class="offer-box__nav-icon">
            <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.99992 9.66667C7.83325 9.66667 8.5417 9.37511 9.12525 8.792C9.70836 8.20844 9.99992 7.5 9.99992 6.66667C9.99992 5.83333 9.70836 5.12489 9.12525 4.54133C8.5417 3.95822 7.83325 3.66667 6.99992 3.66667C6.16658 3.66667 5.45814 3.95822 4.87459 4.54133C4.29147 5.12489 3.99992 5.83333 3.99992 6.66667C3.99992 7.5 4.29147 8.20844 4.87459 8.792C5.45814 9.37511 6.16658 9.66667 6.99992 9.66667ZM1.66659 12C1.29992 12 0.986141 11.8696 0.725252 11.6087C0.463919 11.3473 0.333252 11.0333 0.333252 10.6667V2.66667C0.333252 2.3 0.463919 1.98622 0.725252 1.72533C0.986141 1.464 1.29992 1.33333 1.66659 1.33333H3.76659L4.99992 0H8.99992L10.2333 1.33333H12.3333C12.6999 1.33333 13.0139 1.464 13.2753 1.72533C13.5361 1.98622 13.6666 2.3 13.6666 2.66667V10.6667C13.6666 11.0333 13.5361 11.3473 13.2753 11.6087C13.0139 11.8696 12.6999 12 12.3333 12H1.66659Z" fill="#FEFFFF"/>
            </svg>
            <span class="label-quantity">{{ item.countOfImage }}</span>
          </a>
        </li>
        <li class="offer-box__nav-item my-1" *ngIf="item.countOfVideos > 0">
          <a [routerLink]="item | offerUrl" class="offer-box__nav-icon">
            <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 5H14M2.44444 5L6.05556 1M6.05556 5L9.66667 1M9.66667 5L13.2778 1M2.44444 13H12.5556C13.3533 13 14 12.403 14 11.6667V2.33333C14 1.59695 13.3533 1 12.5556 1H2.44444C1.6467 1 1 1.59695 1 2.33333V11.6667C1 12.403 1.6467 13 2.44444 13Z" stroke="#FEFFFF"/>
              <path d="M6.05566 10.755V7.24447C6.05575 7.20055 6.06907 7.15745 6.09425 7.1197C6.11942 7.08195 6.15551 7.05093 6.19875 7.0299C6.24198 7.00887 6.29076 6.99859 6.33998 7.00015C6.38919 7.00172 6.43703 7.01505 6.47848 7.03877L9.54034 8.79357C9.57911 8.81572 9.61101 8.84622 9.6331 8.88225C9.6552 8.91828 9.66678 8.95869 9.66678 8.99975C9.66678 9.04082 9.6552 9.08123 9.6331 9.11726C9.61101 9.15329 9.57911 9.18379 9.54034 9.20594L6.47848 10.9612C6.43703 10.9849 6.38919 10.9983 6.33998 10.9998C6.29076 11.0014 6.24198 10.9911 6.19875 10.9701C6.15551 10.9491 6.11942 10.918 6.09425 10.8803C6.06907 10.8425 6.05575 10.799 6.05566 10.755Z" fill="#FEFFFF"/>
            </svg>
            <span class="label-quantity">{{ item.countOfVideos }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div class="offer-box__info">
    <div class="offer-details d-flex d-lg-none" style="margin-top: -30px">
      <div class="col-info">
        <div class="offer-box__compare" (click)="comparedOffers.changeComparedStatus(item.id)">
          <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.5189 8.91072L1.08583 5.21555C1.05871 5.18775 1.03711 5.15431 1.02236 5.11726C1.00761 5.0802 1 5.04031 1 5C1 4.95969 1.00761 4.9198 1.02236 4.88274C1.03711 4.84569 1.05871 4.81225 1.08583 4.78445L4.5189 1.08928C4.57201 1.03212 4.64405 1 4.71916 1C4.79427 1 4.86631 1.03212 4.91942 1.08928C4.97254 1.14645 5.00237 1.22399 5.00237 1.30484C5.00237 1.38568 4.97254 1.46322 4.91942 1.52039L1.97747 4.69207L16.7139 4.69207C16.7898 4.69207 16.8626 4.72451 16.9162 4.78226C16.9699 4.84001 17 4.91833 17 5C17 5.08167 16.9699 5.15999 16.9162 5.21774C16.8626 5.27549 16.7898 5.30793 16.7139 5.30793L1.97747 5.30793L4.91942 8.47961C4.97254 8.53678 5.00237 8.61432 5.00237 8.69516C5.00237 8.77601 4.97254 8.85355 4.91942 8.91072C4.86631 8.96788 4.79427 9 4.71916 9C4.64405 9 4.57201 8.96788 4.5189 8.91072Z" fill="#1B1C1B" stroke="#1B1C1B" stroke-width="0.7"/>
            <path d="M13.4811 1.08929L16.9142 4.78445C16.9413 4.81225 16.9629 4.84569 16.9776 4.88274C16.9924 4.9198 17 4.95969 17 5C17 5.04031 16.9924 5.0802 16.9776 5.11726C16.9629 5.15431 16.9413 5.18775 16.9142 5.21555L13.4811 8.91072C13.428 8.96788 13.356 9 13.2808 9C13.2057 9 13.1337 8.96788 13.0806 8.91072C13.0275 8.85355 12.9976 8.77601 12.9976 8.69516C12.9976 8.61432 13.0275 8.53678 13.0806 8.47961L16.0225 5.30793L1.28609 5.30793C1.21021 5.30793 1.13745 5.27549 1.08379 5.21774C1.03014 5.15999 1 5.08167 1 5C1 4.91833 1.03014 4.84001 1.08379 4.78226C1.13745 4.72451 1.21021 4.69207 1.28609 4.69207L16.0225 4.69207L13.0806 1.52039C13.0275 1.46322 12.9976 1.38568 12.9976 1.30484C12.9976 1.22399 13.0275 1.14645 13.0806 1.08929C13.1337 1.03212 13.2057 1 13.2808 1C13.356 1 13.428 1.03212 13.4811 1.08929Z" fill="#1B1C1B" stroke="#1B1C1B" stroke-width="0.7"/>
          </svg>
        </div>
      </div>

      <div class="col-icon">
        <div class="favorite-offer"
             [ngClass]="{'favorite-offer--active': observedOffers.isObserved(item.uid)}"
             (click)="observedOffers.changeObservedStatus(item.uid)">
          <svg width="16" height="14" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.47852 17.0751L9.47743 17.0742C6.75877 14.6431 4.56312 12.6755 3.03837 10.836C1.52151 9.00596 0.75 7.39658 0.75 5.69482C0.75 2.92032 2.94439 0.75 5.775 0.75C7.37914 0.75 8.92829 1.49024 9.93427 2.64607L10.5 3.29607L11.0657 2.64607C12.0717 1.49024 13.6209 0.75 15.225 0.75C18.0556 0.75 20.25 2.92032 20.25 5.69482C20.25 7.39658 19.4785 9.00596 17.9616 10.836C16.4369 12.6755 14.2412 14.6431 11.5226 17.0742L11.5215 17.0751L10.5 17.9921L9.47852 17.0751Z" stroke="#A80C42" stroke-width="1.5"/>
          </svg>
        </div>
      </div>
    </div>

    <a [routerLink]="item | offerUrl" class="box-model__link" *ngIf="item.version !== null" title="{{ brandName }} {{ item.model }} {{ item.version }} {{ item.engineType }}"></a>
    <a [routerLink]="item | offerUrl" class="box-model__link" *ngIf="item.version === null" title="{{ brandName }} {{ item.model }} {{ item.engineType }}"></a>

    <div class="price">
      <div class="price__tax">
        <span *ngIf="brandSlug === 'fiat'">{{ item.price.final.brutto | formatMoney }} ZŁ</span>
        <span *ngIf="brandSlug === 'fiat-professional'">{{ item.price.final.netto | formatMoney }} zł netto</span>
      </div>
    </div>
    <div class="offer-details">
      <div class="col-info">
        <h3 class="offer-box__name text-uppercase">{{ item.model }}</h3>
        <div class="offer-box__version text-uppercase" *ngIf="item.version !== null">{{ item.version }}<br />{{ item.engineType }}</div>
        <div class="offer-box__version text-uppercase" *ngIf="item.version === null">{{ item.engineType }}</div>
        <div class="offer-box__version">Rok produkcji: {{ item.productionYear }}<br />Przebieg: {{ item.mileage|formatMoney }} <span class="text-lowercase">km</span></div>
      </div>

      <div class="col-icon d-none d-lg-block">
        <div class="favorite-offer"
             [ngClass]="{'favorite-offer--active': observedOffers.isObserved(item.uid)}"
             (click)="observedOffers.changeObservedStatus(item.uid)">
          <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.47852 17.0751L9.47743 17.0742C6.75877 14.6431 4.56312 12.6755 3.03837 10.836C1.52151 9.00596 0.75 7.39658 0.75 5.69482C0.75 2.92032 2.94439 0.75 5.775 0.75C7.37914 0.75 8.92829 1.49024 9.93427 2.64607L10.5 3.29607L11.0657 2.64607C12.0717 1.49024 13.6209 0.75 15.225 0.75C18.0556 0.75 20.25 2.92032 20.25 5.69482C20.25 7.39658 19.4785 9.00596 17.9616 10.836C16.4369 12.6755 14.2412 14.6431 11.5226 17.0742L11.5215 17.0751L10.5 17.9921L9.47852 17.0751Z" stroke="#A80C42" stroke-width="1.5"/>
          </svg>
        </div>
      </div>
    </div>

  </div>
</div>
