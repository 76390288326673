import {Component, Input, OnInit} from '@angular/core';
import {BasicOffer} from '../../models/offer/basic-offer';
import {ObservedOfferService} from '../../services/observed-offer.service';
import {ComparedOfferService} from '../../services/compared-offer.service';
import {BrandService} from '../../services/brand.service';
import {AppComponent} from '../../app.component';

@Component({
  selector: 'app-offer-box',
  templateUrl: './offer-box.component.html',
})
export class OfferBoxComponent implements OnInit {

  @Input() item: BasicOffer;
  brandName: string;
  brandSlug: string;
  subscriptionInstallment = false;
  rentingInstallment = false;

  constructor(
    public observedOffers: ObservedOfferService,
    public comparedOffers: ComparedOfferService,
  ) {
  }

  setDefaultImg(event) {
    if (event.currentTarget && event.currentTarget.parentElement) {
      event.currentTarget.parentElement.innerHTML = '<img src="/assets/img/default_img_290x158.png" class="box-model__photo-img" />';
    }
  }

  ngOnInit() {
    this.brandName = BrandService.getCurrentBrand().name;
    this.brandSlug = BrandService.getCurrentBrand().slug;

    if (this.brandSlug === 'jeep' || this.brandSlug === 'alfa-romeo') {
      if (this.item.financial_products !== null) {
        for (const financial_product of Object.keys(this.item.financial_products)) {
          if (this.item.financial_products[financial_product].name === 'Abonament') {
            this.subscriptionInstallment = true;
          } else if (this.item.financial_products[financial_product].type === 'renting') {
            this.rentingInstallment = true;
          }
        }
      }
    }

    if (this.item !== undefined && this.item.version.toLowerCase() === 'electric vehicle') {
      this.item.version = null;
    }
  }

  getFinancialProductPriceType(parameters): string {
    let priceType;
    for (const val in parameters) {
      if (parameters[val].netto) {
        priceType = 'netto';
      } else {
        priceType = 'brutto';
      }
    }
    return priceType;
  }
}
