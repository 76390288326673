/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./input.component";
var styles_InputComponent = [];
var RenderType_InputComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InputComponent, data: {} });
export { RenderType_InputComponent as RenderType_InputComponent };
export function View_InputComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "input", [["class", "form-control"], ["type", "text"]], [[8, "name", 0], [8, "placeholder", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.name, ""); var currVal_1 = i0.ɵinlineInterpolate(1, "", _co.placeholder, ""); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_InputComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-input", [], null, null, null, View_InputComponent_0, RenderType_InputComponent)), i0.ɵdid(1, 114688, null, 0, i1.InputComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InputComponentNgFactory = i0.ɵccf("app-input", i1.InputComponent, View_InputComponent_Host_0, { name: "name", placeholder: "placeholder", value: "value" }, { update: "update" }, []);
export { InputComponentNgFactory as InputComponentNgFactory };
