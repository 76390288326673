/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./checkbox.component";
var styles_CheckboxComponent = [];
var RenderType_CheckboxComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CheckboxComponent, data: {} });
export { RenderType_CheckboxComponent as RenderType_CheckboxComponent };
function View_CheckboxComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["class", "icon icon-check"]], null, null, null, null, null))], null, null); }
function View_CheckboxComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "stock-checkbox__option"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onUpdate(_v.context.$implicit.key) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(2, { "selected": 0 }), (_l()(), i0.ɵeld(3, 0, null, null, 2, "span", [["class", "stock-checkbox__check"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CheckboxComponent_2)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 3, "span", [["class", "stock-checkbox__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", " "])), (_l()(), i0.ɵeld(8, 0, null, null, 1, "small", [], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, ["(", ")"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "stock-checkbox__option"; var currVal_1 = _ck(_v, 2, 0, _co.isSelected(_v.context.$implicit.key)); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.isSelected(_v.context.$implicit.key); _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var currVal_3 = _v.context.$implicit.value; _ck(_v, 7, 0, currVal_3); var currVal_4 = _v.context.$implicit.count; _ck(_v, 9, 0, currVal_4); }); }
export function View_CheckboxComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "stock-checkbox"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CheckboxComponent_1)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.availableValues; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CheckboxComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-checkbox", [], null, null, null, View_CheckboxComponent_0, RenderType_CheckboxComponent)), i0.ɵdid(1, 114688, null, 0, i2.CheckboxComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CheckboxComponentNgFactory = i0.ɵccf("app-checkbox", i2.CheckboxComponent, View_CheckboxComponent_Host_0, { name: "name", icon: "icon", value: "value", availableValues: "availableValues", nullValue: "nullValue" }, { update: "update" }, []);
export { CheckboxComponentNgFactory as CheckboxComponentNgFactory };
