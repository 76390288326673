import { BrandService } from './brand.service';
import * as i0 from "@angular/core";
var LiveChatService = /** @class */ (function () {
    function LiveChatService() {
        this.livechatLicense = null;
        this.livechatDealers = {};
        // Livechat - disabled 18.10.2021 - FCASTOCK3-524
        // livechatLicense = 7128661;
        //
        // livechatDealers = {
        //   '0075206': {
        //     'fiat': 4,
        //     'abarht': 14,
        //     'alfa-romeo': 16
        //   },
        //   '0720006': {
        //     'jeep': 15
        //   },
        //   '0075296': {
        //     'fiat': 6,
        //     'abarth': 6
        //   },
        //   '0720019': {
        //     'jeep': 12
        //   },
        //   '0712341': {
        //     'jeep': 11
        //   },
        //   '0712335': {
        //     'jeep': 17
        //   }
        // };
        this.tawkToDealers = {
            '0075416': {
                'fiat': '560a790c74736b987d118540',
                'fiat-professional': '561271e80de0759b4ed32979',
            },
            '0720016': {
                'jeep': '560a8f7c9cffede15f1fa90a'
            }
        };
    }
    LiveChatService.prototype.getDealerLiveChatGroup = function (dealerCode) {
        if (this.livechatDealers[dealerCode]) {
            if (this.livechatDealers[dealerCode][BrandService.getCurrentBrand().slug]) {
                return this.livechatDealers[dealerCode][BrandService.getCurrentBrand().slug];
            }
        }
        return false;
    };
    LiveChatService.ngInjectableDef = i0.defineInjectable({ factory: function LiveChatService_Factory() { return new LiveChatService(); }, token: LiveChatService, providedIn: "root" });
    return LiveChatService;
}());
export { LiveChatService };
