import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormatMoneyPipe} from './format-money.pipe';
import { SeoPipe } from './seo.pipe';
import { DefaultImagePipe } from './default-image.pipe';
import { UrlPrefixPipe } from './url-prefix.pipe';
import { OfferUrlPipe } from './offer-url.pipe';
import { OfferWordPipe } from './offer-word.pipe';
import { DealerCodePipe } from './dealer-code.pipe';

@NgModule({
  declarations: [
    FormatMoneyPipe,
    SeoPipe,
    DefaultImagePipe,
    UrlPrefixPipe,
    OfferUrlPipe,
    OfferWordPipe,
    DealerCodePipe,
  ],
  imports: [
    CommonModule
  ],
    exports: [
        FormatMoneyPipe,
        SeoPipe,
        DefaultImagePipe,
        UrlPrefixPipe,
        OfferWordPipe,
        OfferUrlPipe,
        DealerCodePipe,
    ]
})
export class ToolsModule { }
