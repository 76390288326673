/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../tools/format-money.pipe";
import * as i3 from "./offer-labels.component";
var styles_OfferLabelsComponent = [];
var RenderType_OfferLabelsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_OfferLabelsComponent, data: {} });
export { RenderType_OfferLabelsComponent as RenderType_OfferLabelsComponent };
function View_OfferLabelsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "label label--lowest-price"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["NAJNI\u017BSZA CENA"]))], null, null); }
function View_OfferLabelsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "label label--highest-discount"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["% NAJWI\u0118KSZY RABAT %"]))], null, null); }
function View_OfferLabelsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "span", [["class", "label label--leasing"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["LEASING "])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["100%"]))], null, null); }
function View_OfferLabelsComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "span", [["class", "label label--wolnosc-wyboru"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Wolno\u015B\u0107 Wyboru: od\u00A0"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), i0.ɵppd(5, 1), (_l()(), i0.ɵted(-1, null, ["\u00A0z\u0142\u00A0netto/mies."]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v.parent.parent, 0), _co.offer.leasing_installment)); _ck(_v, 4, 0, currVal_0); }); }
function View_OfferLabelsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OfferLabelsComponent_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OfferLabelsComponent_3)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OfferLabelsComponent_4)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OfferLabelsComponent_5)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit === "Najni\u017Csza cena"); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_v.context.$implicit === "Najwi\u0119kszy rabat") && (_co.brand.slug !== "alfa-romeo")); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_v.context.$implicit === "Leasing100"); _ck(_v, 6, 0, currVal_2); var currVal_3 = ((((!_co.isProd && (_v.context.$implicit === "WolnoscWyboru")) && _co.offer.leasing_installment) && (_co.offer.leasing_installment !== null)) && (_co.offer.leasing_installment > 0)); _ck(_v, 8, 0, currVal_3); }, null); }
export function View_OfferLabelsComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i2.FormatMoneyPipe, []), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OfferLabelsComponent_1)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.offer.labels; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_OfferLabelsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-offer-labels", [], null, null, null, View_OfferLabelsComponent_0, RenderType_OfferLabelsComponent)), i0.ɵdid(1, 114688, null, 0, i3.OfferLabelsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OfferLabelsComponentNgFactory = i0.ɵccf("app-offer-labels", i3.OfferLabelsComponent, View_OfferLabelsComponent_Host_0, { offer: "offer" }, {}, []);
export { OfferLabelsComponentNgFactory as OfferLabelsComponentNgFactory };
