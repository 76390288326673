import { ChangeDetectorRef, OnInit } from '@angular/core';
import { ComparedOfferService } from '../../services/compared-offer.service';
import { StockApiService } from '../../services/stock-api.service';
import { AppComponent } from '../../app.component';
import { AdobeService } from '../../services/adobe.service';
import { ErrorHandler } from '../../tools/error-handler';
import { BlueKaiService } from '../../services/blue-kai.service';
import { TitleService } from '../../services/title.service';
import { LabelProviderService } from '../../services/labelProvider.service';
import { BrandService } from '../../services/brand.service';
import { throwError } from 'rxjs';
import { NewsletterService } from '../../services/newsletter.service';
var ComparedOffersComponent = /** @class */ (function () {
    function ComparedOffersComponent(comparedOfferService, newsletterService, stockApiService, adobeService, blueKaiService, titleService, labelProviderService, changeDetectorRef) {
        var _this = this;
        this.comparedOfferService = comparedOfferService;
        this.newsletterService = newsletterService;
        this.stockApiService = stockApiService;
        this.adobeService = adobeService;
        this.blueKaiService = blueKaiService;
        this.titleService = titleService;
        this.labelProviderService = labelProviderService;
        this.changeDetectorRef = changeDetectorRef;
        this.offersBasic = [];
        this.offers = [];
        this.loading = true;
        this.AppComponent = AppComponent;
        this.brandSlug = '';
        this.showDifferentClass = false;
        this.visibleNumber = [false, false];
        this.siteData = window.sitedata;
        this.titleService.setTitleObserved();
        if (comparedOfferService.getAllOffers().length === 2) {
            var _loop_1 = function (item) {
                this_1.stockApiService.getOfferData(parseInt(item, 10)).then(function (value) {
                    value = _this.labelProviderService.fillOfferLabels(value);
                    _this.offers.push(value);
                }).catch(function (reason) {
                    comparedOfferService.removeOffer(parseInt(item, 10));
                    ErrorHandler.log(reason);
                    _this.loading = false;
                });
                this_1.isFinally();
            };
            var this_1 = this;
            for (var _i = 0, _a = comparedOfferService.getAllOffers(); _i < _a.length; _i++) {
                var item = _a[_i];
                _loop_1(item);
            }
        }
        else {
            this.loading = false;
        }
    }
    ComparedOffersComponent.prototype.isFinally = function () {
        var _this = this;
        if (this.offers.length === 2) {
            this.loading = false;
        }
        else {
            setTimeout(function () {
                _this.isFinally();
            }, 200);
        }
    };
    ComparedOffersComponent.prototype.isActive = function (id) {
        id = +id;
        for (var _i = 0, _a = this.offers; _i < _a.length; _i++) {
            var offer = _a[_i];
            if (offer.id === id) {
                return true;
            }
        }
        return false;
    };
    ComparedOffersComponent.prototype.removeObservedOffers = function () {
        for (var _i = 0, _a = this.comparedOfferService.getAllOffers(); _i < _a.length; _i++) {
            var id = _a[_i];
            this.comparedOfferService.removeOffer(id);
        }
    };
    ComparedOffersComponent.prototype.ngOnInit = function () {
        setTimeout(function () {
            AppComponent.bodyClass = 'page compare-page';
            // this.adobeService.pageLoadObservedOffers();
            // this.blueKaiService.changeRoute();
        });
        this.brandSlug = BrandService.getCurrentBrand().slug;
    };
    ComparedOffersComponent.prototype.showDifferent = function () {
        this.showDifferentClass = !this.showDifferentClass;
    };
    ComparedOffersComponent.prototype.showPhone = function (offer, key) {
        var phone = offer.dealer.phones[0];
        if (phone) {
            this.visibleNumber[key] = offer.dealer.phones[0];
        }
        else {
            throwError('Dealer phone not found!');
        }
    };
    return ComparedOffersComponent;
}());
export { ComparedOffersComponent };
