import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {ISearchFormElement} from '../../interface/i-search-form-element';

declare var $: any;
@Component({
  selector: 'app-model-height-select',
  templateUrl: './model-height-select.component.html'
})
export class ModelHeightSelectComponent implements OnInit, ISearchFormElement {

  @Input() name: string;
  @Input() class: string;
  @Input() icon: string;
  @Input() value: string;
  @Input() availableValues: Array<any>;
  @Input() nullValue: string;
  @Input() maxLength: string;
  isOptionListOpened = false;
  size: string;

  @Output() update = new EventEmitter<any>();

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.closeOptionList();
    }
  }

  constructor(private eRef: ElementRef) {
    this.value = null;
  }

  ngOnInit() {

    for (const av of this.availableValues) {
      if (av.value === this.value) {
        this.size = av.size;
      }
    }
  }
  public setName(name): void {
    this.name = name;
  }

  public getName(): string {
    return this.name;
  }

  public setIcon(icon): void {
    this.icon = icon;
  }

  public getIcon(key): string {

    if (this.maxLength === 'L1') {
      switch (key) {
        case 'H1':
          return 'assets/img/brands/fiat-professional/form/transport_towarow/form-L1-H1.jpg';
        case 'H2':
          return 'assets/img/brands/fiat-professional/form/transport_towarow/form-L1-H2.jpg';
      }
    } else if (this.maxLength === 'L2') {
      switch (key) {
        case 'H1':
          return 'assets/img/brands/fiat-professional/form/transport_towarow/form-L2-H1.jpg';
        case 'H2':
          return 'assets/img/brands/fiat-professional/form/transport_towarow/form-L2-H2.jpg';
      }
    } else if (this.maxLength === 'L3') {
      switch (key) {
        case 'H2':
          return 'assets/img/brands/fiat-professional/form/transport_towarow/form-L3-H2.jpg';
        case 'H3':
          return 'assets/img/brands/fiat-professional/form/transport_towarow/form-L3-H3.jpg';
      }
    } else if (this.maxLength === 'L4') {
      switch (key) {
        case 'H2':
          return 'assets/img/brands/fiat-professional/form/transport_towarow/form-L4-H2.jpg';
        case 'H3':
          return 'assets/img/brands/fiat-professional/form/transport_towarow/form-L4-H3.jpg';
      }
    }

  }

  public setValue(value: string): void {

    let newVal: string = null;
    if (value !== null) {
      for (const item of value.split(':;')) {
        for (const av of this.availableValues) {
          if (av.key === item) {
            newVal = item;
            this.size = av.size;
          }
        }
      }
    }
    this.value = newVal;
  }

  public getValue(): string {

    for (const av of this.availableValues) {
      if (av.value === this.value) {
        this.size = av.size;
      }
    }

    if (this.value !== null) {
      return 'Wysokość: ' + this.value + ' ' + this.size + ' mm';
    } else {
      return this.nullValue;
    }
  }

  public setNullValue(nullValue: string): void {
    this.nullValue = nullValue;
  }

  public setAvailableValues(availableValues: Array<object>): void {
    this.availableValues = availableValues;
  }

  public onUpdate(value: string): void {
    if (value === null) {
      this.value = null;
      this.emitUpdateEvent();
    } else {
      this.addNewValueOrDeleteWhenIsSelected(value);
      this.emitUpdateEvent();
    }
    this.closeOptionList();
  }

  private emitUpdateEvent() {
    const updateObject = {
      name: this.name,
      value: this.value
    };
    this.update.emit(updateObject);
  }

  private addNewValueOrDeleteWhenIsSelected(value: string): void {
    if (this.isSelected(value)) {
      const array: Array<string> = this.value.split(':;');
      array.splice(array.indexOf(value, 0), 1);
      if (array.length === 0) {
        this.value = null;
      } else {
        this.value = array.join(':;');
      }
    } else {
      this.value = value;
      for (const av of this.availableValues) {
        if (av.value === this.value) {
          this.size = av.size;
        }
      }
    }
  }

  public openOrCloseOptionList($event = null) {
    const isTargetHasIconClas = $($event.target).hasClass('icon-x');
    const isTargetHasRemoveClass = $($event.target).hasClass('remove');
    const clickOnRemoveButton = isTargetHasIconClas || isTargetHasRemoveClass;
    if ($event === null || !clickOnRemoveButton) {
      this.isOptionListOpened = !this.isOptionListOpened;
    }
  }


  closeOptionList() {
    this.isOptionListOpened = false;
  }

  public isSelected(value: any): boolean {
    if (this.value === null) {
      return false;
    }

    if (value === this.value) {
      return true;
    }

    return false;
  }
}

