import {Injectable} from '@angular/core';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {Location} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class QueryStringService {
  private static params: object = {};

  constructor(
    private router: Router,
    private location: Location,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((params: any) => {
      if (params.length > 0) {
        Object.keys(params).forEach((key) => {
          if (typeof params[key] !== 'string' && params[key].length > 0) {
            QueryStringService.params[key] = params[key][0];
          } else {
            QueryStringService.params[key] = params[key];
          }
        });
      } else {
        QueryStringService.params = this.getParamsFromUrl();
      }
    });
  }

  private getParamsFromUrl(): any {
    const params = {};

    if (document.location.href.indexOf('?') >= 0) {

      const qParams = document.location.href.substr(document.location.href.indexOf('?') + 1);

      for (const paramString of qParams.split('&')) {
        const key = paramString.substr(0, paramString.indexOf('='));
        params[key] = decodeURIComponent(paramString.substr(paramString.indexOf('=') + 1));
      }
    }

    return params;
  }

  public setParam(name: string, value: any) {
    if (!value) {
      this.removeParam(name);
    } else {
      QueryStringService.params[name] = value;
      this.updateUrlQueryString();
    }
  }

  public getAllParams(): object {
    return QueryStringService.params;
  }

  public removeParam(name) {
    if (QueryStringService.params[name]) {
      QueryStringService.params[name] = null;
      this.updateUrlQueryString();
    }
  }

  public getParam(name: string, forceArray: boolean = false): string|object {
    if (QueryStringService.params[name] !== undefined) {
      if (forceArray && QueryStringService.params[name] instanceof String) {
        return [QueryStringService.params[name]];
      }
      return QueryStringService.params[name];
    }
    if (forceArray) {
      return [];
    } else {
      return null;
    }
  }

  protected updateUrlQueryString() {
    const currentUrl = this.router.url.split('?')[0];
    this.router.navigate([currentUrl], {queryParams: QueryStringService.params, queryParamsHandling: 'merge'} as NavigationExtras);
  }

  public navigateByUrl(route: string) {
    this.router.navigate([route], {queryParams: QueryStringService.params} as NavigationExtras);
  }
}
