import {BrandService} from '../services/brand.service';

export class DigitalDataAdobeTM {
    page: Page;

    constructor(withConstData = false) {
        this.page = new Page();

        if (withConstData) {
            this.page.pageInfo.vehicleBrand = BrandService.getCurrentBrand().slug;
            this.page.pageInfo.language = 'pl';
            this.page.pageInfo.countryCode = 'pl';
            this.page.pageInfo.pageName = BrandService.getCurrentBrand().slug + ':pl:shopping-tools:used';
            this.page.category.primaryCategory = BrandService.getCurrentBrand().slug + ':pl:shopping-tools:used';
            this.page.category.subCategory1 = BrandService.getCurrentBrand().slug + ':pl:shopping-tools';
            this.page.category.subCategory2 = BrandService.getCurrentBrand().slug + ':pl:shopping-tools:used';
            this.page.category.pageType = BrandService.getCurrentBrand().slug + ':pl:shopping-tools';
        }
    }
}

export class Page {
    pageInfo: PageInfo;
    category: Category;
    component: Component;

    constructor() {
        this.pageInfo = new PageInfo();
        this.category = new Category();
        this.component = new Component();
    }
}

export class PageInfo {
    vehicleTrim: string;
    vehicleModel: string;
    vehicleBrand: string;
    vehicleID: string;
    language: string;
    countryCode: string;
    pageName: string;

    constructor() {
      this.vehicleTrim = '';
      this.vehicleModel = '';
      this.vehicleBrand = '';
      this.vehicleID = '';
    }
}

export class Category {
    primaryCategory: string;
    subCategory1: string;
    subCategory2: string;
    pageType: string;
}

export class Component {
    inventorySearch: InventorySearch;
    form: Form;
    paymentCalc: PaymentCalc;
    configurator: Configurator;

    constructor() {
        this.inventorySearch = new InventorySearch();
        this.form = new Form();
        this.paymentCalc = new PaymentCalc();
        this.configurator = new Configurator();
    }
}

export class Configurator {
    stepName: string;

    constructor() {
        this.stepName = '';
    }
}

export class InventorySearch {
    filterList: any;
    numberOfVehicles: number;
}

export class Form {
    stepName: string;
    nameType: string;
    leadType: string;
    transactionID: string;
}

export class PaymentCalc {
    term: number;
    downPayment: number;
    purchaseType: string;
    tradeIn: number;
}
