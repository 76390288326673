import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {ISearchFormElement} from '../../interface/i-search-form-element';

declare var $: any;

@Component({
  selector: 'app-mileage-select',
  templateUrl: './mileage-select.component.html'
})
export class MileageSelectComponent implements OnInit, ISearchFormElement {

  @Input() name: string;
  @Input() class: string;
  @Input() icon: string;
  @Input() value: string;
  @Input() availableValues: Array<any>;
  @Input() nullValue: string;
  isOptionListOpened = false;
  valueName: string;

  @Output() update = new EventEmitter<any>();

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.closeOptionList();
    }
  }

  constructor(private eRef: ElementRef) {
  }

  ngOnInit() {
  }

  public setName(name): void {
    this.name = name;
  }

  public getName(): string {
    return this.name;
  }

  public setIcon(icon): void {
    this.icon = icon;
  }

  public getIcon(): string {
    return this.icon;
  }

  public setValue(value: string): void {
    this.value = value;
    this.closeOptionList();
    this.emitUpdateEvent();
  }

  public getValue(): string {
    return this.value;
  }

  public setNullValue(nullValue: string): void {
    this.nullValue = nullValue;
  }

  public setAvailableValues(availableValues: Array<object>): void {
    this.availableValues = availableValues;
  }

  public onUpdate(value: string): void {
    if (value === null) {
      this.value = null;
      this.closeOptionList();
      this.emitUpdateEvent();
    } else {
      this.setValue(value);
      this.emitUpdateEvent();
    }
  }

  private emitUpdateEvent() {
    const updateObject = {
      name: this.name,
      value: this.value
    };
    this.setValueName(this.value);
    this.update.emit(updateObject);
  }


  public openOrCloseOptionList($event = null) {
    const isTargetHasIconClas = $($event.target).hasClass('icon-x');
    const isTargetHasRemoveClass = $($event.target).hasClass('remove');
    const clickOnRemoveButton = isTargetHasIconClas || isTargetHasRemoveClass;
    if ($event === null || !clickOnRemoveButton) {
      this.isOptionListOpened = !this.isOptionListOpened;
    }
  }


  closeOptionList() {
    this.isOptionListOpened = false;
  }


  public setValueName(value): any {
    this.availableValues.forEach(value1 => {
      if (value1.key === value) {
        this.valueName = value1.value;
      }
    });

    return this.valueName;
  }

  public isSelected(value: any): boolean {
    if (this.value === null) {
      return false;
    }

    for (const val of this.value.split(':;')) {
      if (val === value) {
        return true;
      }
    }

    return false;
  }


}
