<div class="stock-select-color row justify-content-center">
  <ng-container *ngFor="let item of availableValues">
      <div *ngIf="item.key !== null && item.key.length > 0" class="stock-select-color__option d-flex" (click)="onUpdate(item.key)"  [ngClass]="{'selected': isSelected(item.key)}">
        <div class="stock-select-color__checkbox">
            <div class="stock-select-color__field {{getColorByValue(item.key)}}">
                <div [style.background]="getColorClassValue(item.key)" [style.border-color]="getColorClassValue(item.key)">
                    ({{item.count}})
                </div>
            </div>
            <div class="stock-select-color__label">{{item.value}}</div>
        </div>
      </div>
  </ng-container>
</div>
