/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./equipment-select.component";
var styles_EquipmentSelectComponent = [];
var RenderType_EquipmentSelectComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EquipmentSelectComponent, data: {} });
export { RenderType_EquipmentSelectComponent as RenderType_EquipmentSelectComponent };
function View_EquipmentSelectComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "i", [], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(2, { icon: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "icon ", _co.icon, ""); var currVal_1 = _ck(_v, 2, 0, (_co.icon !== null)); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_EquipmentSelectComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "b", [["class", "remove"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onUpdate($event, null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [["class", "icon icon-x"]], null, null, null, null, null))], null, null); }
function View_EquipmentSelectComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [["class", "arrow arrow--down"]], null, null, null, null, null))], null, null); }
function View_EquipmentSelectComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "b", [["class", "arrow arrow--up"]], null, null, null, null, null))], null, null); }
function View_EquipmentSelectComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "option"]], [[8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onUpdate($event, _v.context.$implicit.key) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(2, { "selected": 0 }), (_l()(), i0.ɵeld(3, 0, null, null, 6, "div", [["class", "option__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "input", [["class", "option__checkbox"], ["type", "checkbox"]], [[8, "checked", 0]], null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 4, "div", [["class", "option__label"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [["class", "option__check"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 0, "i", [["class", "icon icon-check"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "span", [["class", "option__text"]], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "option"; var currVal_2 = _ck(_v, 2, 0, _co.isSelected(_v.context.$implicit.key)); _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isHiddenValue(_v.context.$implicit.value); _ck(_v, 0, 0, currVal_0); var currVal_3 = _co.isSelected(_v.context.$implicit.key); _ck(_v, 4, 0, currVal_3); var currVal_4 = _v.context.$implicit.value; _ck(_v, 9, 0, currVal_4); }); }
function View_EquipmentSelectComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "select-option-list"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EquipmentSelectComponent_7)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.availableValues; _ck(_v, 2, 0, currVal_0); }, null); }
function View_EquipmentSelectComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 17, "div", [], [[1, "data-open", 0]], null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(2, { "selected": 0, "no-icon": 1 }), (_l()(), i0.ɵeld(3, 0, null, null, 11, "div", [["class", "select-selection"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openOrCloseOptionList($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EquipmentSelectComponent_2)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 1, "div", [["class", "select-selection-label"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 0, "input", [["class", "select-selection-label-input"], ["type", "text"]], [[8, "value", 0]], [[null, "keydown"], [null, "keyup"], [null, "blur"], [null, "focus"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown" === en)) {
        var pd_0 = (_co.onKeyDownInput($event) !== false);
        ad = (pd_0 && ad);
    } if (("keyup" === en)) {
        var pd_1 = (_co.onKeyUpInput($event) !== false);
        ad = (pd_1 && ad);
    } if (("blur" === en)) {
        var pd_2 = (_co.onBlurInput($event) !== false);
        ad = (pd_2 && ad);
    } if (("focus" === en)) {
        var pd_3 = (_co.onFocusInput() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 6, "div", [["class", "select-selection-icons"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EquipmentSelectComponent_3)), i0.ɵdid(10, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EquipmentSelectComponent_4)), i0.ɵdid(12, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EquipmentSelectComponent_5)), i0.ɵdid(14, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(15, 0, null, null, 2, "div", [["class", "select-options"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EquipmentSelectComponent_6)), i0.ɵdid(17, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵinlineInterpolate(1, "stock-select ", _co.class, ""); var currVal_2 = _ck(_v, 2, 0, ((_co.value !== null) && !_co.isFocusedInput), (_co.icon == null)); _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_3 = (_co.icon !== null); _ck(_v, 5, 0, currVal_3); var currVal_5 = (_co.value !== null); _ck(_v, 10, 0, currVal_5); var currVal_6 = ((_co.value == null) && _co.isOptionListOpened); _ck(_v, 12, 0, currVal_6); var currVal_7 = ((_co.value == null) && !_co.isOptionListOpened); _ck(_v, 14, 0, currVal_7); var currVal_8 = _co.isOptionListOpened; _ck(_v, 17, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isOptionListOpened; _ck(_v, 0, 0, currVal_0); var currVal_4 = i0.ɵinlineInterpolate(1, "", _co.initialDisplayValue, ""); _ck(_v, 7, 0, currVal_4); }); }
export function View_EquipmentSelectComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_EquipmentSelectComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.availableValues !== undefined); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_EquipmentSelectComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-equipment-select", [], null, [["document", "click"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).clickout($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_EquipmentSelectComponent_0, RenderType_EquipmentSelectComponent)), i0.ɵdid(1, 114688, null, 0, i2.EquipmentSelectComponent, [i0.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EquipmentSelectComponentNgFactory = i0.ɵccf("app-equipment-select", i2.EquipmentSelectComponent, View_EquipmentSelectComponent_Host_0, { name: "name", class: "class", icon: "icon", value: "value", availableValues: "availableValues", nullValue: "nullValue" }, { update: "update" }, []);
export { EquipmentSelectComponentNgFactory as EquipmentSelectComponentNgFactory };
