import {Component, OnInit} from '@angular/core';
import {LocalStorageService} from '../../services/local-storage.service';
import {environment} from '../../../environments/environment';
import {QueryStringService} from '../../services/query-string.service';
import {AdobeService} from '../../services/adobe.service';
import {TrackingService} from '../../services/tracking.service';
import {BrandService} from '../../services/brand.service';
import {NewsletterService} from '../../services/newsletter.service';

declare var $: any;

@Component({
    selector: 'app-newsletter-form',
    templateUrl: './newsletter-form.component.html',
})
export class NewsletterFormComponent implements OnInit {

    isFormError = false;
    isVisible = false;
    adobeThankyouData: any = null;
    brandSlug: string;

    constructor(private storage: LocalStorageService,
                private QSS: QueryStringService,
                private adobeService: AdobeService,
                private newsletterService: NewsletterService) {
    }

    ngOnInit() {
        this.brandSlug = BrandService.getCurrentBrand().slug;

        $('#newsletter').on('shown.bs.modal', (e) => {
            const element = document.getElementById('selectBrandBtn');

            if (element !== null) {
              const leftPos = element.getBoundingClientRect().left + window.scrollX,
                backdrop = $('.modal-backdrop'),
                widthContainer = $('.header .container').width(),
                logo = $('.header__logo').clone(),
                logoWrap = $('<div class="container"></div>').append($('<div class="col-3"></div>').append(logo));

              backdrop.addClass('modal-backdrop--white').append(logoWrap).eq(1).remove();

              if (widthContainer >= 930) {
                $('.modal--newsletter .modal-content').css('padding-right', $(window).width() - leftPos - 30);
              }
              $('.modal--newsletter .modal-dialog').css('width', ($(window).width() - leftPos - 30) + this.widthModal(widthContainer));
            }
        });

        $('#newsletter').on('hidden.bs.modal', (e) => {
            this.storage.set('newsletter-form-close-time', Date.now() / 1000);
            this.isVisible = false;
        });

        const lastClosedTime = this.storage.get('newsletter-form-close-time');
        const timeToAgainShow = 30 * 24 * 60 * 60; // 7 days

        let forceShowForm = false;
        if (this.QSS.getParam('nl') !== null) {
            forceShowForm = true;
        }

        if (TrackingService.checkCookie('opncl_essential') === 'true') {
            if (forceShowForm || lastClosedTime === null || lastClosedTime < (Date.now() / 1000 - timeToAgainShow)) {
                setTimeout(() => {
                    this.adobeService.newsletterLoaded();
                    this.adobeThankyouData = this.adobeService.getNewsletterThankYouData();
                    this.isVisible = true;
                    if (this.isFormError === false || environment.production === false) {
                        $('#newsletter').show(300, () => {
                            $('#newsletter').modal('show');
                        });
                    }
                }, forceShowForm ? 1500 : 120000);
            }
        }

        this.newsletterService.showNL.subscribe(() => {
          if (TrackingService.checkCookie('opncl_essential') === 'true') {
            if (!this.isVisible) {
              this.adobeService.newsletterLoaded();
              this.adobeThankyouData = this.adobeService.getNewsletterThankYouData();
              this.isVisible = true;
              if (this.isFormError === false || environment.production === false) {
                $('#newsletter').show(300, () => {
                  $('#newsletter').modal('show');
                });
              }
            }
          }
        });

    }

    public widthModal(widthContainer: number): number {
        if (widthContainer >= 930) {
            return (widthContainer - 30) / 12 * 9;
        } else {
            return widthContainer - 30;
        }
    }

    public catchError(error) {
        this.isFormError = true;
    }
}
