/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "ng-lazyload-image";
import * as i2 from "./box-compare-picture.component";
var styles_BoxComparePictureComponent = [];
var RenderType_BoxComparePictureComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BoxComparePictureComponent, data: {} });
export { RenderType_BoxComparePictureComponent as RenderType_BoxComparePictureComponent };
export function View_BoxComparePictureComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "picture", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "source", [["media", "(min-width: 1200px)"]], [[1, "defaultImage", 0], [1, "lazyLoad", 0]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "source", [["media", "(min-width: 992px)"]], [[1, "defaultImage", 0], [1, "lazyLoad", 0]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "source", [["media", "(min-width: 480px)"]], [[1, "defaultImage", 0], [1, "lazyLoad", 0]], null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "img", [["class", "box-model__photo-img"]], [[8, "alt", 0]], [[null, "error"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("error" === en)) {
        var pd_0 = (_co.setDefaultImg($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(5, 1720320, null, 0, i1.LazyLoadImageDirective, [i0.ElementRef, i0.NgZone, i0.PLATFORM_ID, [2, "options"]], { lazyImage: [0, "lazyImage"], defaultImage: [1, "defaultImage"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.imageJpeg; var currVal_8 = _co.defaultImage; _ck(_v, 5, 0, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.defaultImage; var currVal_1 = _co.imageLg; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.defaultImage; var currVal_3 = _co.imageMd; _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_4 = _co.defaultImage; var currVal_5 = _co.imageXs; _ck(_v, 3, 0, currVal_4, currVal_5); var currVal_6 = _co.alt; _ck(_v, 4, 0, currVal_6); }); }
export function View_BoxComparePictureComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-box-compare-picture", [], null, null, null, View_BoxComparePictureComponent_0, RenderType_BoxComparePictureComponent)), i0.ɵdid(1, 114688, null, 0, i2.BoxComparePictureComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BoxComparePictureComponentNgFactory = i0.ɵccf("app-box-compare-picture", i2.BoxComparePictureComponent, View_BoxComparePictureComponent_Host_0, { offer: "offer", toAlt: "toAlt", brandSlug: "brandSlug" }, {}, []);
export { BoxComparePictureComponentNgFactory as BoxComparePictureComponentNgFactory };
