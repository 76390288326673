import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {StockApiService} from './stock-api.service';
import {ErrorHandler} from '../tools/error-handler';
import {BrandService} from '../services/brand.service';
import {Brands} from '../models/Brands';

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  constructor(
    private stockApiService: StockApiService,
    private router: Router,
    private title: Title,
  ) {
  }

  setTitleList(model: string, dealer: string) {
    const brand: string =  BrandService.getCurrentBrand().name;

    if (model !== null) {
      model = ' ' + model;
    } else {
      model = '';
    }

    if (dealer !== null) {
      dealer = ' - Samochody demonstracyjne dostępne u dealera ' + dealer;
      this.title.setTitle(brand + model + dealer);
    } else {
      // this.title.setTitle('Salon ' + brand + model + ' - Wyprzedaż samochodów dostępnych u dealerów');
      this.title.setTitle('Samochody demonstracyjne u dealerów marki ' + brand);
    }
  }

  setTitleOffer(model: string, version: string, dealer: string) {
    const brand: string =  BrandService.getCurrentBrand().name;

    if (dealer !== 'invenduto') {
      this.title.setTitle(brand + ' ' + model + ' ' + version + ' - Samochód dostępny u dealera ' + dealer);
    } else {
      this.title.setTitle(brand + ' ' + model + ' ' + version +
          ' - Samochód dostępny na placu centralnym FCA Poland oraz u każdego dealera w Polsce');
    }
  }

  setTitleObserved() {
    const brand: string =  BrandService.getCurrentBrand().name;
    this.title.setTitle(brand + ' - Obserwowane ogłoszenia');
  }

  setTitleDealerList() {
    const brand: string =  BrandService.getCurrentBrand().name;
    this.title.setTitle(brand + ' - Lista dealerów');
  }
}
