import { EventEmitter } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { BrandService } from './brand.service';
import { StockApiService } from './stock-api.service';
import { AppComponent } from '../app.component';
import { ErrorHandler } from '../tools/error-handler';
import * as i0 from "@angular/core";
import * as i1 from "./local-storage.service";
import * as i2 from "./stock-api.service";
var ObservedOfferService = /** @class */ (function () {
    function ObservedOfferService(storage, stockApiService) {
        var _this = this;
        this.storage = storage;
        this.stockApiService = stockApiService;
        this.offers = [];
        this.update = new EventEmitter();
        if (BrandService.getCurrentBrand().slug) {
            this.getOffers();
        }
        else {
            var subscription = BrandService.changeBrand.subscribe(function (value) {
                _this.getOffers();
            });
        }
    }
    ObservedOfferService.prototype.getOffers = function () {
        this.offers = this.storage.get(ObservedOfferService.STORAGE_KEY, []);
    };
    ObservedOfferService.prototype.addOffer = function (id) {
        var _this = this;
        this.getOffers();
        id = id.toString();
        if (!this.isObserved(id)) {
            this.stockApiService.getSimpleOffersData(id).then(function (value) {
                if (value && value.length > 0) {
                    _this.offers.push(id);
                    var data = _this.storage.get(ObservedOfferService.STORAGE_DATA_KEY, {});
                    data['offer-' + id] = value[0];
                    _this.storage.set(ObservedOfferService.STORAGE_DATA_KEY, data);
                    _this.updateStorage();
                }
            }).catch(function (reason) {
                ErrorHandler.log(reason);
            });
        }
    };
    ObservedOfferService.prototype.removeOffer = function (id) {
        id = id.toString();
        if (this.isObserved(id)) {
            var data = this.storage.get(ObservedOfferService.STORAGE_DATA_KEY, {});
            delete data['offer-' + id];
            this.storage.set(ObservedOfferService.STORAGE_DATA_KEY, data);
            this.offers = this.offers.filter(function (el) { return el !== id; });
            this.updateStorage();
        }
    };
    ObservedOfferService.prototype.changeObservedStatus = function (id) {
        id = id.toString();
        if (this.isObserved(id)) {
            this.removeOffer(id);
        }
        else {
            this.addOffer(id);
        }
    };
    ObservedOfferService.prototype.getAllOffers = function (dealerCode) {
        if (dealerCode === void 0) { dealerCode = null; }
        if (dealerCode !== null && AppComponent.isDealerStock) {
            var dealerOffers = [];
            var data = this.storage.get(ObservedOfferService.STORAGE_DATA_KEY, {});
            for (var _i = 0, _a = this.offers; _i < _a.length; _i++) {
                var item = _a[_i];
                if (data['offer-' + item] && data['offer-' + item].dealer_code === dealerCode) {
                    dealerOffers.push(item);
                }
            }
            return dealerOffers;
        }
        else {
            return this.offers;
        }
    };
    ObservedOfferService.prototype.isObserved = function (id) {
        if (id) {
            return this.offers.indexOf(id.toString()) > -1;
        }
        else {
            // Usunięcie starych ofert (bez uid) z obserwowanych
            this.storage.set(ObservedOfferService.STORAGE_KEY, []);
            this.storage.set(ObservedOfferService.STORAGE_DATA_KEY, {});
        }
    };
    ObservedOfferService.prototype.updateStorage = function () {
        if (this.offers.length > 0) {
            this.storage.set(ObservedOfferService.STORAGE_KEY, this.offers);
        }
        else {
            this.storage.remove(ObservedOfferService.STORAGE_KEY);
        }
        this.update.emit(this.offers);
    };
    ObservedOfferService.prototype.getOfferData = function (id) {
        var data = this.storage.get(ObservedOfferService.STORAGE_DATA_KEY, {});
        if (data['offer-' + id]) {
            return data['offer-' + id];
        }
        return null;
    };
    ObservedOfferService.STORAGE_KEY = 'observed-offers';
    ObservedOfferService.STORAGE_DATA_KEY = 'observed-offers-data';
    ObservedOfferService.ngInjectableDef = i0.defineInjectable({ factory: function ObservedOfferService_Factory() { return new ObservedOfferService(i0.inject(i1.LocalStorageService), i0.inject(i2.StockApiService)); }, token: ObservedOfferService, providedIn: "root" });
    return ObservedOfferService;
}());
export { ObservedOfferService };
