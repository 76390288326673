<!--<input class="form-control"-->
			 <!--*ngIf="data.type === 'text' || data.type === 'email'"-->
			 <!--[type]="data.type"-->
			 <!--placeholder="{{data.placeholder}}"-->
			 <!--(change)="changeValue(data.name)"-->
			 <!--[(ngModel)]="value"-->
<!--/>-->
<div *ngIf="errors[0]" class="error-info">{{errors[0]}}</div>
<input class="form-control"
			 *ngIf="data.type === 'text'"
			 type="text"
			 placeholder="{{getPlaceholder()}}"
			 (change)="changeValue(data.name)"
			 (keyup)="changeValue(data.name)"
			 [(ngModel)]="value"
			 mask="{{getMask()}}"
			 prefix="{{getMaskPrefix()}}"
			 [dropSpecialCharacters]="false"
			 [specialCharacters]="[ '-' ,'+' , ' ' ]"
			 [ngClass]="{'is-error': errors[0]}"
/>
<input class="form-control"
			 *ngIf="data.type === 'email'"
			 type="email"
			 placeholder="{{data.placeholder + (data.required ? '*' : null)}}"
			 (change)="changeValue(data.name)"
			 (keyup)="changeValue(data.name)"
			 (paste)="$event.preventDefault()"
			 [(ngModel)]="value"
			 [ngClass]="{'is-error': errors[0]}"
/>

<app-captcha
		*ngIf="data.name === 'g-recaptcha-response'"
		[siteKey]="data.recaptcha.site_key"
		[sendEvent]="sendEvent"
		(changeResponse)="setCaptchaResponse($event)"
></app-captcha>

<app-dealer-select
		*ngIf="data.name === 'dealer_sincom'"
		[zipCode]="'96-130'"
		[dealers]="form.form_dealers"
		[changeZipCode]="changeZipCode"
		(update)="setValue($event)"
		[ngClass]="{'is-error': errors[0]}"
></app-dealer-select>

<app-pre-reservation
    *ngIf="data.name === 'pre_reservation'"
    [name]="data.name"
    [label]="data.label"
    (update)="setValue($event)"
    [value]="data.value"
></app-pre-reservation>

<app-preferred-contact
    *ngIf="data.name === 'communication_channel'"
    [name]="data.name"
    [label]="data.label + (data.required ? '*' : null)"
    [value]="'T'"
    (update)="setValue($event)"
    [availableValues]="data.input">
</app-preferred-contact>
