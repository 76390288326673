/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./pre-reservation-component";
var styles_PreReservationComponent = [];
var RenderType_PreReservationComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PreReservationComponent, data: {} });
export { RenderType_PreReservationComponent as RenderType_PreReservationComponent };
export function View_PreReservationComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "pre-reservation-checkbox"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "pre-reservation-checkbox__option"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onUpdate(_co.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { "selected": 0 }), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["class", "pre-reservation-checkbox__check"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "i", [["class", "icon icon-check"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [["class", "pre-reservation-checkbox__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), (_l()(), i0.ɵeld(8, 0, null, null, 2, "div", [["class", "pre-reservation-checkbox__info"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Wst\u0119pna rezerwacja nie jest to\u017Csama z zam\u00F3wieniem samochodu."]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "pre-reservation-checkbox__option"; var currVal_1 = _ck(_v, 3, 0, _co.isSelected(_co.value)); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.label; _ck(_v, 7, 0, currVal_2); }); }
export function View_PreReservationComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-pre-reservation", [], null, null, null, View_PreReservationComponent_0, RenderType_PreReservationComponent)), i0.ɵdid(1, 114688, null, 0, i2.PreReservationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PreReservationComponentNgFactory = i0.ɵccf("app-pre-reservation", i2.PreReservationComponent, View_PreReservationComponent_Host_0, { name: "name", label: "label", value: "value", nullValue: "nullValue" }, { update: "update" }, []);
export { PreReservationComponentNgFactory as PreReservationComponentNgFactory };
