<div class="form form--offert pr-4 pr-lg-0 pb-5"
     [ngClass]="{'form--no-dealer': dealer === null,
      'pr-4': brand_slug !== 'fiat' && brand_slug !== 'fiat-professional',
       'pr-md-4': brand_slug === 'fiat' ||  brand_slug === 'fiat-professional'}">
	<div [ngClass]="{'offset-lg-1 px-lg-2': brand_slug !== 'fiat' && brand_slug !== 'fiat-professional'}"
       *ngIf="form !== null && cannotLoadForm === false && errorForm === false">
		<div class="headline headline--form-offert headline--transparent text-uppercase my-0 pt-5 pb-2"
				 *ngIf="cta==='getaquote' || cta==='getaquote_invenduto'">
			<h3 class="headline__title">Poproś o ofertę</h3>
		</div>
		<div class="headline headline--form-offert headline--transparent text-uppercase"
         [ngClass]="{'my-0 pt-5 pb-2': brand_slug !== 'fiat' && brand_slug !== 'fiat-professional'}"
				 *ngIf="cta==='newsletter'">
			<h3 class="headline__title">WYBIERZEMY DLA CIEBIE NAJLEPSZE OFERTY</h3>
			<h4>WYPEŁNIJ PONIŻSZY FORMULARZ A OTRZYMASZ NA MAILA WYBRANE PRZEZ NAS, NAJKORZYSTNIEJSZE OFERTY Z
				PROMOCJI.</h4>
		</div>
		<p class="form__note text-uppercase">* Pola obowiązkowe</p>
		<div class="form__fields d-flex">
			<div class="form-group form__field form__field--{{ item }}" *ngFor="let item of fields"
					 [ngClass]="{'d-none': isHiddenField(form.fields[item])}">
				<app-form-field *ngIf="item !== 'dealer_sincom' && item !== 'g-recaptcha-response'"
												[data]="form.fields[item]"
												[form]="form"
												[errors]="errors[form.fields[item].name]"
												(valueChange)="change($event)"
												[sendEvent]="sendEvent"
												[changeZipCode]="changeZipCode"
                        [brandSlug]="brand_slug"
												(click)="clickFormElement()"
				></app-form-field>
				<app-form-field
						*ngIf="item === 'dealer_sincom'"
						[data]="form.fields['dealer_sincom']"
						[form]="form"
						[errors]="errors[form.fields['dealer_sincom'].name]"
						(valueChange)="change($event)"
						[sendEvent]="sendEvent"
						[changeZipCode]="changeZipCode"
				></app-form-field>
			</div>
		</div>
		<div class="form__agreements mt-2">
			<p class="agreements__desc">Po przeczytaniu i zrozumieniu niniejszej <span (click)="openPolicyNotes()" class="agreements__link">Polityki Prywatności</span>,<br />wyrażam zgodę na przetwarzanie moich Danych w następujących celach:</p>
			<div class="agreements__wrap d-flex">
				<div class="row ie-row">
					<div class="agreements__col form-accordion-agreements col-sm-12" *ngFor="let agreement of form.agreements">
						<app-form-agreement [data]="agreement" (valueChange)="change($event)"
                                [brandSlug]="brand_slug"
                                [form]="form"
																[errors]="errors[agreement.inputs[0].name]"></app-form-agreement>
					</div>
				</div>
			</div>
		</div>
		<div class="form-group form__field form__field--g-recaptcha-response mt-4 offset-sm-0 mb-3" *ngIf="form.fields['g-recaptcha-response'] !== undefined">
				<app-form-field
						[data]="form.fields['g-recaptcha-response']"
						[form]="form"
						[errors]="errors[form.fields['g-recaptcha-response'].name]"
						(valueChange)="change($event)"
						[sendEvent]="sendEvent"
						[changeZipCode]="changeZipCode"
				></app-form-field>
		</div>
		<div class="form-group form__field form__field--send offset-md-0">
      <button *ngIf="brand_slug !== 'fiat' && brand_slug !== 'fiat-professional'" class="btn w-100" (click)="send()">WYŚLIJ</button>
      <button *ngIf="brand_slug === 'fiat' || brand_slug === 'fiat-professional'" class="btn" (click)="send()">
        WYŚLIJ
        <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.1489 0.189729L19.8085 8.04195C19.869 8.10103 19.9172 8.17209 19.9501 8.25083C19.983 8.32957 20 8.41434 20 8.5C20 8.58566 19.983 8.67043 19.9501 8.74917C19.9172 8.82791 19.869 8.89897 19.8085 8.95805L12.1489 16.8103C12.0304 16.9318 11.8697 17 11.7021 17C11.5345 17 11.3738 16.9318 11.2553 16.8103C11.1368 16.6888 11.0702 16.524 11.0702 16.3522C11.0702 16.1804 11.1368 16.0157 11.2553 15.8942L17.8191 9.15435L0.638298 9.15435C0.46901 9.15435 0.306657 9.08541 0.186953 8.9627C0.0672486 8.83998 -3.63961e-07 8.67354 -3.71547e-07 8.5C-3.79133e-07 8.32646 0.0672486 8.16002 0.186953 8.0373C0.306657 7.91459 0.46901 7.84565 0.638298 7.84565L17.8191 7.84565L11.2553 1.10582C11.1368 0.98434 11.0702 0.819576 11.0702 0.647775C11.0702 0.475974 11.1368 0.311211 11.2553 0.189729C11.3738 0.0682477 11.5345 -5.00804e-07 11.7021 -5.11516e-07C11.8697 -5.22228e-07 12.0304 0.0682477 12.1489 0.189729Z" fill="#1B1C1B"/>
        </svg>
      </button>
		</div>
	</div>
	<loader [visible]="loading && cannotLoadForm === false && errorForm === false"></loader>
  <div class="row" *ngIf="cannotLoadForm === true">
    <div class="col-11 col-lg-10 offset-lg-1">
      <div class="headline headline--form-offert headline--transparent text-uppercase my-0 pt-5 pb-2 text-white">
        <h4>Wystąpił błąd podczas pobierania danych formularza</h4>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="errorForm === true">
    <div class="col-11 col-lg-10 offset-lg-1">
      <div class="headline headline--form-offert headline--transparent text-uppercase my-0 pt-5 pb-2 text-white">
        <h4>Wystąpił błąd, prosimy spróbować później.</h4>
      </div>
    </div>
  </div>
	<div class="form__typ d-flex align-items-center justify-content-center" *ngIf="status === 1">
    <div class="text-center text-white text-uppercase" *ngIf="cta==='newsletter'">
      <h2 class="form__typ-title">Dziękujemy za zapytanie!</h2>
      <p class="form__typ-desc ">Twoje pytanie zostało wysłane.</p>
    </div>
    <div class="text-center text-white text-uppercase" *ngIf="formValues['communication_channel'] === 'T'">
      <h2 class="form__typ-title">Dziękujemy za wypełnienie formularza!</h2>
      <p class="form__typ-desc ">Prosimy oczekiwać kontaktu telefonicznego na podany w formularzu numer telefonu.</p>
    </div>
    <div class="text-center text-white text-uppercase" *ngIf="formValues['communication_channel'] === 'M'">
      <h2 class="form__typ-title">Dziękujemy za wypełnienie formularza!</h2>
      <p class="form__typ-desc ">Prosimy oczekiwać wiadomości na podany w formularzu adres e-mail.</p>
    </div>
	</div>
</div>
