import { EventEmitter } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { BrandService } from './brand.service';
import { StockApiService } from './stock-api.service';
import { ErrorHandler } from '../tools/error-handler';
import * as i0 from "@angular/core";
import * as i1 from "./local-storage.service";
import * as i2 from "./stock-api.service";
var ComparedOfferService = /** @class */ (function () {
    function ComparedOfferService(storage, stockApiService) {
        var _this = this;
        this.storage = storage;
        this.stockApiService = stockApiService;
        this.changeCompare = new EventEmitter();
        this.compareInfo = new EventEmitter();
        this.offers = [];
        this.update = new EventEmitter();
        if (BrandService.getCurrentBrand().slug) {
            this.getOffers();
        }
        else {
            var subscription = BrandService.changeBrand.subscribe(function (value) {
                _this.getOffers();
            });
        }
    }
    ComparedOfferService.prototype.getOffers = function () {
        this.offers = this.storage.get(ComparedOfferService.STORAGE_KEY, []);
    };
    ComparedOfferService.prototype.addOffer = function (id) {
        var _this = this;
        this.getOffers();
        id = id.toString();
        if (this.offers.length < 2) {
            if (!this.isCompared(id)) {
                this.stockApiService.getSimpleOffersData(id).then(function (value) {
                    if (value && value.length > 0) {
                        _this.offers.push(id);
                        var data = _this.storage.get(ComparedOfferService.STORAGE_DATA_KEY, {});
                        data['offer-' + id] = value[0];
                        _this.storage.set(ComparedOfferService.STORAGE_DATA_KEY, data);
                        _this.updateStorage();
                    }
                }).catch(function (reason) {
                    ErrorHandler.log(reason);
                });
            }
        }
        else {
            this.compareInfo.emit(true);
        }
    };
    ComparedOfferService.prototype.removeOffer = function (id) {
        id = id.toString();
        if (this.isCompared(id)) {
            var data = this.storage.get(ComparedOfferService.STORAGE_DATA_KEY, {});
            delete data['offer-' + id];
            this.storage.set(ComparedOfferService.STORAGE_DATA_KEY, data);
            this.offers = this.offers.filter(function (el) { return el !== id; });
            this.updateStorage();
        }
    };
    ComparedOfferService.prototype.changeComparedStatus = function (id) {
        this.compareInfo.emit(false);
        id = id.toString();
        if (this.isCompared(id)) {
            this.removeOffer(id);
        }
        else {
            this.addOffer(id);
        }
    };
    ComparedOfferService.prototype.getAllOffers = function () {
        return this.offers;
    };
    ComparedOfferService.prototype.isCompared = function (id) {
        return this.offers.indexOf(id.toString()) > -1;
    };
    ComparedOfferService.prototype.updateStorage = function () {
        if (this.offers.length > 0) {
            this.storage.set(ComparedOfferService.STORAGE_KEY, this.offers);
        }
        else {
            this.storage.remove(ComparedOfferService.STORAGE_KEY);
        }
        this.update.emit(this.offers);
        this.changeCompare.emit(this.offers);
    };
    ComparedOfferService.prototype.getOfferData = function (id) {
        var data = this.storage.get(ComparedOfferService.STORAGE_DATA_KEY, {});
        if (data['offer-' + id]) {
            return data['offer-' + id];
        }
        return null;
    };
    ComparedOfferService.STORAGE_KEY = 'compared-offers';
    ComparedOfferService.STORAGE_DATA_KEY = 'compared-offers-data';
    ComparedOfferService.ngInjectableDef = i0.defineInjectable({ factory: function ComparedOfferService_Factory() { return new ComparedOfferService(i0.inject(i1.LocalStorageService), i0.inject(i2.StockApiService)); }, token: ComparedOfferService, providedIn: "root" });
    return ComparedOfferService;
}());
export { ComparedOfferService };
