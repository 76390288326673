import {Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ISearchFormElement} from '../../interface/i-search-form-element';
import {StockApiService} from '../../services/stock-api.service';
import {ErrorHandler} from '../../tools/error-handler';
import {LocalStorageService} from '../../services/local-storage.service';

declare var $: any;

@Component({
  selector: 'app-model-version-select-with-color',
  templateUrl: './model-version-select-with-color.component.html',
})
export class ModelVersionSelectWithColorComponent implements OnInit, OnDestroy, ISearchFormElement {

  @Input() protected name: string;
  @Input() icon: string;
  @Input() model: any = null;
  @Input() version: any = null;
  @Input() availableValues: Array<any>;
  @Input() nullValue: string;
  @Input() sendStats = false;
  @Input() brandSlug: string;
  isOptionListOpened = false;
  openedSubSelect = false;
  allVersions = false;
  mouseIsDown = false;
  isTouchable = false;
  currentItem: any;

  @Output() update = new EventEmitter<any>();

  @HostListener('document:click', ['$event'])
  clickout(event) {
    // if (!this.eRef.nativeElement.contains(event.target)) {
    //   this.closeOptionList();
    // }
  }

  constructor(private eRef: ElementRef,
              private stockApiService: StockApiService,
              private LSS: LocalStorageService) {
  }

  ngOnInit() {
    this.setAvailableValues(this.availableValues, null);

    if (!this.model) {
      this.LSS.set('current-group-value', null);
    }

    for (const item of this.availableValues) {
      if (item.key === this.model) {
        this.LSS.set('current-group-value', item.key);
      }
    }

  }

  getCountOfSelectedModels() {
    let counter = 0;
    if (this.model !== null && this.model.split(':;').length > 1) {
        for (const model of this.availableValues) {
          for (const m of this.model.split(':;')) {
            if (model.key === m) {
              counter++;
            }
          }
        }
     }
    return counter;
  }

  ngOnDestroy() {
  }

  public setName(name): void {
    this.name = name;
  }

  public getName(): string {
    return this.name;
  }

  public setIcon(icon): void {
      this.icon = icon;
  }

  public getIcon(): string {
      return this.icon;
  }

  public setValue(value: any): void {
    this.model = value.model;
    this.version = value.version;
  }

  public getValue(): any {
    return {
      model: this.model,
      version: this.version
    };
  }

  public setNullValue(nullValue: string, rangeName: string): void {
    this.LSS.set('current-group-value', null);
    this.nullValue = nullValue;
  }

  public setAvailableValues(availableValues: Array<any>, rangeName: string): void {
    for (const model_key of Object.keys(availableValues)) {
      if (availableValues[model_key].key === 'e-ducato') {
        for (const version_key of Object.keys(availableValues[model_key].versions)) {
          if (availableValues[model_key].versions[version_key].key === 'Electric Vehicle') {
            availableValues[model_key].versions.splice(version_key, 1);
          }
        }
      }
    }
    this.availableValues = availableValues;
  }

  public onUpdate(model: any, modelVersion: any): void {

    if (this.model !== model) {
      this.version = null;
    }
    this.model = model;
    this.LSS.set('current-group-value', model);

    if (this.sendStats && this.model !== null) {
      this.stockApiService.sendSearchStats(this.model, this.version).catch(reason => {
        ErrorHandler.log(reason);
      });
    }

    this.emitUpdateEvent();
    this.closeOptionList();
  }

  public onUpdateVersion( model: any, modelVersion: any): void {

    if (this.model !== model) {
      this.version = null;
    }
    this.model = model;
    this.LSS.set('current-group-value', model);

    if (this.version) {
      const versionsList: Array<any> = this.version.split(':;');
      if (versionsList.indexOf(modelVersion) > -1) {
        versionsList.splice(versionsList.indexOf(modelVersion), 1);
      } else {
        versionsList.push(modelVersion);
      }
      this.version = versionsList.join(':;');
    } else {
      this.version = modelVersion;
    }


    if (this.sendStats && this.model !== null) {
      this.stockApiService.sendSearchStats(this.model, this.version).catch(reason => {
        ErrorHandler.log(reason);
      });
    }

    this.emitUpdateEvent();

  }

  private emitUpdateEvent() {
    const modelUpdateObject = {
      name: 'models',
      value: this.model,
    };
    const versionUpdateObject = {
      name: 'version',
      value: this.version,
    };
    this.update.emit(modelUpdateObject);
    this.update.emit(versionUpdateObject);
  }

  public openOrCloseOptionList($event = null) {
    const isTargetHasIconClas = $($event.target).hasClass('icon-x');
    const isTargetHasRemoveClass = $($event.target).hasClass('remove');
    const clickOnRemoveButton = isTargetHasIconClas || isTargetHasRemoveClass;
    if ($event === null || !clickOnRemoveButton) {
      this.isOptionListOpened = !this.isOptionListOpened;
    }
  }


  closeOptionList() {
     this.isOptionListOpened = false;
     this.openedSubSelect = null;
  }

  getDisplayModel() {
    if (this.model !== null && this.availableValues !== undefined) {
      for (const model of this.availableValues) {
        if (model.key === this.model) {
          return model.value;
        }
      }
    }
  }

  getDisplayVersion() {
    if (this.model !== null && this.availableValues !== undefined) {
      for (const model of this.availableValues) {
        if (model.key === this.model) {
          if (model.versions && this.version !== null) {
            let versionsList = '';
            for (const modelVersion of model.versions) {

              const versions: Array<any> = this.version.split(':;');

              versions.forEach((val) => {
                if ( this.availableValues !== undefined ) {
                  if (modelVersion.key === val) {
                    if (versionsList === '') {
                      versionsList = ': ' + val;
                    } else {
                      versionsList += ', ' + val;
                    }
                  }
                }
              });

            }
            return versionsList;
          }
        }
      }
    }
  }

  getDisplayValue() {
    if (this.model !== null) {
      const count = this.getCountOfSelectedModels();
      if (count > 1) {
        return 'Wybrane modele: ' + count;
      } else {
        if (this.version !== null && this.version.split(':;').length >= 1) {
           return this.getDisplayModel() + this.getDisplayVersion();
        } else {
          return this.getDisplayModel();
        }
      }
    }

    return this.nullValue;
  }

  isSelectedModel(val) {
    if (this.model !== null && this.availableValues !== undefined) {
      for (const m of this.model.split(':;')) {
        if (val === m) {
          return true;
        }
      }
    }

    return false;
  }

  isSelectedVersion(val) {
    if (val === this.version) {
      return true;
    }

    if (this.version !== null) {
      for (const v of this.version.split(':;')) {
        if (val === v) {
          return true;
        }
      }
    }

    return false;
  }

  public onFocus(item) {
    if (!this.isTouchable) {
      if (item.versions && item.versions.length > 0) {
        this.openedSubSelect = item.key;
      } else {
        this.openedSubSelect = null;
      }
    }
  }

  public onFocusMobile(item, $event) {
    $event.stopPropagation();
    // if (!this.isTouchable) {
      if (item.versions && item.versions.length > 0) {
        this.openedSubSelect = item.key;
      } else {
        this.openedSubSelect = null;
      }
    // }
  }

  public onUpdateDesktop(item) {
    if (!this.isTouchable) {
      this.onUpdate(item.key, null);
    }
  }


  public onTouchDown(item) {
    this.mouseIsDown = true;
    this.isTouchable = true;
    this.currentItem = item;

    setTimeout(() => {
      if (this.mouseIsDown) {
        if (item.versions && item.versions.length > 0) {
          this.openedSubSelect = item.key;
          // this.isTouchable = false;
        } else {
          this.openedSubSelect = null;
        }
        this.mouseIsDown = false;
      }
    }, 500);
  }

  public onTouchUp() {
    if (this.mouseIsDown) {
      this.mouseIsDown = false;
      this.onUpdate(this.currentItem.key, null);
    }
  }

  public closeSubSelect($event) {
    $event.stopPropagation();
    this.isTouchable = true;
    this.openedSubSelect = null;
    setTimeout(() => {
      this.isTouchable = false;
    }, 500);
  }

  public mouseLeaveOnlyDesktop() {
    if (window.innerWidth > 992) {
      this.openedSubSelect = null;
    }
  }

  public setBackgroundColor(item) {
    if (this.brandSlug === 'fiat') {
      switch (item) {
        case '500':
          return '#84BABA';
        case 'nowa-500-elektryczna':
          return '#5EAAB8';
        case '500e':
          return '#5EAAB8';
        case '500c':
          return '#84BABA';
        case '500x':
          return '#5293CB';
        case 'tipo-sedan':
          return '#EB7E39';
        case 'tipo-cross':
          return '#EB7E39';
        case 'tipo-sw':
          return '#EB7E39';
        case 'tipo-kombi':
          return '#EB7E39';
        case 'tipo-5d':
          return '#EB7E39';
        case 'tipo-hatchback':
          return '#EB7E39';
        case 'tipo':
          return '#EB7E39';
        case 'panda':
          return '#F4D200';
        case '600':
          return '#619CAA';
        default:
          return '#5293CB';
      }
    } else if (this.brandSlug === 'fiat-professional') {
      if (item === 'nowa-panda-van' || item === 'fiorino' || item === 'nowe-doblo' || item === 'scudo' ||  item === 'ducato') {
        return '#224766';
      } else {
        return '#5EAAB8';
      }
    }
  }

  public setModelThumbnail(item) {
    if (this.brandSlug === 'fiat') {
      switch (item) {
        case '500':
          return 'model-500.png';
        case 'nowa-500-elektryczna':
          return 'model-500-bev.png';
        case '500e':
          return 'model-500-bev.png';
        case '500c':
          return 'model-500.png';
        case '500x':
          return 'model-500x.png';
        case 'tipo-sedan':
          return 'model-tipo-sedan.png';
        case 'tipo-cross':
          return 'model-tipo.png';
        case 'tipo-sw':
          return 'model-tipo-sw.png';
        case 'tipo-kombi':
          return 'model-tipo-sw.png';
        case 'tipo-5d':
          return 'model-tipo.png';
        case 'tipo-hatchback':
          return 'model-tipo.png';
        case 'tipo':
          return 'model-tipo.png';
        case 'panda':
          return 'model-panda.png';
        case '600':
          return 'model-600.png';
      }
    } else if (this.brandSlug === 'fiat-professional') {
      switch (item) {
        case 'nowa-panda-van':
          return 'model-nowa-panda-van.png';
        case 'fiorino':
          return 'model-fiorino.png';
        case 'nowe-doblo':
          return 'model-nowe-doblo.png';
        case 'scudo':
          return 'model-scudo.png';
        case 'ducato':
          return 'model-ducato.png';
        case 'e-doblo':
          return 'model-e-doblo.png';
        case 'e-scudo':
          return 'model-e-scudo.png';
        case 'e-ducato':
          return 'model-e-ducato.png';
      }
    }
  }
}
