/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./distance-select.component";
var styles_DistanceSelectComponent = [];
var RenderType_DistanceSelectComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DistanceSelectComponent, data: {} });
export { RenderType_DistanceSelectComponent as RenderType_DistanceSelectComponent };
function View_DistanceSelectComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "option"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onUpdate(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(2, { "selected": 0 }), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "option__item"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, [" +", " km "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "option"; var currVal_1 = _ck(_v, 2, 0, (_co.value === _v.context.$implicit)); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = (_v.context.$implicit - 0); _ck(_v, 4, 0, currVal_2); }); }
function View_DistanceSelectComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "select-option-list"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DistanceSelectComponent_3)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.availableValues; _ck(_v, 2, 0, currVal_0); }, null); }
function View_DistanceSelectComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "stock-select"]], [[1, "data-open", 0]], null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(2, { "selected": 0 }), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "select-selection"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openOrCloseOptionList($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "select-selection-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, [" +", " km "])), (_l()(), i0.ɵeld(6, 0, null, null, 2, "div", [["class", "select-options"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DistanceSelectComponent_2)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "stock-select"; var currVal_2 = _ck(_v, 2, 0, (_co.value !== null)); _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_4 = _co.isOptionListOpened; _ck(_v, 8, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isOptionListOpened; _ck(_v, 0, 0, currVal_0); var currVal_3 = (_co.value - 0); _ck(_v, 5, 0, currVal_3); }); }
export function View_DistanceSelectComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_DistanceSelectComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.availableValues !== undefined); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_DistanceSelectComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-distance-select", [], null, [["document", "click"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).clickout($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_DistanceSelectComponent_0, RenderType_DistanceSelectComponent)), i0.ɵdid(1, 114688, null, 0, i2.DistanceSelectComponent, [i0.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DistanceSelectComponentNgFactory = i0.ɵccf("app-distance-select", i2.DistanceSelectComponent, View_DistanceSelectComponent_Host_0, { value: "value", availableValues: "availableValues" }, { update: "update" }, []);
export { DistanceSelectComponentNgFactory as DistanceSelectComponentNgFactory };
