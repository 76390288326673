/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./agreement.component";
var styles_AgreementComponent = [];
var RenderType_AgreementComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AgreementComponent, data: {} });
export { RenderType_AgreementComponent as RenderType_AgreementComponent };
function View_AgreementComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "label", [], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(2, { "is-error": 0 }), (_l()(), i0.ɵeld(3, 0, null, null, 5, "div", [["class", "stock-radio__option"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectValue(_v.context.$implicit.name, _v.context.$implicit.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(4, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(5, { selected: 0 }), (_l()(), i0.ɵeld(6, 0, null, null, 0, "span", [["class", "stock-radio__check"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "span", [["class", "stock-radio__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "agreements__radio agreements__radio--", _v.context.$implicit.value, " stock-radio"); var currVal_1 = _ck(_v, 2, 0, _co.errors[0]); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = "stock-radio__option"; var currVal_3 = _ck(_v, 5, 0, (_co.value === _v.context.$implicit.value)); _ck(_v, 4, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_4 = _v.context.$implicit.label; _ck(_v, 8, 0, currVal_4); }); }
function View_AgreementComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "accordion-agreement--title"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openAgreementDescription(("fieldset-" + _co.data.inputs[0].name)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "accordion-agreement-arrow"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, ":svg:svg", [["class", "bi bi-chevron-down"], ["fill", "currentColor"], ["height", "16"], ["viewBox", "0 0 16 16"], ["width", "16"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, ":svg:path", [["d", "M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"], ["fill-rule", "evenodd"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "div", [["class", "accordion-agreement--description"]], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.agreementPrivacyModal($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "agreements__box"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AgreementComponent_2)), i0.ɵdid(9, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.data.inputs; _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "form-accordion-agreement fieldset-", _co.data.inputs[0].name, " accordion-agreement--is-open"); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.data.label; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.data.content_after; _ck(_v, 6, 0, currVal_2); }); }
export function View_AgreementComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AgreementComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.data.type === "radio"); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AgreementComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-form-agreement", [], null, null, null, View_AgreementComponent_0, RenderType_AgreementComponent)), i0.ɵdid(1, 114688, null, 0, i2.AgreementComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AgreementComponentNgFactory = i0.ɵccf("app-form-agreement", i2.AgreementComponent, View_AgreementComponent_Host_0, { data: "data", errors: "errors", brandSlug: "brandSlug", form: "form" }, { valueChange: "valueChange" }, []);
export { AgreementComponentNgFactory as AgreementComponentNgFactory };
